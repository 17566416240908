import { Box, CloseButton, Flex, Icon } from '@chakra-ui/react';
import React from 'react';
import {
  FiCompass,
  FiHome,
  FiSettings,
  FiStar,
  FiTrendingUp,
  FiUsers,
} from 'react-icons/fi';
import { Link } from 'react-router-dom';

const LinkItems = [
  { name: 'Home', href: '/dashboard', icon: FiHome },
  { name: 'Signups', icon: FiTrendingUp, href: '/sign-up' },
  { name: 'Organizations', icon: FiUsers, href: '/organizations' },
  { name: 'Buisness Merchants', icon: FiCompass, href: '/business-merchants' },
  { name: 'Terminals', icon: FiStar, href: '/terminals' },
  { name: 'Wallet', icon: FiSettings, href: '/wallet' },
  { name: 'Transactions', icon: FiHome, href: '/transactions' },
  { name: 'Roles & Permission', icon: FiTrendingUp, href: '/roles' },
  { name: 'Sub Admin', icon: FiCompass, href: '/sub-admin' },
  { name: 'Checkout', icon: FiCompass, href: '/checkout' },
  { name: 'Settings', icon: FiStar, href: '/settings' },
  { name: 'Support', icon: FiSettings, href: '/support' },
];

const NavItem = ({ icon, href, children, ...rest }) => {
  return (
    <Link
      to={href}
      style={{ textDecoration: 'none', color: '#4A5568', fontSize: '14px' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex
        align="center"
        p="3"
        mx="4"
        _focus={{ boxShadow: 'none' }}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={{
          color: '#6500E0',
        }}
        _active={{
          color: '#6500E0',
        }}
        {...rest}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="14"
            _groupHover={{
              color: '#4a5568',
            }}
            as={icon}
          />
        )}
        {children}
      </Flex>
    </Link>
  );
};

const Sidebar = ({ onClose, ...rest }) => {
  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  );

  return (
    <Box
      transition="3s ease"
      bg="whitesmoke"
      zIndex="10"
      //borderRight="1px"
      //borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      w={{ base: 'full', md: 60 }}
      pos="fixed"
      h="full"
      mt={['', '20']}
      {...rest}
    >
      <Flex
        h="10"
        zIndex="10"
        alignItems="center"
        mx="8"
        justifyContent="flex-end"
      >
        {/* <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
          Logo
        </Text> */}
        <CloseButton display={{ base: 'flex', md: 'none' }} onClick={onClose} />
      </Flex>
      {userType === 'ORGANIZATION'
        ? LinkItems.filter(
            link =>
              link.name !== 'Settings' &&
              link.name !== 'Support' &&
              link.name !== 'Sub Admin' &&
              link.name !== 'Organizations' &&
              link.name !== 'Checkout' &&
              link.name !== 'Roles & Permission'
          ).map(link => (
            <NavItem key={link.name} href={link.href} icon={link.icon}>
              {link.name}
            </NavItem>
          ))
        : LinkItems.map(link => (
            <NavItem key={link.name} href={link.href} icon={link.icon}>
              {link.name}
            </NavItem>
          ))}
    </Box>
  );
};

export default Sidebar;
