import {
  Box,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Pagination from '../Common/Pagination';
import FilterMainWallet from './FilterMainWallet';

const TableUI = ({
  id,
  merchantname,
  // rrn,
  terminalref,
  terminalid,
  bank,
  panaccount,
  cardtype,
  currency,
  stan,
  authcode,
  transactiontype,
  amount,
  bizzdeskcharges,
  receiveableamount,
  datetime,
  status,
  responsecode,
  emailId,
}) => {
  return (
    <Tr>
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {merchantname}
      </Td>
      {/* <Td fontSize="14px" fontWeight="400">
        {rrn}
      </Td> */}
      <Td fontSize="14px" fontWeight="400">
        {terminalref}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {terminalid}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {bank}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {panaccount}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {cardtype}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {currency}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {stan}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {authcode}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {transactiontype}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {amount}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {bizzdeskcharges}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {receiveableamount}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {datetime}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {status}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {responsecode}
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#6500E0" fontWeight="400">
        {' '}
        <Link to={`/transactions/${emailId}`}> View </Link>
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
        {' '}
        Print
      </Td>
    </Tr>
  );
};

const WalletWithdrawalList = () => {
  const [loading, setLoading] = React.useState(false);
  const [val, setVal] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = React.useState(1);
  // eslint-disable-next-line
  const [postPerPage, setPostPerPage] = React.useState(10);
  const validate = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': 'true',
        'Content-type': 'Application/json',
      },
      url: `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/merchant/analytics/getMerchantTransactionRecord?limit=5000&offset=0&transMode=wallet%20withdrawal&transType=wallet%20withdrawal`,
    })
      .then(response => {
        setLoading(false);
        setVal(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get current post
  const indexOfLastPage = currentPage * postPerPage;

  const indexOfFirstPage = indexOfLastPage - postPerPage;

  const serial = Array?.isArray(val)
    ? val?.map((data, index) => ({ ...data, serialno: index + 1 }))
    : null;
  // const filteredData = serial.filter(item => {
  //   return item.property === 'some value';
  // });
  const currentPosts = Array?.isArray(serial)
    ? serial?.slice(indexOfFirstPage, indexOfLastPage)
    : serial?.toString().slice(indexOfFirstPage, indexOfLastPage);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  // filter server data
  const [displayData, setDisplayData] = useState([]);
  const [firstDate, setFirstDate] = useState();
  const [secondDate, setSecondDate] = useState();
  const [search, setSearch] = useState();
  const [trues, setTrue] = useState(false);

  const filterTransactions = () => {
    setDisplayData(currentPosts);
    setTrue(true);

    const newData = val.filter(data => {
      const firstDateInNumber = new Date(firstDate).getTime();
      const secondDateInNumber = new Date(secondDate).getTime();

      const dataDate = new Date(data.date).getTime();

      if (
        (firstDateInNumber <= dataDate && secondDateInNumber <= dataDate) ||
        search === data.status
      ) {
        return true;
      }
      return false;
    });
    setDisplayData(newData);
  };

  return (
    <Box>
      <FilterMainWallet
        setSecondDate={setSecondDate}
        setFirstDate={setFirstDate}
        setSearch={setSearch}
        filterTransactions={filterTransactions}
      />

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                s/n
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                merchant name
              </Th>
              {/* <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                rrn
              </Th> */}
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                transaction ref
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                terminal id
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                bank
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                pan account
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                card type
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                currency
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                stan
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                auth code
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                transaction type
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                amount
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                paysure charges
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                receiveable amount
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                date/time
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                status
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                response code
              </Th>
            </Tr>
          </Thead>
          {trues ? (
            <Tbody>
              {loading ? (
                <Skeleton w="full" h="50%"></Skeleton>
              ) : Array?.isArray(val) ? (
                displayData.map((data, index) => (
                  <TableUI
                    key={data.rrn}
                    id={data.serialno}
                    merchantname={data.fullName}
                    emailId={data.fullName}
                    // rrn={data.rrn||'-'}
                    terminalref={data.ref || '-'}
                    terminalid={data.tid || '-'}
                    bank={data.bank || '-'}
                    panaccount={data.panAccount || '-'}
                    cardtype={data.cardType || '-'}
                    currency={data.currency || '-'}
                    stan={data.stan || '-'}
                    authcode={data.authCode || '-'}
                    transactiontype={data.transtype || '-'}
                    amount={data.amount || 0}
                    bizzdeskcharges={data.bizzdeskCharges || 0}
                    receiveableamount={data.receivableAmount || 0}
                    datetime={data.date}
                    status={data.status}
                    responsecode={
                      data.response === 'COMPLETED' ? '00' : data.response
                    }
                  />
                ))
              ) : null}
            </Tbody>
          ) : (
            <Tbody>
              {loading ? (
                <Skeleton w="full" h="50%"></Skeleton>
              ) : Array?.isArray(val) ? (
                currentPosts.map((data, index) => (
                  <TableUI
                    key={data.rrn}
                    id={data.serialno}
                    merchantname={data.fullName}
                    emailId={data.fullName}
                    // rrn={data.rrn||'-'}
                    terminalref={data.ref || '-'}
                    terminalid={data.tid || '-'}
                    bank={data.bank || '-'}
                    panaccount={data.panAccount || '-'}
                    cardtype={data.cardType || '-'}
                    currency={data.currency || '-'}
                    stan={data.stan || '-'}
                    authcode={data.authCode || '-'}
                    transactiontype={data.transtype || '-'}
                    amount={data.amount || '0'}
                    bizzdeskcharges={data.bizzdeskCharges || '0'}
                    receiveableamount={data.receivableAmount || '-0'}
                    datetime={data.date}
                    status={data.status}
                    responsecode={
                      data.response === 'COMPLETED' ? '00' : data.response
                    }
                  />
                ))
              ) : null}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        postPerPage={postPerPage}
        paginate={paginate}
        totalPosts={val.length}
      />
    </Box>
  );
};

export default WalletWithdrawalList;
