import React from 'react';
import tw from 'twin.macro';
// import { useRouter } from 'next/router'
import {
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

const SearchBar = ({ value, label, setSearchValue }) => {
  const [searchValueI, setSearchValueI] = React.useState('');

  React.useEffect(() => {
    if (value) {
      setSearchValueI(value);
    }
  }, [value]);

  // function to handle search
  const handleSearch = () => {
    setSearchValue(searchValueI);

    // router.push({
    //   pathname: router.pathname,
    //   query: {
    //     ...router.query,
    //     [searchkey]: searchValue,
    //   },
    // })
    // history.push({
    //   pathname: '/transactions/transactions_list/income',
    //   search: `?${new URLSearchParams({ [searchkey]: searchValue })}`,
    // });
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  // function to handle onchange
  const handleChange = e => {
    setSearchValueI(e.target.value);
  };

  return (
    <InputGroup width={'256px'}>
      <Input
        value={searchValueI}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        fontSize="13px"
        placeholder={label ? label : 'Search'}
        // maxWidth={['100%', '256px']}
      />
      <InputRightElement
        // pr={4}
        children={
          <Button onClick={handleSearch}>
            <Image src="/assets/search-normal.png" alt="" />
          </Button>
        }
      />
    </InputGroup>
  );
};

export default SearchBar;
