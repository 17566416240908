import { Box, HStack, Image, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import TransactionTable from './TransactionTable';

const Settings = () => {
  return (
    <Box display="flex" flexDirection={['column', 'row']} mt="1rem" w="full">
      <Box
        w={['full', '30%']}
        display="flex"
        flexDirection="column"
        borderRight="1px solid #E4ECF7"
        h={['100%', '100vh']}
      >
        <Text float="left" fontWeight="700" fontSize="32px">
          Settings
        </Text>
        <Box mt="3rem">
          <NavLink to="/settings/merchant-band">
            <HStack>
              <Image src="assets/home.png" alt="" />
              <Text
                _active={{ color: '#6500E0' }}
                _hover={{ color: '#6500E0' }}
                fontWeight="500"
                fontSize="16px"
              >
                Merchant Band
              </Text>
            </HStack>
          </NavLink>
          <NavLink style={{ marginTop: '1rem' }} to="/settings">
            <HStack>
              <Image src="assets/home.png" alt="" />
              <Text
                _active={{ color: '#6500E0' }}
                _hover={{ color: '#6500E0' }}
                fontWeight="500"
                fontSize="16px"
              >
                Charge Plan
              </Text>
            </HStack>
          </NavLink>
        </Box>
      </Box>
      <Box w={['full', '70%']} h="100%" p="2rem">
        <Box>
          <Box
            display="flex"
            gap="6"
            justifyContent="space-between"
            w="full"
            flexDirection={['column', 'row']}
            mt="1rem"
          >
            <VStack alignItems="flex-start">
              <Text
                float="left"
                fontSize="20px"
                color="#16192C"
                fontWeight="500"
              >
                Charge Plan
              </Text>
              <Text
                float="left"
                fontSize="14px"
                color="#425D8A"
                fontWeight="400"
              >
                Create a Charge plan
              </Text>
            </VStack>
            <Box float="right">
              <TransactionTable />
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap="6" flexDirection="column" mt="2.5rem">
          <Link to="/settings/plan-packages">
            <Box
              borderRadius="12px"
              w="308px"
              h="57px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#EBF2FA"
            >
              <Text fontSize="14px" fontWeight="500">
                POS withdrawal
              </Text>
            </Box>
          </Link>
          <Link to="">
            <Box
              borderRadius="12px"
              w="308px"
              h="57px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#EBF2FA"
            >
              <Text fontSize="14px" fontWeight="500">
                Transfer
              </Text>
            </Box>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Settings;
