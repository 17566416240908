/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
  // InputRightElement,
  // InputGroup,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import image1 from '../../BusinessMerchant/assets/edit.png';
//import { useParams } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

function EditTerminalModal({ userEmail, email, serialnumber, isFullButton }) {
  // eslint-disable-next-line
  // const { userEmail } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  //   validation

  // const validationSchema = Yup.object().shape({
  //   tid: Yup.string().required('This is required'),
  // });
  // const formOptions = { resolver: yupResolver(validationSchema) };

  // const { register, handleSubmit, formState } = useForm(formOptions);
  // const { errors } = formState;

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const getNameList =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/Merchants/getNamesList'
      : 'https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/merchant/getNamesList';

  const rawurl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/profile/getTerminalProfile?tid=${userEmail}`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/getTerminalProfile?tid=${userEmail}`;

  const editTerminalUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/edit/editTerminal`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/edit/editTerminal`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  const [mail, setMail] = React.useState();
  // eslint-disable-next-line
  const [mails, setMails] = React.useState();

  const [user, setUserDetails] = React.useState({
    tid: userEmail,
    serialnumber: serialnumber,
    transactionLimit: '',
  });

  const handleChange = e => {
    setUserDetails({ ...user, [e.target.name]: e.target.value });
  };

  const validatess = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getNameList,
    })
      .then(response => {
        setLoading(false);
        setMails(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validatess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validates = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: rawurl,
    })
      .then(response => {
        setLoading(false);
        setMail(response.data);
        setUserDetails({
          ...user,
          transactionLimit: response.data.transactionLimit,
        });
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = event => {
    event.preventDefault();
    const input = {
      serialnumber: user?.serialnumber,
      tid: user?.tid,
      transactionLimit: user?.transactionLimit,
    };

    setLoading(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: editTerminalUrl,
      data: input,
    })
      .then(response => {
        setLoading(false);
        setUserDetails(response.data);
        toast({
          // title: 'A New Merchant was Assigned!.',
          position: 'top',
          description: 'Terminal saved successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch(error => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: error.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
      });
  };

  return (
    <>
      {!isFullButton ? (
        <Image
          onClick={onOpen}
          cursor="pointer"
          src={image1}
          alt=""
          h="24px"
          w="24px"
        />
      ) : (
        <Button
          border="none"
          borderRadius="12px"
          w="full"
          h="55px"
          color="#6500E0"
          bg="#F3F7FC"
          onClick={onOpen}
          _hover={{
            color: '#F3F7FC',
            bg: '#6500E0',
          }}
        >
          Edit
        </Button>
      )}

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Edit Terminal
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <Box
              mt="1rem"
              p="1rem"
              display="flex"
              flexDirection="column"
              gap="3"
            >
              <form method="POST" onSubmit={validate} tw="space-y-4">
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Terminal ID
                  </Text>
                  <Input
                    value={user.tid}
                    // disabled
                    border={' 1px solid #E3E5E8'}
                    name="tid"
                    onChange={handleChange}
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    // {...register('tid')}
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  {/* <Text color="red" mt=".5rem">
                    {errors.tid?.message}
                  </Text> */}
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Serial Number
                  </Text>
                  <Input
                    value={user.serialnumber}
                    name="serialnumber"
                    onChange={handleChange}
                    border=" 1px solid #E3E5E8"
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  {/* <Text color="red" mt=".5rem">
                    {errors.title?.serialnumber}
                  </Text> */}
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Transaction Limit
                  </Text>
                  <Input
                    value={user.transactionLimit}
                    name="transactionLimit"
                    onChange={handleChange}
                    border=" 1px solid #E3E5E8"
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  {/* <Text color="red" mt=".5rem">
                    {errors.title?.transactionLimit}
                  </Text> */}
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Model
                  </Text>
                  <Input
                    value={mail?.modelType}
                    // {...register('modelType')}
                    border=" 1px solid #E3E5E8"
                    disabled
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  {/* <Text color="red" mt=".5rem">
                    {errors.modelType?.message}
                  </Text> */}
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    OS Type
                  </Text>
                  <Input
                    value={mail?.osType}
                    border=" 1px solid #E3E5E8"
                    borderRadius="4px"
                    color="#333"
                    disabled
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  {/* <Text color="red" mt=".5rem">
                    {errors.title?.osType}
                  </Text> */}
                </VStack>

                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Bank
                  </Text>
                  <Input
                    value={mail?.bank}
                    disabled
                    border=" 1px solid #E3E5E8"
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Assign Merchant
                  </Text>
                  <Select
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                    // {...register('email')}
                    border={' 1px solid #E3E5E8'}
                    disabled
                    borderRadius="4px"
                    fontSize="13px"
                    value={email}
                  >
                    {Array?.isArray(mails)
                      ? mails.map((e, id) => {
                          return (
                            <Box
                              key={id}
                              as="option"
                              value={e?.userName}
                              bg="#F3F7FC"
                              borderBottom="1px solid #ccc"
                            >
                              <Text>
                                {' '}
                                {loading ? 'Name' : e?.fullName}{' '}
                                &nbsp;&nbsp;&nbsp;
                              </Text>
                              <Text>
                                &#40;{loading ? 'Name' : e?.userName}&#41;
                              </Text>
                            </Box>
                          );
                        })
                      : null}
                  </Select>
                  {/* <Input
                    placeholder=""
                    {...register('email')}
                    border=" 1px solid #E3E5E8"
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  /> */}
                  {/* <Text color="red" mt=".5rem">
                    {errors.title?.email}
                  </Text> */}
                </VStack>
                <Center w="full">
                  {loading ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  )}
                </Center>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditTerminalModal;
