import {
  Box,
  // Select,
  // Skeleton,
  Button,
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

function AssignBand() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  //   validation

  const validationSchema = Yup.object().shape({
    band: Yup.string().required('Band is required'),
    merchantUserName: Yup.string().required('Email is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  //  const [val, setVal] = React.useState(false);

  //  const validates = () => {
  //    setLoading(true);

  //    axios({
  //      method: 'get',
  //      headers: {
  //        Authorization: `Bearer ${token}`,
  //        'Access-Control-Allow-Origin': '*',
  //        'Content-type': 'Application/json',
  //      },
  //      url: 'https://bbm.bizzdesk.ng:8080/apis/bizzdesk/merchants/Merchants/getNamesList',
  //    })
  //      .then(response => {
  //        setLoading(false);
  //        setVal(response.data);
  //      })
  //      .catch(err => {
  //        setLoading(false);
  //      });
  //  };
  //  React.useEffect(() => {
  //    validates();
  //    // eslint-disable-next-line react-hooks/exhaustive-deps
  //  }, []);
  const validate = (data, event) => {
    event.preventDefault();
    const input = {
      band: data?.band,
      merchantUserName: data?.merchantUserName,
    };

    setLoading(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/merchant/assignBand',
      data: input,
    })
      .then(response => {
        setLoading(false);
        toast({
          title: 'A New Band has been assigned!.',
          position: 'top',
          description: 'A New Role has been Created Successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: 'It is not you, it i us, wait as we resolve the issue.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg="#6500E0"
        w={['85%', '225px']}
        h="42px"
        borderRadius="8px"
        colorScheme="#6500E0"
        variant="solid"
      >
        Assign Merchant to a Band
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Assign Merchant to Band
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <form onSubmit={handleSubmit(validate)}>
              <Box
                mt="1rem"
                p="1rem"
                display="flex"
                flexDirection="column"
                gap="3"
                w="full"
              >
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Merchant
                  </Text>
                  {/* <Select
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                    {...register('merchantUserName')}
                    border={
                      errors.merchantUserName
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    fontSize="13px"
                    placeholder="Select Band"
                  >
                    {loading ? (
                      <>
                        <Skeleton
                          _hover={{ background: '#F3F7FC' }}
                          borderBottom="1px solid #ccc"
                          p="1rem"
                        ></Skeleton>
                        <Skeleton
                          _hover={{ background: '#F3F7FC' }}
                          borderBottom="1px solid #ccc"
                          p="1rem"
                        ></Skeleton>
                        <Skeleton
                          _hover={{ background: '#F3F7FC' }}
                          borderBottom="1px solid #ccc"
                          p="1rem"
                        ></Skeleton>
                      </>
                    ) : Array?.isArray(val) ? (
                      val?.map(e => {
                        return (
                          <Text
                            as="option"
                            cursor="pointer"
                            value={e?.roleTitle}
                            _hover={{ background: '#F3F7FC' }}
                            borderBottom="1px solid #ccc"
                            p="1rem"
                          >
                            {' '}
                            {loading ? 'Band A' : e?.roleTitle}{' '}
                          </Text>
                        );
                      })
                    ) : null}
                  </Select> */}

                  <Input
                    placeholder="Account Admin"
                    border={
                      errors.merchantUserName
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#000"
                    fontSize="14px"
                    outline="none"
                    id="email"
                    type="text"
                    {...register('merchantUserName')}
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.merchantUserName?.message}
                  </Text>
                </VStack>

                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Band
                  </Text>
                  <Input
                    placeholder="Account Admin"
                    border={
                      errors.band ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#000"
                    fontSize="14px"
                    outline="none"
                    id="email"
                    type="text"
                    {...register('band')}
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.band?.message}
                  </Text>
                </VStack>

                <Center w="full">
                  {loading ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Done
                    </Button>
                  )}
                </Center>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AssignBand;
