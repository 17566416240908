import { Box, Text } from '@chakra-ui/react';
import { Box as MUIBox } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import TabPanel from '../Common/TabPanel';
import AllMerchants from './AllMerchants';
import Income from './Income';
import Overview from './Overview';
import POSSales from './POSSales';

const Wallets = () => {
  const navigate = useNavigate();

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const [tabValue, setTabValue] = React.useState(userType === 'admin' ? 0 : 2);

  // get tab value from url param
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTabValue(parseInt(tab));
    }
  }, []);

  const Tab = ({ label, index }) => {
    return (
      <button
        css={[
          tw`text-black text-sm 2xl:text-base py-1.5 md:py-2.5 2xl:py-3.5 px-2 xl:px-4 tracking-[-0.025em] font-medium`,
          index === tabValue &&
            tw`text-white bg-[#6500E0] rounded-lg xl:rounded-[12px]`,
        ]}
        onClick={() => {
          navigate({
            pathname: '/wallet',
            search: `?tab=${index}`,
          });

          setTabValue(index);
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <Box>
      <Text flat="left" fontWeight="700" fontSize="32px">
        Wallet
      </Text>

      <MUIBox sx={{ width: '100%', marginTop: '24px' }}>
        <MUIBox
          sx={{
            paddingBottom: {
              xs: '20px',
              lg: '20px',
            },
          }}
        >
          <div tw="space-x-4">
            {userType === 'admin' && (
              <Tab
                label={`${
                  userType === 'admin' ? 'BBM' : 'Organization'
                } Main Wallet`}
                index={0}
              />
            )}
            {userType === 'admin' && (
              <Tab
                label={`${
                  userType === 'admin' ? 'BBM' : 'Organization'
                } POS Sales/Verification Wallet`}
                index={1}
              />
            )}
            <Tab
              label={`${
                userType === 'admin' ? 'BBM' : 'Organization'
              } Income Wallet`}
              index={2}
            />
            <Tab label="All Merchants Wallet" index={3} />
          </div>
        </MUIBox>
        {userType === 'admin' && (
          <TabPanel tabvalue={tabValue} index={0}>
            <Overview />
          </TabPanel>
        )}
        {userType === 'admin' && (
          <TabPanel tabvalue={tabValue} index={1}>
            <POSSales />
          </TabPanel>
        )}
        <TabPanel tabvalue={tabValue} index={2}>
          <Income />
        </TabPanel>
        <TabPanel tabvalue={tabValue} index={3}>
          <AllMerchants />
        </TabPanel>
      </MUIBox>
    </Box>
  );
};

export default Wallets;
