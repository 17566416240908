import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import DatRangePickerAndOthers from '../component/Common/DataGridFunctionalities/DatRangePickerAndOthers';
import DataGridViewTemp from '../component/Common/DataGridViewTemp';
import Sidebar from '../component/Common/Sidebar';
import Topnav from '../component/Common/Topnav';
import { swrfetcher } from '../utils/swrFetcher';

const BizzdeskWallets = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const [date, setDate] = React.useState(['', '']);

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/IncomeWallet/getWalletActivities?limit=30&offset=${
          offset || 0
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`
      : `/apis/bizzdesk/organization/wallet/getIncomeWalletActivities?limit=30&offset=${
          offset || 0
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`;

  const tableContUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/IncomeWallet/getWalletActivities/count?limit=30&offset=${
          offset || 0
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`
      : `/apis/bizzdesk/organization/wallet/getIncomeWalletActivities/count?limit=30&offset=${
          offset || 0
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableCount } = useSWR(
    [tableContUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          transactiontype: item?.transType,
          description: item?.transinfo,
          debitN: item?.debit,
          creditN: item?.credit,
          balanceN: item?.newamount,
          date: item?.date,
          action: {
            link: `/wallet/${item?.userName}`,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'transaction type',
    },
    {
      headerName: 'description',
    },
    {
      headerName: 'debit',
    },
    {
      headerName: 'credit',
    },
    {
      headerName: 'balance',
    },
    {
      headerName: 'date',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <button
          onClick={() => navigate(-1)}
          style={{ fontSize: '14px', fontWeight: '300' }}
          tw="mb-4 hover:underline"
        >
          Back
        </button>

        <DataGridViewTemp
          hasMT
          title="BBM Income Wallet"
          rows={rows}
          columns={TransWalletColumn}
          isValidating={isValidating}
          hasExportBtn={tableData}
          pagination
          recordCount={tableCount || 0}
          page={offset / 30 + 1}
        >
          <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
            <DatRangePickerAndOthers date={date} setDate={setDate} />
          </div>
        </DataGridViewTemp>
      </Box>
    </Box>
  );
};

export default BizzdeskWallets;
