/** @jsxImportSource @emotion/react */
import { Link } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons';
import {
  Button,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

const LandingPage = () => {
  return (
    <main tw="">
      <Nav />
      <Jumbotron />
      <div tw="space-y-20 md:space-y-28 lg:space-y-[150px] mt-20 py-3 lg:py-6 container mx-auto px-4 md:px-8 lg:px-12 xl:px-20">
        <Services />
        <Join />
        <Download />
        <Footer />
      </div>
    </main>
  );
};

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer>
      <div tw="py-[30px] sm:py-8 md:pt-16 lg:pt-[71px] lg:pb-[60px] flex justify-center items-center flex-col space-y-4">
        <p tw="text-[#170F49] leading-[22px] text-center font-semibold text-lg md:text-xl">
          Explore and find the best solution for <br tw="hidden md:inline" />{' '}
          your business needs
        </p>
        <Button tw="text-white bg-[#6500E0] !px-[38px] hover:bg-[#6500C0] !text-xs font-bold rounded-full">
          Contact Us
        </Button>
      </div>

      <hr tw="border-[#EFF0F6]" />

      <div tw="flex justify-between items-center flex-col lg:flex-row py-4 lg:py-[38.26px]">
        <p tw="text-[#6F6C90] text-sm md:text-base lg:text-lg leading-[20px] text-center md:text-left">
          Copyright © {year} Paysure Technologies Limited | All Rights Reserved
        </p>

        {/* Socials */}
        <div tw="space-x-[22px] mt-8 md:mt-0 flex ">
          <LInk src="facebook" href="#" />
          <LInk src="twitter" href="#" />
          <LInk src="instagram" href="#" />
          <LInk src="linkedin" href="#" />
          <LInk src="youtube" href="#" />
        </div>
      </div>
    </footer>
  );
};

const LInk = ({ href, src }) => {
  return (
    <a href={href}>
      <Image
        src={`/assets/${src}.svg`}
        alt="Payure logo"
        width={19}
        height={15}
      />
    </a>
  );
};

const Download = () => {
  return (
    <section tw="bg-[#F0E5FC] rounded-[25px] py-4 lg:pt-6 px-4 lg:px-[125px]">
      <div tw="flex justify-between items-center space-x-0 md:space-x-10 mt-6 flex-col md:flex-row">
        <div tw="md:w-1/2 flex justify-center items-center">
          <Image src={`/assets/phone-11.png`} />
        </div>

        <div tw="text-center md:text-left md:w-1/2 mt-4 md:mt-0">
          <h3 tw="xl:leading-[50.004px] text-lg lg:text-2xl xl:text-[36px] font-bold">
            Revolutionised Payments
          </h3>
          <p tw="mt-1 lg:leading-[25.6px] text-sm lg:text-base">
            Empower your businesses with innovative payment solutions designed
            to enhance financial efficiency and elevate the customer experience.
          </p>

          <div tw="space-x-3 md:space-x-[21px] mt-6 flex justify-center md:justify-start items-center">
            <button tw="">
              <Image src={`/assets/apple-download.png`} />
            </button>
            <button tw="">
              <Image src={`/assets/store-download.png`} />
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

const Join = () => {
  return (
    <section tw="text-center">
      <h6 tw="text-[#6500E0] lg:text-lg font-bold leading-[25.002px] text-xs sm:text-sm md:text-base">
        Join the Business Elite:
      </h6>
      <h4 tw="text-lg md:text-xl lg:text-[25px]font-bold lg:leading-[35.6px] mt-2.5">
        Elevate Your Operations with Paysure's Seamless
        <br tw="hidden md:inline" /> Payment Solutions
      </h4>

      <div tw="flex lg:justify-between items-center space-x-0 lg:space-x-10 mt-6 flex-col lg:flex-row max-w-md mx-auto lg:max-w-none lg:mx-0">
        <div tw="lg:w-1/2">
          <Image src={`/assets/join.png`} tw="w-full" />
        </div>

        <div tw="text-center lg:text-left lg:w-1/2 mt-2 lg:mt-0">
          <h3 tw="xl:leading-[50.004px] text-lg lg:text-2xl xl:text-[36px] font-bold">
            An opportunity to elevate your business to the next stage
          </h3>
          <p tw="mt-1 lg:leading-[25.6px] text-sm lg:text-base">
            Discover the power of Paysure and join a thriving community of
            businesses experiencing unparalleled efficiency and success. Our
            seamless payment solutions empower you to elevate your daily
            operations, ensuring reliability and security.
          </p>

          <button tw="text-[#6500E0] border-[#6500E0] border rounded-full py-1.5 lg:py-2.5 px-4 lg:px-[25px] mt-5 lg:mt-[30px] text-sm font-bold leading-[22.4px]">
            Create Account
          </button>
        </div>
      </div>
    </section>
  );
};

const Services = () => {
  return (
    <section tw="text-center">
      <h6 tw="text-[#6500E0] lg:text-lg font-bold leading-[25.002px] text-xs sm:text-sm md:text-base">
        Choose Paysure Business for <br tw="lg:hidden" /> Unmatched Efficiency
        and Success
      </h6>
      <h4 tw="text-lg md:text-xl lg:text-[25px]font-bold lg:leading-[35.6px] mt-2.5">
        The Only Payment Solution <br /> Your Business Will Ever Need
      </h4>

      <div tw="flex justify-between space-x-0 lg:space-x-10 space-y-8 lg:space-y-0 mt-6 flex-col lg:flex-row max-w-sm mx-auto lg:max-w-none lg:mx-0">
        <ServicesCard
          title="Secured Payment"
          para="Receive payments in person or while you're on the move utilize
           Paysure's POS Terminals to process credit card transactions."
          logo="secured"
        />
        <ServicesCard
          title="Fast"
          para="Receive payments in person or while you're on the move utilize Paysure's POS Terminals to process credit card transactions."
          logo="fast"
        />
        <ServicesCard
          title="Reliable"
          para="Receive payments in person or while you're on the move utilize
           Paysure's POS Terminals to process credit card transactions."
          logo="reliable"
        />
      </div>
    </section>
  );
};

const Jumbotron = () => {
  return (
    <section tw="bg-bgg bg-no-repeat bg-left-top">
      <div tw="lg:bg-bgJumbotron bg-no-repeat lg:bg-right flex justify-between items-center">
        <div tw=" py-3 lg:py-6 container mx-auto px-4 md:px-8 lg:px-12 xl:px-20">
          <div tw="lg:w-1/2 md:w-2/3 text-center md:text-left py-[clamp(24px,5vh,100px)] ">
            <h4 tw="text-[#3A3A3A] text-3xl lg:text-4xl xl:text-[40px] font-bold lg:leading-[46.96px] xl:leading-[56.96px] lg:w-11/12">
              Empower <span tw="text-[#6500E0]">Your Business</span> with
              Paysure Secure, Seamless, and Reliable Payment Solutions for
              Merchants
            </h4>

            <p tw="text-xs lg:text-base mt-2.5 lg:w-11/12">
              Receive payments in person or while you're on the move utilize
              Paysure's POS Terminals to process credit card transactions.
            </p>

            <div tw="mt-4 md:mt-8 space-y-4 md:space-y-8">
              <CusButton>Request for a Terminal</CusButton>

              <div tw="space-y-2 md:space-y-0 flex justify-start items-center flex-col md:flex-row">
                <div tw="space-x-2 flex items-center">
                  <Button
                    leftIcon={
                      <Image src="/assets/material-symbols-license.svg" />
                    }
                    tw="text-[12px]"
                  >
                    Fully Licensed by CBN
                  </Button>
                  <Image src="/assets/cbn.png" />
                </div>

                <div tw="bg-[#AA80F9] h-[46px] w-px mx-7 hidden md:block" />

                <div tw="space-x-2 flex items-center">
                  <Button
                    leftIcon={<Image src="/assets/tdesign-secured.svg" />}
                    tw="text-[12px]"
                  >
                    Deposits insured by
                  </Button>
                  <Image src="/assets/ndic.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const Nav = () => {
  return (
    <nav tw="flex justify-between py-3 lg:py-6 container mx-auto px-4 md:px-8 lg:px-12 xl:px-20">
      <Image
        src="/assets/logo_purple.png"
        height={31.06}
        width={117.91}
        alt="logo"
      />

      <div tw="space-x-2 hidden lg:block">
        <Link tw="hover:no-underline " to="/auth/login">
          <CusButton>Login</CusButton>
        </Link>
        <CusButton>Register</CusButton>
      </div>

      <div tw="lg:hidden">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            variant="outline"
          />
          <MenuList>
            <MenuItem>
              <Link tw="hover:no-underline " to="/auth/login">
                Login
              </Link>
            </MenuItem>
            <MenuItem>Register</MenuItem>
          </MenuList>
        </Menu>
      </div>
    </nav>
  );
};

const CusButton = ({ children }) => {
  return (
    <Button tw="text-white bg-[#6500E0] !py-4 !px-[38px] hover:bg-[#6500C0] !text-xs font-bold ">
      {children}
    </Button>
  );
};

const ServicesCard = ({ logo, para, title }) => {
  return (
    <div tw="bg-[#F0E5FC] flex flex-col justify-start items-center p-[38px] rounded-[20px] ">
      <Image src={`/assets/${logo}.png`} />

      <h4 tw="my-2.5 text-lg font-semibold">{title}</h4>
      <p tw="text-[#3A3A3A] text-sm leading-[22.806px] font-medium">{para}</p>
    </div>
  );
};

export default LandingPage;
