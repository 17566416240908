/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  HStack,
  Image,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';
import { CSVLink } from 'react-csv';
import CurrencyFormat from 'react-currency-format';
import { Link, useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import Pagination from '../Common/DataGridFunctionalities/Pagination';

const DataGridTable = ({
  rows,
  columns,
  limited,
  hasExportBtn,
  children,
  pageSize,
  pagination,
  pageId,
  recordCount,
  isValidating,
  title,
  offsetLabel,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const download = () => {
    console.log('download');
  };

  const handlePageChange = page => {
    navigate({
      pathname: location.pathname,
      search: `?${offsetLabel || 'offset'}=${(page - 1) * 30}`,
    });
  };

  return (
    <Box mt="1rem">
      {!limited && (
        <HStack
          mb={6}
          width="100%"
          spacing={4}
          justifyContent={'space-between'}
        >
          {children}

          {hasExportBtn && (
            <CSVLink
              data={hasExportBtn || []}
              filename={(title || 'Paysure_transaction') + '.csv'}
              target="_blank"
            >
              <Button
                bg="#F3F7FC"
                w={['85%', '118px']}
                fontSize={['14px', '1rem']}
                h={['35px', '4flex1px']}
                color="#6500E0"
                borderRadius="12px"
                onClick={download}
                variant="solid"
              >
                Export Data
              </Button>
            </CSVLink>
          )}
        </HStack>
      )}
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr py="14px" background="#FAFAFA">
              {columns.map(({ headerName, key }) => (
                <Th
                  key={key}
                  fontSize="14px"
                  fontWeight="400"
                  color="#505780"
                  letterSpacing="-0.025em"
                  // borderRight="1px solid #BAC7DE"
                  textTransform="uppercase"
                >
                  {headerName}
                </Th>
              ))}
            </Tr>
          </Thead>
          {rows.length > 0 ? (
            <Tbody>
              {rows.map((propps, idx) => {
                return (
                  <Tr key={idx}>
                    {Object.entries(propps).map((val, idx) => {
                      if (val[0] === 'action') {
                        return (
                          <Td
                            key={idx}
                            fontSize="14px"
                            cursor="pointer"
                            color="#6500E0"
                            fontWeight="400"
                            tw="flex space-x-4 w-full"
                          >
                            {/* view */}
                            {val[1]?.link && (
                              <>
                                <Link to={val[1]?.link} tw="w-6 h-6">
                                  <Image
                                    src="/assets/eye.png"
                                    alt=""
                                    h="24px"
                                    w="24px"
                                  />
                                </Link>
                              </>
                            )}
                            {/* edit */}
                            {val[1]?.edit && <>{val[1]?.edit}</>}
                            {val[1]?.editlink && (
                              <>
                                <Link to={val[1]?.editlink} tw="w-6 h-6">
                                  <Image
                                    src="/assets/edit.png"
                                    alt=""
                                    h="24px"
                                    w="24px"
                                  />
                                </Link>
                              </>
                            )}
                            {/* assign */}
                            {val[1]?.assign && <>{val[1]?.assign}</>}
                            {/* wallet */}
                            {val[1]?.wallet && <>{val[1]?.wallet}</>}
                            {/* print */}
                            {val[1]?.print && (
                              <>
                                <span tw="inline-block w-6 h-6">
                                  <Image
                                    src="/assets/print.png"
                                    alt=""
                                    h="24px"
                                    w="24px"
                                  />
                                </span>
                              </>
                            )}
                          </Td>
                        );
                      }

                      if (val[0] === 'status') {
                        return (
                          <>
                            {val[1].toLowerCase() === 'completed' ||
                            val[1].toLowerCase() === 'active' ? (
                              <Td fontWeight="400">
                                <span tw="text-[#136F63] bg-[#E9FBF9] text-[10px] p-1 rounded-[4px]">
                                  {val[1]}
                                </span>
                              </Td>
                            ) : val[1].toLowerCase() === 'failed' ||
                              val[1].toLowerCase() === 'rejected' ||
                              val[1].toLowerCase() === 'unassigned' ||
                              val[1].toLowerCase() === 'inactive' ? (
                              <Td fontWeight="400">
                                <span tw="text-[#EDA95A] bg-[#FCEAE8] text-[10px] p-1 rounded-[4px]">
                                  {val[1]}
                                </span>
                              </Td>
                            ) : val[1].toLowerCase() === 'unapproved' ? (
                              <Td fontWeight="400">
                                <span tw="text-[#A6B7D4] bg-[#E4ECF7] text-[10px] p-1 rounded-[4px]">
                                  {val[1]}
                                </span>
                              </Td>
                            ) : (
                              <Td fontWeight="400">
                                <span tw="text-[10px] p-1 rounded-[4px]">
                                  {val[1]}
                                </span>
                              </Td>
                            )}
                          </>
                        );
                      }

                      if (val[0] === 'terminals') {
                        return (
                          <>
                            {val[1].length !== 0 ? (
                              <Td fontWeight="400" tw="space-x-1 text-sm">
                                {val[1].slice(0, 2).map((item, index) => {
                                  return (
                                    <span key={index}>
                                      {item}
                                      {index !== val[1].length - 1 && ', '}
                                    </span>
                                  );
                                })}
                                {val[1].length > 2 && (
                                  <span tw="ml-2">+{val[1]?.length - 2}</span>
                                )}
                              </Td>
                            ) : (
                              <Td fontWeight="400 text-sm">
                                <spn>0</spn>
                              </Td>
                            )}
                          </>
                        );
                      }

                      return (
                        <Td fontSize="14px" fontWeight="400">
                          {val[0].endsWith('N') ? (
                            <CurrencyFormat
                              value={val[1]}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'₦'}
                            />
                          ) : (
                            val[1]
                          )}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Tbody tw="relative h-28">
              <div tw="absolute w-full justify-center items-center flex">
                <p tw="text-gray-500 text-center py-10  ">No data found</p>
              </div>
            </Tbody>
          )}
        </Table>
      </TableContainer>

      {/* Pagination */}
      {pagination && rows.length > 0 && (
        <Skeleton isLoaded={!isValidating}>
          <Pagination
            totalPages={Math.ceil(Number(recordCount) / 30)}
            currentPage={pageId}
            handlePageChange={handlePageChange}
            offsetLabel={offsetLabel}
          />
        </Skeleton>
      )}
    </Box>
  );
};

export default DataGridTable;
