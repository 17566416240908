/** @jsxImportSource @emotion/react */
// eslint-disable-next-line
import tw from 'twin.macro';
import React from 'react';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';
import { swrfetcher } from '../../utils/swrFetcher';
import DataGridViewTemp from '../Common/DataGridViewTemp';

const TerminalListPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/terminals/analytics/getTerminalsByBank?limit=10&offset=${
          offset || 0
        }`
      : `/apis/bizzdesk/organization/terminals/analytics/getTerminalsByBank?limit=10&offset=${
          offset || 0
        }`;

  const tableCountUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/terminals/analytics/getTerminalsByBank/count?limit=10&offset=${
          offset || 0
        }`
      : `/apis/bizzdesk/organization/terminals/analytics/getTerminalsByBank/count?limit=10&offset=${
          offset || 0
        }`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableCount } = useSWR(
    [tableCountUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          bank: item?.bank,
          noOfterminals: item?.noOfTerminals,
          action: { link: true },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 's/n',
    },
    {
      headerName: 'bank',
    },
    {
      headerName: 'terminals',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <div tw="mb-10 -mt-10">
      <DataGridViewTemp
        // link="/terminals/list"
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
        hasExportBtn={tableData}
        pagination
        recordCount={tableCount || 0}
        page={offset / 30 + 1}
      >
        <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)" />
      </DataGridViewTemp>
    </div>
  );
};

export default TerminalListPage;
