/** @jsxImportSource @emotion/react */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { swrfetcher } from '../../../utils/swrFetcher';
import DatRangePickerAndOthers from '../../Common/DataGridFunctionalities/DatRangePickerAndOthers';
import FilterBox from '../../Common/DataGridFunctionalities/FilterBox';
import SearchBar from '../../Common/DataGridFunctionalities/SearchBar';
import DataGridViewTemp from '../../Common/DataGridViewTemp';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

const BuisnessMerchant = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const [searchValue, setSearchValue] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [date, setDate] = React.useState(['', '']);

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/admin/analytics/getMerchantAnalyticsTable?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`
      : `/apis/bizzdesk/organization/businessMerchants/getMerchantAnalyticsTable?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`;

  const tableCountUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/admin/analytics/getMerchantAnalyticsTable/count?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`
      : `/apis/bizzdesk/organization/businessMerchants/getMerchantAnalyticsTable/count?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`;

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableCount } = useSWR(
    [tableCountUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: Number(offset || 0) + index + 1,
          merchantId: item?.merchantId,
          name: item?.fullname,
          organizationName: item?.organizationName,
          terminals: item?.terminals,
          walletbalanceN: item?.walletBallance,
          walletaccount: item?.walletAccount,
          transactionN: item?.totalAmountInTransactions,
          nooftransactions: item?.noOfTransactions,
          charge: item?.charge,
          datecreated: item?.dateAdded,
          status: item?.status,
          supportstaff: item?.supportStaff,
          action: {
            disable: true,
            link: `/business-merchant/${item?.username}`,
            edit: true,
          },
        };
      });
    }
  }

  const TransColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'merchant id',
    },
    {
      headerName: 'name',
    },
    {
      headerName: 'Organization Name',
    },
    {
      headerName: 'terminals',
    },
    {
      headerName: 'wallet balance',
    },
    {
      headerName: 'wallet account',
    },
    {
      headerName: 'Total Transaction Amount(N)',
    },
    {
      headerName: 'No of Transaction',
    },
    {
      headerName: 'charges',
    },
    {
      headerName: 'date created',
    },
    {
      headerName: 'status',
    },
    {
      headerName: 'support staff',
    },
  ];

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        style={{ fontSize: '14px', fontWeight: '300' }}
        tw="mb-4 hover:underline"
      >
        Back
      </button>

      <DataGridViewTemp
        title="List of Merchants"
        rows={rows}
        columns={TransColumn}
        isValidating={isValidating}
        hasExportBtn={tableData}
        pagination
        recordCount={tableCount || 0}
        page={offset / 30 + 1}
      >
        <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
          <SearchBar value={searchValue} setSearchValue={setSearchValue} />
          <FilterBox
            dropdownData={['All', 'Active', 'Inactive']}
            value={status}
            setFilterValue={setStatus}
          />
          <DatRangePickerAndOthers date={date} setDate={setDate} />
        </div>
      </DataGridViewTemp>
    </>
  );
};

export default BuisnessMerchant;
