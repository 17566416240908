/** @jsxImportSource @emotion/react */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { swrfetcher } from '../../../utils/swrFetcher';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import DatRangePickerAndOthers from '../../Common/DataGridFunctionalities/DatRangePickerAndOthers';
import DataGridViewTemp from '../../Common/DataGridViewTemp';

const BBMMainWalletPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const [date, setDate] = React.useState(['', '']);

  const tableUrl = `/apis/v1/bizzdesk/admin/wallet/MainWallet/getWalletActivities?limit=30&offset=${
    offset || 0
  }${!date[0] ? '' : `&startDate=${date[0]}`}${
    !date[1] ? '' : `&endDate=${date[1]}`
  }`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          transactiontype: item?.transType,
          description: item?.transinfo,
          debitN: item?.debit,
          creditN: item?.credit,
          balanceN: item?.newamount,
          date: item?.date,
          action: {
            link: `/wallet/${item?.userName}`,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'transaction type',
    },
    {
      headerName: 'description',
    },
    {
      headerName: 'debit',
    },
    {
      headerName: 'credit',
    },
    {
      headerName: 'balance',
    },
    {
      headerName: 'date',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <>
      <button
        onClick={() => navigate(-1)}
        style={{ fontSize: '14px', fontWeight: '300' }}
        tw="mb-4 hover:underline"
      >
        Back
      </button>

      <DataGridViewTemp
        title="BBM Main Wallets"
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
        hasExportBtn={tableData}
        pagination
        recordCount={100}
        page={offset / 10 + 1}
      >
        <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
          <DatRangePickerAndOthers date={date} setDate={setDate} />
        </div>
      </DataGridViewTemp>
    </>
  );
};

export default BBMMainWalletPage;
