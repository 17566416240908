/** @jsxImportSource @emotion/react */
import { Box } from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';
import DataGridViewTemp from '../Common/DataGridViewTemp';
import WalletBalance from '../Common/WalletBalance';

const Overview = () => {
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const url =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/wallet/analytics/getAmountInWallets'
      : '/apis/bizzdesk/organization/wallet/getIncomeWalletBalance';
  const tableUrl = `/apis/v1/bizzdesk/admin/wallet/MainWallet/getWalletActivities?limit=10&offset=0`;

  const { data } = useSWR([url, token, 'GET'], ([url, USER_TOKEN, method]) =>
    swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          transactiontype: item?.transType,
          description: item?.transinfo,
          debitN: item?.debit,
          creditN: item?.credit,
          balanceN: item?.newamount,
          date: item?.date,
          action: {
            link: `#`,
            print: true,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'transaction type',
    },
    {
      headerName: 'description',
    },
    {
      headerName: 'debit',
    },
    {
      headerName: 'credit',
    },
    {
      headerName: 'balance',
    },
    {
      headerName: 'date',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <>
      <Box
        w="full"
        h={['100%', '163px']}
        p={['1rem', '0']}
        borderRadius="20px"
        bg="#EBF2FA"
        position="relative"
        mt="2rem"
      >
        {/* <Box
          justifyContent="center"
          display="flex"
          w="full"
          h="full"
          flexDirection={['column', 'row']}
          alignItems="flex-start"
          zIndex="100"
          gap={['6', '0']}
          tw="bg-bgSVG bg-no-repeat bg-cover bg-center"
        >
          <Box
            justifyContent="center"
            display="flex"
            flexDirection="column"
            float="left"
            w={['full', '50%']}
            h="full"
            ml="2rem"
            alignItems="flex-start"
            zIndex="100"
            gap="3"
          >
            <Skeleton isLoaded={data}>
              <Text zIndex="100" fontSize="16px" fontWeight="500">
                Wallet Balance
              </Text>

              <Text
                as="div"
                zIndex="100"
                display="flex"
                fontSize="40px"
                fontWeight="500"
              >
                <CurrencyFormat
                  value={data?.mainWallet}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₦'}
                />
              </Text>
            </Skeleton>
          </Box>
          <Box
            float="right"
            display="flex"
            gap="3"
            w={['full', '50%']}
            h="full"
            alignItems="center"
            justifyContent="flex-end"
            mr="2rem"
            flexDirection={['column', 'row']}
          >
            <FundWalletModal />
            <Button
              w="185px"
              h="56px"
              borderRadius="12px"
              color="#fff"
              bg="#191716"
            >
              Withdraw
            </Button>
          </Box>
        </Box> */}
        <WalletBalance
          label="Wallet Balance"
          balance={data?.mainWallet}
          data={data}
          hasNoFundWallet={userType === 'admin' ? false : true}
          // hasNoWithdraw
        />
      </Box>

      <DataGridViewTemp
        link="/wallet/main-wallet-list"
        limited
        title={`${userType === 'admin' ? 'BBM' : 'Organization'} Main Wallet`}
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
      />
    </>
  );
};

export default Overview;
