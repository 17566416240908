import {
  Box,
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

function CreateMerchantModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  //   validation

  const validationSchema = Yup.object().shape({
    merchantBand: Yup.string().required('This field is required'),
    transactionTarget: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const validate = (data, event) => {
    event.preventDefault();
    const input = {
      merchantBand: data?.merchantBand,
      transactionTarget: data?.transactionTarget,
    };

    setLoading(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/band/new/addBand',
      data: input,
    })
      .then(response => {
        setLoading(false);
        toast({
          title: 'A New Role Created!.',
          position: 'top',
          description: 'A New Band has been Created Successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: 'It is not you, it i us, wait as we resolve the issue.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Image src="/assets/add-square.png" alt="" />}
        bg="#6500E0"
        w="191px"
        fontSize="13px"
        h="42px"
        borderRadius="8px"
        colorScheme="#6500E0"
        variant="solid"
      >
        Create Merchant Band
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Create Merchant Band
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <form onSubmit={handleSubmit(validate)}>
              <Box
                mt="1rem"
                p="1rem"
                display="flex"
                flexDirection="column"
                gap="3"
              >
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Merchant Band
                  </Text>
                  <Input
                    placeholder=""
                    borderRadius="4px"
                    color="#333"
                    {...register('merchantBand')}
                    border={
                      errors.merchantBand
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Transaction Target
                  </Text>
                  <Input
                    placeholder=""
                    {...register('transactionTarget')}
                    border={
                      errors.transactionTarget
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                </VStack>
                <Box w="full">
                  {loading ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w={['full', '30%']}
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      float="right"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w={['full', '30%']}
                      color="#fff"
                      align="center"
                      p="1rem"
                      float="right"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  )}
                </Box>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateMerchantModal;
