import {
  Box,
  Button,
  // InputRightElement,
  // InputGroup,
  // Select,
  Image,
  Input,
} from '@chakra-ui/react';
import axios from 'axios';
import 'js-file-download';
import React from 'react';

const FilterMerchant = ({
  setSecondDate,
  setFirstDate,
  setSearch,
  filterTransactions,
}) => {
  const fileDownload = require('js-file-download');
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  const download = () => {
    setLoading(true);

    axios({
      method: 'get',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/signup/agentsSignups/findAllByAggregatorsFullnameAndStatus?limit=100&offset=0',
    })
      .then(data => {
        setLoading(false);
        fileDownload(data.data, 'merchant-list.csv');
      })
      .catch(err => {
        setLoading(false);
      });
  };
  return (
    <Box
      display="flex"
      gap="6"
      justifyContent="space-between"
      w="full"
      flexDirection={['column', 'row']}
      mt="2rem"
      mb={['1rem', '0']}
    >
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        gap="2"
        float="left"
      >
        {/* <Select
          fontWeight="300"
          textAlign="center"
          borderRadius="4px"
          fontSize="12px"
          alignItems="flex-start"
          color="#425D8A"
          onChange={e => setSearch(e.target.value)}
          placeholder="Showing: All"
        >
          <Text as='option' p='1rem' value="TRANSFER INCOME">Transfer Income</Text>
          <Text as='option' p='1rem' value="PURCHASE SETTLEMENT">Purchase Settlement</Text>
        </Select> */}
        <Box
          display="flex"
          border="1px solid #ccc"
          color="#425D8A"
          alignItems="center"
          borderRadius="4px"
        >
          <Input
            type="date"
            border="none"
            borderRight="1px solid #ccc !important"
            borderRadius="none"
            outline="none !important"
            className="filter"
            onChange={e => setFirstDate(e.target.value)}
          />
          <Input
            type="date"
            border="none"
            outline="none"
            onChange={e => setSecondDate(e.target.value)}
          />
        </Box>
        <Button w={['full', '50%']} onClick={() => filterTransactions()}>
          Search
        </Button>
      </Box>
      <Box float="right">
        <Button
          bg="#F3F7FC"
          w={['85%', '118px']}
          fontSize={['14px', '1rem']}
          h={['35px', '41px']}
          color="#6500E0"
          borderRadius="12px"
          variant="solid"
          onClick={download}
        >
          Export Data
        </Button>
      </Box>
    </Box>
  );
};

export default FilterMerchant;
