/** @jsxImportSource @emotion/react */
import { Box } from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';
import DataGridViewTemp from '../Common/DataGridViewTemp';
import WalletBalance from '../Common/WalletBalance';

const Overview = () => {
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const url =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/wallet/analytics/getAmountInWallets'
      : '/apis/bizzdesk/organization/wallet/getIncomeWalletBalance';

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/IncomeWallet/getWalletActivities?limit=10&offset=0`
      : '/apis/bizzdesk/organization/wallet/getIncomeWalletActivities?limit=10&offset=0';

  const { data } = useSWR([url, token, 'GET'], ([url, USER_TOKEN, method]) =>
    swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          transactiontype: item?.transType,
          description: item?.transinfo,
          debitN: item?.debit,
          creditN: item?.credit,
          balanceN: item?.newamount,
          date: item?.date,
          action: {
            link: `#`,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'transaction type',
    },
    {
      headerName: 'description',
    },
    {
      headerName: 'debit',
    },
    {
      headerName: 'credit',
    },
    {
      headerName: 'balance',
    },
    {
      headerName: 'date',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <>
      <Box
        w="full"
        h={['100%', '163px']}
        p={['1rem', '0']}
        borderRadius="20px"
        bg="#EBF2FA"
        position="relative"
        mt="2rem"
      >
        <WalletBalance
          label={`${
            userType === 'admin' ? 'BBM' : 'Organization'
          } Income Wallet Balance`}
          balance={
            userType === 'admin'
              ? data?.bizzdeskIncomeWallet
              : data?.data?.walletBalance
          }
          data={data}
          hasNoFundWallet={userType === 'admin' ? false : true}
        />
      </Box>

      <DataGridViewTemp
        link="/wallet/income_wallet_list"
        limited
        title={`${userType === 'admin' ? 'BBM' : 'Organization'} Income Wallet`}
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
      />
    </>
  );
};

export default Overview;
