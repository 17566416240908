import { Box, Select } from '@chakra-ui/react';
import React from 'react';

const AddMerchantFilter = () => {
  return (
    <Box
      display="flex"
      gap="6"
      justifyContent="space-between"
      w="full"
      flexDirection={['column', 'row']}
      mt="2rem"
      mb={['1rem', '0']}
    >
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        gap="2"
        float="left"
      >
        {/* <InputGroup>
          <Input fontSize="13px" placeholder="Search All" />
          <InputRightElement
            children={<Image src="assets/search-normal.png" alt="" />}
          />
        </InputGroup> */}
        <Select
          fontWeight="300"
          textAlign="center"
          borderRadius="4px"
          fontSize="12px"
          alignItems="flex-start"
          color="#425D8A"
          placeholder="Showing: All"
        >
          <option style={{ margin: '.75rem' }} value="option1">
            Option 1
          </option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </Select>
        <Select
          fontWeight="300"
          textAlign="center"
          borderRadius="4px"
          fontSize="12px"
          alignItems="flex-start"
          color="#425D8A"
          placeholder="Last month"
        >
          <option style={{ margin: '.75rem' }} value="option1">
            Option 1
          </option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </Select>
      </Box>
      {/* <Box float="right">
        <Button
          bg="#F3F7FC"
          w={['85%', '118px']}
          fontSize={['14px', '1rem']}
          h={['35px', '41px']}
          color="#6500E0"
          borderRadius="12px"
          variant="solid"
        >
          Export Data
        </Button>
      </Box> */}
    </Box>
  );
};

export default AddMerchantFilter;
