/** @jsxImportSource @emotion/react */
import { Skeleton } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import tw from 'twin.macro';
import FilterBox from './DataGridFunctionalities/FilterBox';

function ContentComp(props) {
  return (
    <Wrapper
      css={[
        tw`px-4 flex flex-col justify-center lg:(px-6) transition-colors duration-300 `,
        props.value
        ? tw`min-h-[194px] lg:max-h-[204px] xl:max-h-[214px]`
        : tw`h-[132px]`,
        props.link && tw`hover:bg-[rgb(209 219 236)]`,
      ]}
    >
      {props.hasFilter && (
        <div tw="w-3/5 mt-2 ml-auto">
          <FilterBox
            dropdownData={[
              'Today',
              'Yesterday',
              'This Week',
              'This Month',
              'Last Month',
              'This Year',
            ]}
            value={props.hasFilter}
            setFilterValue={props.setFilter}
            hasNoLabel
          />
        </div>
      )}
      <>
        <Skeleton
          isLoaded={props.isValidating}
          height={!props.isValidating && '40px'}
        >
          <H1>{props.amount}</H1>
        </Skeleton>
        {props.value && (
          <Skeleton
            width={32}
            isLoaded={props.isValidating}
            mt={!props.isValidating && 3}
            height={!props.isValidating && '15px'}
          >
            <p
              css={[
                props.isOverview ? tw`` : tw`bg-white px-2 lg:px-3 2xl:px-4`,
                tw`leading-[24px] py-0.5 xl:py-1 2xl:py-[6px] rounded mt-2 w-min text-sm lg:text-base`,
              ]}
            >
              {props.value}
            </p>
          </Skeleton>
        )}
        <Skeleton
          isLoaded={props.isValidating}
          mt={!props.isValidating && 3}
          height={!props.isValidating && '15px'}
        >
          <p tw="truncate text-sm text-[#425D8A] mt-2 md:text-xs lg:(text-sm mt-4) 2xl:text-base">
            {props.title}
          </p>
        </Skeleton>
      </>
    </Wrapper>
  );
}

const TransactionCard = ({
  title,
  amount,
  value,
  isValidating,
  isOverview,
  link,
  hasFilter,
  setFilter,
}) => {
  return (
    <>
      {link ? (
        <Link to={link}>
          <ContentComp
            title={title}
            amount={amount}
            value={value}
            isValidating={isValidating}
            isOverview={isOverview}
            link={link}
            hasFilter={hasFilter}
            setFilter={setFilter}
          />
        </Link>
      ) : (
        <ContentComp
          title={title}
          amount={amount}
          value={value}
          isValidating={isValidating}
          isOverview={isOverview}
          hasFilter={hasFilter}
          setFilter={setFilter}
        />
      )}
    </>
  );
};

const Wrapper = tw.div`bg-[#E4ECF7] min-w-[130px] border border-[#F3F7FC] rounded-lg transition-colors lg:(min-w-[235px]) `;
const H1 = tw.h1`text-2xl sm:text-3xl md:text-2xl 2xl:text-[33px] text-[#191716] font-bold`;

export default TransactionCard;
