/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Grid, Text } from '@chakra-ui/react';
import AddSubadminModal from './AddSubadminModal';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';
import DataGridViewTemp from '../Common/DataGridViewTemp';

const Subadmin = () => {
  const tableUrl =
    '/apis/v1/bizzdesk/admin/SubAdmin/getSubAdminTable?limit=10&offset=0';

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          name: item?.fullNameOfAdmin,
          roles: item?.role,
          email: item?.email,
          datejoined: item?.dateCreated,
          action: {
            edit: true,
            delete: true,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'name',
    },
    {
      headerName: 'roles',
    },
    {
      headerName: 'email',
    },
    {
      headerName: 'date joined',
    },
    {
      headerName: 'actions',
    },
  ];

  return (
    <Box>
      <Box
        display="flex"
        gap="6"
        justifyContent="space-between"
        w="full"
        flexDirection={['column', 'row']}
        mt="1rem"
      >
        <Text float="left" fontSize="32px" color="#16192C" fontWeight="700">
          Sub-admins
        </Text>
        <Box float="right">
          <AddSubadminModal />
        </Box>
      </Box>
      <Box
        w="full"
        h={['100%', '100%']}
        mt="1rem"
        border="1px solid #E2E8F0"
        borderRadius="20px"
        p="1rem"
        display="flex"
        flexDirection="column"
        gap="3"
      >
        <Text color="#505780" fontSize="16px" fontWeight="500">
          Overview
        </Text>
        <Grid templateColumns={['repeat(2,1fr)', 'repeat(4,1fr)']} gap="3">
          <Box w={['full', '75%']} p="1rem" h="100%">
            <Text color="#000" fontSize="32px" fontWeight="500">
              15
            </Text>

            <Text color="#8F99A3" fontSize="14px" fontWeight="400">
              Sub-admins
            </Text>
          </Box>
          <Box w={['full', '75%']} p="1rem" h="100%">
            <Text color="#000" fontSize="32px" fontWeight="500">
              4
            </Text>

            <Text color="#8F99A3" fontSize="14px" fontWeight="400">
              Administrators
            </Text>
          </Box>
          <Box w={['full', '75%']} p="1rem" h="100%">
            <Text color="#000" fontSize="32px" fontWeight="500">
              3
            </Text>

            <Text color="#8F99A3" fontSize="14px" fontWeight="400">
              Account admins
            </Text>
          </Box>
          <Box w={['full', '75%']} p="1rem" h="100%">
            <Text color="#000" fontSize="32px" fontWeight="500">
              3
            </Text>

            <Text color="#8F99A3" fontSize="14px" fontWeight="400">
              Account users
            </Text>
          </Box>
        </Grid>
      </Box>

      <DataGridViewTemp
        link={'/sub-admin/sub_admin_list'}
        limited
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
      />
    </Box>
  );
};

export default Subadmin;
