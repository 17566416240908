/** @jsxImportSource @emotion/react */
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';
import DataGridViewTemp from '../Common/DataGridViewTemp';
import TransactionCard from '../Common/TransactionCard';
import EditTerminalModal from './Modals/EditTerminalModal';
import ViewTerminal from './Modals/ViewTerminal';
import ReassignTerminalModal from './Modals/ReassignTerminalModal';

const Overview = () => {
  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const url =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/terminals/analytics'
      : '/apis/bizzdesk/organization/terminals/analytics';

  const tableUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/terminals/getTerminals?limit=10&offset=0'
      : '/apis/bizzdesk/organization/terminals/getTerminals?limit=10&offset=0';

  const { data } = useSWR([url, token, 'GET'], ([url, USER_TOKEN, method]) =>
    swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  /* A data for the transactionStatsData component. */
  const transactionStatsData = [
    {
      amount: (
        <CurrencyFormat
          value={data?.totalTerminals}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
      title: 'Total terminals',
    },
    {
      amount: (
        <CurrencyFormat
          value={data?.totalActiveTerminals}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
      title: 'Total active terminals',
    },
    {
      amount: (
        <CurrencyFormat
          value={data?.totalInactiveTerminals}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
      title: 'Total inactive terminals',
    },
  ];

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          terminalId: item?.tid,
          serialno: item?.serialNo,
          bank: item?.bank,
          amountN: item?.transactionAmount,
          transactions: item?.transactions,
          chargeN: item?.nibbsRate,
          businessmerchant: item?.agentFullName,
          status: item?.status,
          lasttransaction: item?.transactionDate,
          action: {
            edit: (
              <EditTerminalModal
                serialnumber={item?.serialNo}
                email={item?.agentEmail}
                userEmail={item?.tid}
              />
            ),
            assign: (
              <ReassignTerminalModal
                serialno={item?.serialNo}
                status={item?.agentFullName}
                email={item?.agentEmail}
                userTid={item?.tid}
              />
            ),
            wallet: (
              <ViewTerminal
                serialnumber={item?.serialNo}
                userEmail={item?.tid}
              />
            ),
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 's/n',
    },
    {
      headerName: 'terminal id',
    },
    {
      headerName: 'serial no',
    },
    {
      headerName: 'bank',
    },
    {
      headerName: 'total transaction amount',
    },
    {
      headerName: 'transaction count',
    },
    {
      headerName: 'charge',
    },
    {
      headerName: 'buisness merchant',
    },
    {
      headerName: 'status',
    },
    {
      headerName: 'last transaction',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <>
      <div tw="grid grid-cols-2 gap-3 md:grid-cols-3 xl:(gap-5)">
        {transactionStatsData.map(({ amount, value, link, title }, index) => {
          return (
            <TransactionCard
              key={index}
              title={title}
              amount={amount}
              value={value}
              isValidating={data}
            />
          );
        })}
      </div>

      <DataGridViewTemp
        link="/terminals/list"
        limited
        title="Terminals"
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
      />

      {/* <ListOfTerminal /> */}
    </>
  );
};

export default Overview;
