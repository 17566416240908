import { Box, Text } from '@chakra-ui/react';
import React from 'react';

const Pagination = ({ postPerPage, totalPosts, paginate }) => {
  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <Box pb="1rem" mt="1rem">
      <Box display="flex" gap="2">
        {pageNumbers.map(number => (
          <Text
            onClick={() => paginate(number)}
            padding=".55rem 1rem"
            _active={{ bg: '#6500E0', color: '#fff' }}
            _focus={{ bg: '#6500E0', color: '#fff' }}
            cursor="pointer"
            border="1px solid #ccc"
            key={number}
          >
            <Text _active={{ color: '#fff' }} color="blue">
              {' '}
              {number}
            </Text>
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default Pagination;

// SEttlements ==> REceivable amount
// pos transaction overvieow
