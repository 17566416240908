/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../component/Common/Sidebar';
import Topnav from '../component/Common/Topnav';
import DataGridViewTemp from '../component/Common/DataGridViewTemp';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import DatRangePickerAndOthers from '../component/Common/DataGridFunctionalities/DatRangePickerAndOthers';
import FilterBox from '../component/Common/DataGridFunctionalities/FilterBox';
import useSWR from 'swr';
import SearchBar from '../component/Common/DataGridFunctionalities/SearchBar';
import { swrfetcher } from '../utils/swrFetcher';

export default function Signups() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');
  const statusFronUrl = query.get('status');

  const [searchValue, setSearchValue] = React.useState('');
  const [status, setStatus] = React.useState(statusFronUrl || 'unapproved');
  const [date, setDate] = React.useState(['', '']);

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/signup/agentsSignups/findAllByAggregatorsFullnameAndStatus?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`
      : `/apis/bizzdesk/organization/merchantSignups/getTable?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`;

  const tableCountUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/signup/agentsSignups/findAllByAggregatorsFullnameAndStatus/count?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`
      : `/apis/bizzdesk/organization/merchantSignups/getTable/count?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableCount } = useSWR(
    [tableCountUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: Number(offset || 0) + index + 1,
          fullName: item?.fullName,
          email: item?.email,
          phoneNumber: item?.phoneNumber,
          bvn: item?.bvn,
          businessName: item?.businessName,
          businessAddress: item?.businessAddress,
          dateAdded: item?.dateAdded,
          status: item?.status,
          action: {
            link: `/business-merchant/merchant-details/${item?.email}`,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'full name',
    },
    {
      headerName: 'email',
    },
    {
      headerName: 'phone number',
    },
    {
      headerName: 'bvn',
    },
    {
      headerName: 'business name',
    },
    {
      headerName: 'business address',
    },
    {
      headerName: 'date added',
    },
    {
      headerName: 'status',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <Box minH="100vh" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <>
          <button
            onClick={() => navigate(-1)}
            style={{ fontSize: '14px', fontWeight: '300' }}
            tw="mb-4 hover:underline"
          >
            Back
          </button>

          <DataGridViewTemp
            title="List of New Signups"
            rows={[]}
            columns={TransWalletColumn}
            // isValidating={isValidating}
            hasExportBtn={tableData}
            pagination
            recordCount={tableCount || 0}
            page={offset / 30 + 1}
          >
            <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
              <SearchBar value={searchValue} setSearchValue={setSearchValue} />
              <FilterBox
                dropdownData={['Unapproved', 'Active', 'Rejected']}
                value={status}
                setFilterValue={setStatus}
              />
              <DatRangePickerAndOthers date={date} setDate={setDate} />
            </div>
          </DataGridViewTemp>
        </>
      </Box>
    </Box>
  );
}
