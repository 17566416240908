import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import PosList from './PosList';

const PosPage = () => {
  //   const fileDownload = require('js-file-download');
  //   const token = JSON?.parse(
  //      localStorage.getItem('token') || sessionStorage.getItem('token')
  //   );

  const navigate = useNavigate();

  return (
    <Box>
      <button
        onClick={() => navigate(-1)}
        style={{ fontSize: '14px', fontWeight: '300' }}
        tw="mb-4 hover:underline"
      >
        Back
      </button>
      <Box
        w="full"
        h="100%"
        mt="1rem"
        display="flex"
        justifyContent="space-between"
      >
        <Text float="left" fontWeight="700" fontSize="32px">
          Pos Transactions
        </Text>
        {/* <Box display="flex" flexDirection={['column', 'row']} gap="6">
          <AddTerminalModal />
        </Box> */}
      </Box>
      <Box
        mt="1rem"
        display="flex"
        gap={['3', '3']}
        flexDirection={['column', 'row']}
      >
        <Box w={['full', '100%']}>
          <Box
            display="flex"
            gap="6"
            justifyContent="space-between"
            w="full"
            flexDirection={['row', 'row']}
            mt="2rem"
          >
            <Text fontWeight="500" color="#191716" float="left">
              Pos Transactions Table
            </Text>
            {/* <HStack float="right">
              <Link style={{ color: '#6500E0' }} to="/">
                View All
              </Link>
              <Image src="assets/merchant_blue_arrow_right.png" alt="" />
            </HStack> */}
          </Box>
          <PosList />
        </Box>
      </Box>
    </Box>
  );
};

export default PosPage;
