import React from 'react';
//import { ViewOffIcon, ViewIcon } from '@chakra-ui/icons';
import {
  Box,
  // InputRightElement,
  Button,
  Center,
  Image,
  Input,
  InputGroup,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
//import {Link} from 'react-router-dom'

const RecoverAccount = () => {
  // eslint-disable-next-line
  const [show, setShow] = React.useState(false);
  // const handleClick = () => setShow(!show);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  let navigate = useNavigate();
  let toast = useToast();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  //  end of validation

  const validate = (data, event) => {
    event.preventDefault();
    const input = {
      email: data?.email,
    };

    setLoading(true);

    axios({
      method: 'get',
      responseType: 'json',
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/signup/pin/recover/first/',
      data: input,
    })
      .then(response => {
        setLoading(false);
        toast({
          title: 'Successful.',
          position: 'top',
          description: 'You have been sent a mail to recover your account.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/create-password');
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: 'Please kindly verify your mail before logging in.',
          status: err.message,
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <Box
      w="full"
      h={['100%', '100vh']}
      display="flex"
      alignItems="center"
      justifyContent="base-start"
      flexDirection="column"
      mt="2rem"
    >
      <Image src="assets/logo_purple.png" alt="" />
      <Box
        mt="4rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text fontWeight="400" fontSize="24px">
          Recover your account
        </Text>
        <form onSubmit={handleSubmit(validate)}>
          <VStack mt="1rem" alignItems="baseline">
            <Text fontSize="13px" color="#454D54">
              Email address
            </Text>
            <InputGroup size="md">
              <Input
                h="42px"
                bg="#fff"
                color="#191716"
                w={['100%', '308px']}
                borderRadius="4px"
                border={errors.email ? '1px solid red' : ' 1px solid #E3E5E8'}
                type={show ? 'text' : 'text'}
                {...register('email')}
                placeholder=""
              />
              {/* <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={handleClick}>
                {show ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement> */}
            </InputGroup>
            <Text color="red" mt=".5rem">
              {errors.email?.message}
            </Text>
          </VStack>
          <Center w="full">
            {loading ? (
              <Button
                isLoading
                mt="2rem"
                w="full"
                align="center"
                p="1rem"
                borderRadius="4px"
                role="group"
                cursor="pointer"
                _hover={{
                  bg: '#5403b8',
                  color: 'white',
                }}
                loadingText="Loading"
                colorScheme="#6500E0"
                variant="outline"
              />
            ) : (
              <Button
                bg="#6500E0"
                mt="2rem"
                w="full"
                color="#fff"
                align="center"
                p="1rem"
                borderRadius="4px"
                role="group"
                cursor="pointer"
                _hover={{
                  bg: '#5403b8',
                  color: 'white',
                }}
                type="submit"
              >
                Recover your account
              </Button>
            )}
          </Center>
        </form>
      </Box>
    </Box>
  );
};

export default RecoverAccount;
