/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../component/Common/Sidebar';
import Topnav from '../component/Common/Topnav';
import BusinessPage from '../component/BusinessMerchant/pages/BusinessPage';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import tw from 'twin.macro';
import { swrfetcher } from '../utils/swrFetcher';
import DatRangePickerAndOthers from '../component/Common/DataGridFunctionalities/DatRangePickerAndOthers';
import FilterBox from '../component/Common/DataGridFunctionalities/FilterBox';
import SearchBar from '../component/Common/DataGridFunctionalities/SearchBar';
import DataGridViewTemp from '../component/Common/DataGridViewTemp';

const LOS = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const [searchValue, setSearchValue] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [date, setDate] = React.useState(['', '']);

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/admin/analytics/getMerchantAnalyticsTable?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`
      : `/apis/bizzdesk/organization/businessMerchants/getMerchantAnalyticsTable?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`;

  const tableCountUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/admin/analytics/getMerchantAnalyticsTable/count?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`
      : `/apis/bizzdesk/organization/businessMerchants/getMerchantAnalyticsTable/count?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`;

  // const { data: tableData, isValidating } = useSWR(
  //   [tableUrl, token, 'GET'],
  //   ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  // );

  // const { data: tableCount } = useSWR(
  //   [tableCountUrl, token, 'GET'],
  //   ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  // );

  // let rows = [];
  // if (tableData) {
  //   if (tableData?.status >= 400) {
  //     rows = [];
  //   } else {
  //     rows = tableData?.map((item, index) => {
  // return {
  //   id: index + 1,
  //   merchantId: item?.merchantId,
  //   name: item?.fullname,
  //   walletbalanceN: item?.walletBallance,
  //   walletaccount: item?.walletAccount,
  //   transactionN: item?.totalAmountInTransactions,
  //   nooftransactions: item?.noOfTransactions,
  //   charge: item?.charge,
  //   datecreated: item?.dateAdded,
  //   status: item?.status,
  //   action: {
  //     disable: true,
  //     link: `/business-merchant/${item?.username}`,
  //     edit: true,
  //   },
  // };
  //     });
  //   }
  // }

  const TransColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'merchant id',
    },
    {
      headerName: 'name',
    },
    {
      headerName: 'wallet balance',
    },
    {
      headerName: 'wallet account',
    },
    {
      headerName: 'Total Transaction Amount(N)',
    },
    {
      headerName: 'No of Transaction',
    },
    {
      headerName: 'charges',
    },
    {
      headerName: 'date created',
    },
    {
      headerName: 'status',
    },
  ];

  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <>
          <button
            onClick={() => navigate(-1)}
            style={{ fontSize: '14px', fontWeight: '300' }}
            tw="mb-4 hover:underline"
          >
            Back
          </button>

          <DataGridViewTemp
            title="List of Merchants"
            rows={[]}
            columns={TransColumn}
            // isValidating={isValidating}
            // hasExportBtn={tableData}
            // pagination
            // recordCount={tableCount || 0}
            // page={offset / 30 + 1}
          >
            <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
              <SearchBar value={searchValue} setSearchValue={setSearchValue} />
              <FilterBox
                dropdownData={['All', 'Active', 'Inactive']}
                value={status}
                setFilterValue={setStatus}
              />
              <DatRangePickerAndOthers date={date} setDate={setDate} />
            </div>
          </DataGridViewTemp>
        </>
      </Box>
    </Box>
  );
};

export default LOS;
