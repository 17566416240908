/** @jsxImportSource @emotion/react */
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import DatRangePickerAndOthers from '../component/Common/DataGridFunctionalities/DatRangePickerAndOthers';
import SearchBar from '../component/Common/DataGridFunctionalities/SearchBar';
import DataGridViewTemp from '../component/Common/DataGridViewTemp';
import Sidebar from '../component/Common/Sidebar';
import Topnav from '../component/Common/Topnav';
import EditTerminalModal from '../component/Terminals/Modals/EditTerminalModal';
import ReassignTerminalModal from '../component/Terminals/Modals/ReassignTerminalModal';
import ViewTerminal from '../component/Terminals/Modals/ViewTerminal';
import { swrfetcher } from '../utils/swrFetcher';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

const TerminalList = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const [searchValue, setSearchValue] = React.useState('');
  // const [status, setStatus] = React.useState('');
  const [date, setDate] = React.useState(['', '']);

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/terminals/getTerminals?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&tid=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}`
      : `/apis/bizzdesk/organization/terminals/getTerminals?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&tid=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}`;

  const terminalsTableCountUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/terminals/getTerminals/count?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&tid=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}`
      : `/apis/bizzdesk/organization/terminals/getTerminals/count?limit=30&offset=${
          offset || 0
        }${!searchValue ? '' : `&tid=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableCount } = useSWR(
    [terminalsTableCountUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          terminalId: item?.tid,
          serialno: item?.serialNo,
          bank: item?.bank,
          amountN: item?.transactionAmount,
          transactions: item?.transactions,
          chargeN: item?.nibbsRate,
          businessmerchant: item?.agentFullName,
          status: item?.status,
          lasttransaction: item?.transactionDate,
          action: {
            edit: (
              <EditTerminalModal
                serialnumber={item?.serialNo}
                email={item?.agentEmail}
                userEmail={item?.tid}
              />
            ),
            assign: (
              <ReassignTerminalModal
                serialno={item?.serialNo}
                status={item?.status}
                email={item?.agentEmail}
                userTid={item?.tid}
              />
            ),
            wallet: (
              <ViewTerminal
                serialnumber={item?.serialNo}
                userEmail={item?.tid}
              />
            ),
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 's/n',
    },
    {
      headerName: 'terminal id',
    },
    {
      headerName: 'serial no',
    },
    {
      headerName: 'bank',
    },
    {
      headerName: 'total transaction amount',
    },
    {
      headerName: 'transaction count',
    },
    {
      headerName: 'charge',
    },
    {
      headerName: 'buisness merchant',
    },
    {
      headerName: 'status',
    },
    {
      headerName: 'last transaction',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Box mb="1rem">
          <button
            onClick={() => navigate(-1)}
            style={{ fontSize: '14px', fontWeight: '300' }}
            tw="mb-4 hover:underline"
          >
            Back
          </button>
        </Box>
        <DataGridViewTemp
          hasMT
          title="Terminals"
          rows={rows}
          columns={TransWalletColumn}
          isValidating={isValidating}
          hasExportBtn={tableData}
          pagination
          recordCount={100}
          page={offset / 30 + 1}
        >
          <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
            <SearchBar value={searchValue} setSearchValue={setSearchValue} />
            {/* <FilterBox
              dropdownData={['All', 'Completed', 'Failed']}
              value={status}
              setFilterValue={setStatus}
            /> */}
            <DatRangePickerAndOthers date={date} setDate={setDate} />
          </div>
        </DataGridViewTemp>
      </Box>
    </Box>
  );
};

export default TerminalList;
