/** @jsxImportSource @emotion/react */
import React from 'react';
import tw from 'twin.macro';
// import Link from 'next/link'
import { Skeleton } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import DataGridView from './DataGridView';

const DataGridViewTemp = ({
  title,
  rows,
  columns,
  link,
  limited,
  hasExportBtn,
  hasMT,
  children,
  className,
  pageSize,
  pagination,
  page,
  columnVisibilityModel,
  isValidating,
  recordCount,
  offsetLabel,
}) => {
  return (
    <div css={[(limited || hasMT) && tw`mt-10`]}>
      <div css={[tw`flex items-center justify-between`]}>
        <Title className="font-500">{title}</Title>

        {link && (
          <Link
            to={link}
            css={[tw`cursor-pointer text-[#6500E0] hover:underline`]}
          >
            View all
          </Link>
        )}
      </div>

      {isValidating && rows?.length === 0 ? (
        <Skeleton mt={'16px'} height={100} />
      ) : (
        <DataGridView
          rows={rows}
          columns={columns}
          limited={limited}
          hasExportBtn={hasExportBtn}
          className={className}
          pageSize={pageSize}
          pagination={pagination}
          pageId={page}
          recordCount={recordCount}
          columnVisibilityModel={columnVisibilityModel}
          isValidating={isValidating}
          title={title}
          offsetLabel={offsetLabel}
        >
          {children}
        </DataGridView>
      )}
    </div>
  );
};

// Tailwind Styles
const Title = tw.h3`tracking-[-0.02em] text-black lg:(text-[20px])`;

export default DataGridViewTemp;
