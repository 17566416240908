/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Modal } from '@material-ui/core';
import { Widget } from '@uploadcare/react-widget';
import axios from 'axios';
import moment from 'moment';
import NaijaStates from 'naija-state-local-government';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import * as Yup from 'yup';
import { swrfetcher } from '../../utils/swrFetcher';

const AddMerchant = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const edit = query.get('edit');
  const username = query.get('username');

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  /* useState hooks */
  const [files, setFiles] = React.useState();
  const [passport, setPassport] = React.useState();
  const [loading, setLoading] = React.useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isLoading, setisLoading] = React.useState(false);
  const [mail, setMail] = React.useState();
  const [statelga, setStatelga] = React.useState('Oyo');
  const [bank, setBank] = React.useState();
  const [bankCode, setBankCode] = React.useState('');
  const [unVerifiedBvn, setUnVerifiedBvn] = React.useState();
  const [open, setOpen] = React.useState(true);
  const [bvn, setBvn] = React.useState();
  const [accountnumber, setAccountNumber] = React.useState('');
  const [bankAcctName, setBankAcctName] = React.useState('');
  const [reason, setReason] = React.useState('');

  const getUserDetalsUrl =
    userType === 'admin' && edit
      ? `/apis/v1/bizzdesk/admin/checker/getUserDetals?userName=${username}`
      : `/apis/bizzdesk/organization/checker/getUserDetals?userName=${username}`;

  const getBvnUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/checker/getBvnDetails?bvn=${unVerifiedBvn}`
      : `/apis/bizzdesk/organization/checker/getBvnDetails?bvn=${unVerifiedBvn}`;

  const addMerchantUrl =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/merchant/new/addMerchant'
      : 'https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/signup/merchant/new/addMerchant';

  const editMerchantUrl =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/signup/merchant/editSignUp'
      : 'https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/signup/merchant/editSignUp';

  const getbankListUrl =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/signup/bank/bankList'
      : 'https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/bank/bankList';

  const transferNameEnquiryUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/signup/bank/transferNameEnquiry`
      : '/apis/bizzdesk/organization/bank/transferNameEnquiry';
  /* Other hooks */
  let toast = useToast();
  let navigate = useNavigate();

  const { data: userDetails } = useSWR(
    edit ? [getUserDetalsUrl, token, 'GET'] : null,
    ([url, USER_TOKEN, method]) =>
      swrfetcher(url, USER_TOKEN, method).then(res => {
        setValue('firstname', res.firstName);
        setValue('lastname', res.lastName);
        setValue('middlename', res.middlename);
        setBvn(res.bvnNumber);
        setValue('phonenumber', res.phone);
        setValue('email', res.email);
        setValue('dob', moment(res.dateOfBirth).format('YYYY-MM-DD'));
        setValue('gender', res.gender);
        setValue('businessname', res.businessName);
        setValue('cacbusinessname', res.businessName);
        setValue('businessaddress', res.address);
        setValue('businessphone', res.businessPhone);
        setValue('cacnumber', res.cacNumber);
        setValue('bvn', res.bvnNumber);
        setValue('validid', res.validIdNumber);
        setValue('nin', res.ninNumber);
        setValue('idtype', res.validIdType);
        setStatelga(res.state);
        setReason(res.reasonForDenial);

        return res;
      }),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  /* useEffect hook */
  // Fetch bank list
  React.useEffect(() => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getbankListUrl,
    })
      .then(response => {
        setLoading(false);
        setMail(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  }, [getbankListUrl, token]);

  // check and set the modal for bvn
  React.useEffect(() => {
    if (!open && !bvn) {
      setOpen(true);
    }
  }, [bvn, open]);

  /* form and validation functions */
  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    middlename: Yup.string().optional(),
    phonenumber: Yup.string().required('Phone number is required'),
    email: Yup.string().required('Email is required'),
    dob: Yup.string().required('This field is required'),
    gender: Yup.string().required('This field is required'),
    businessname: Yup.string().required('This field is required'),
    cacbusinessname: Yup.string(),
    businessaddress: Yup.string(),
    businessemail: Yup.string(),
    businessphone: Yup.string(),
    cacnumber: Yup?.string(),
    bvn: Yup.string().required('This field is required'),
    validid: Yup?.string(),
    nin: Yup?.string(),
    idtype: Yup?.string(),
    //bank: Yup?.string()?.required('This field is required'),
    // state:Yup?.string()?.required('This field is required'),
    lga: edit
      ? Yup?.string()
      : Yup?.string()?.required('This field is required'),
    accountnumber: edit
      ? Yup?.string()
      : Yup.string().required('This field is required'),
    // passportfile: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, setValue, formState } = useForm(formOptions);
  const { errors } = formState;

  /* functions */
  const validate = (data, event) => {
    event.preventDefault();

    setLoading(true);

    if (edit) {
      axios({
        method: 'put',
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
        url: editMerchantUrl,
        data: {
          bankAccName: '',
          bankAccNumber: '',
          bankName: '',
          bdsUserName: '',
          firstname: data?.firstname,
          lastname: data?.lastname,
          middlename: data?.middlename,
          fullname: `${data?.lastname} ${data?.middlename || ''} ${
            data?.firstname
          }`,
          phonenumber: data?.phonenumber,
          email: data?.email,
          dob: data?.dob,
          gender: '',
          businessname: data?.businessname,
          cacBusinessName: data?.cacbusinessname,
          businessaddress: data?.businessaddress,
          cacnumber: data?.cacnumber,
          bvn: data?.bvn,
          validIdNumber: data?.validid,
          validIdType: data?.idtype,
          nin: data?.nin,
          state: statelga,
          businessEmail: '',
          businessphone: '',
          city: '',
          housenumber: '',
          landmark: '',
          latitude: '',
          lga: '',
          lgacode: '',
          locationUrl: '',
          longitude: '',
          organizationUserName: '',
          passportUrl: passport?.originalUrl || '',
          password: '',
          pin: '',
          settlementPlan: '',
          signatureUrl: '',
          streetname: '',
          timestamp: '',
          usertype: '',
          utilityUrl: files?.originalUrl || '',
        },
      })
        .then(response => {
          toast({
            // title: 'A New Merchant Added!.',
            position: 'top',
            description: 'Merchant saved successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
          navigate('/sign-up?tab=0');
        })
        .catch(err => {
          toast({
            title: 'Failed',
            position: 'top',
            description: err.response.data.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        });
    } else {
      axios({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
        url: addMerchantUrl,
        data: {
          bankAccName: bankAcctName?.beneficiaryName,
          bankAccNumber: data?.accountnumber,
          bankName: bank,
          bdsUserName: '',
          businessEmail: data?.businessemail,
          businessaddress: data?.businessaddress,
          businessname: data?.businessname,
          businessphone: data?.businessphone,
          bvn: data?.bvn,
          cacBusinessName: data?.cacbusinessname,
          cacnumber: data?.cacnumber,
          city: '',
          dob: data?.dob,
          email: data?.email,
          firstname: data?.firstname,
          fullname: `${data?.lastname} ${data?.middlename || ''} ${
            data?.firstname
          }`,
          gender: data?.gender,
          housenumber: '',
          landmark: '',
          lastname: data?.lastname,
          latitude: '',
          lga: data?.lga,
          lgacode: '',
          locationUrl: '',
          longitude: '',
          middlename: data?.middlename,
          nin: data?.nin,
          organizationUserName: '',
          passportUrl: passport?.originalUrl || '',
          password: '',
          phonenumber: data?.phonenumber,
          pin: '',
          settlementPlan: '',
          signatureUrl: '',
          state: statelga,
          streetname: '',
          timestamp: '',
          usertype: '',
          utilityUrl: files?.originalUrl || '',
          validIdNumber: data?.validid,
          validIdType: data?.idtype,
        },
      })
        .then(response => {
          toast({
            title: 'A New Merchant Added!.',
            position: 'top',
            description: 'A New Merchant has been Created Successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
          navigate('/business-merchants');
        })
        .catch(err => {
          console.log('err <<<', err);

          toast({
            title: 'Merchant not added!',
            position: 'top',
            description: err.response.data.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        });
    }
  };

  const handleClose = () => {
    if (bvn) {
      setOpen(false);
    }
  };

  const handleGetBankAcctName = acctNumber => {
    setLoading(true);

    swrfetcher(transferNameEnquiryUrl, token, 'POST', {
      bankCode: bankCode,
      beneficiaryAccountNumber: acctNumber,
    })
      .then(res => {
        setBankAcctName(res);
        setLoading(false);
      })
      .catch(err => {
        const errorData = JSON.parse(err.message);

        if (errorData.status === 400) {
          setBankAcctName({ message: 'Account name not found!' });

          setLoading(false);

          return;
        }

        setLoading(false);
      });
  };

  const handleVeriryBVN = async e => {
    setisLoading(true);

    swrfetcher(getBvnUrl, token, 'GET')
      .then(res => {
        if (res.status === '400') {
          toast({
            title: 'BVN not found!',
            position: 'top',
            description: res.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          setisLoading(false);
          return;
        }

        setBvn(res);
        setValue('firstname', res.firstName);
        setValue('lastname', res.lastName);
        setValue('middlename', res.middleName);
        setValue('dob', moment(res.dob).format('YYYY-MM-DD'));
        setValue('phonenumber', res.phoneNumber);
        setValue('bvn', unVerifiedBvn);

        let gender = res.gender.charAt(0).toUpperCase() + res.gender.slice(1);
        setValue('gender', gender);

        setisLoading(false);
        setOpen(false);
      })
      .catch(err => {
        setisLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      <Box>
        <div>
          <button
            onClick={() => navigate(-1)}
            style={{ fontSize: '14px', fontWeight: '300' }}
            tw="mb-4 hover:underline"
          >
            Back
          </button>
          <Box
            display="flex"
            gap={['3', '0']}
            flexDirection={['column', 'column', 'column', 'row']}
            justifyContent="space-between"
            w="full"
            mt="1rem"
          >
            <HStack float="left" gap="3">
              <Image src="/assets/merchantframe.png" alt="" />
              <VStack alignItems="flex-start">
                <Text fontWeight="600" fontSize="24px">
                  {edit ? `Edit` : `Add`} Merchant
                </Text>
              </VStack>
            </HStack>
          </Box>
        </div>
        {/* form */}
        <Box w="full" pb="8rem" mt="2rem">
          {!userDetails && edit ? (
            <div tw="flex justify-center items-center h-40">
              <span tw="relative flex h-10 w-10">
                <span tw="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#6500E0B3] opacity-75"></span>
                <span tw="relative inline-flex rounded-full h-10 w-10 bg-[#6500E0B3]"></span>
              </span>
            </div>
          ) : (
            <form onSubmit={handleSubmit(validate)}>
              <Box
                alignItems="flex-start"
                w="full"
                display="flex"
                gap="6"
                flexDirection="column"
              >
                <div tw="border rounded-xl w-full p-4">
                  <Text fontSize="16px" fontWeight="500" color="#16192C">
                    Bio Data
                  </Text>
                  <Box
                    display="flex"
                    flexDirection={['column', 'row']}
                    justifyContent="flex-start"
                    alignItems="baseline"
                    w="full"
                    gap={['2', '0']}
                    mt="4"
                  >
                    <VStack w="full" gap="2" alignItems="flex-start">
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        First Name
                      </Text>
                      <Input
                        fontSize="13px"
                        type="text"
                        borderRadius="4px"
                        w={['full', 'full', 'full', '308px']}
                        placeholder=""
                        {...register('firstname')}
                        disabled={true}
                        border={
                          errors.firstname
                            ? '1px solid red'
                            : ' 1px solid #E3E5E8'
                        }
                        fontWeight="300"
                        color="#454D54"
                        mb=".7rem"
                      />
                      <Text color="red" mt=".5rem">
                        {errors.firstname?.message}
                      </Text>
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        Gender
                      </Text>
                      <Select
                        fontSize="13px"
                        w={['full', 'full', 'full', '308px']}
                        borderRadius="4px"
                        placeholder=""
                        {...register('gender')}
                        border={
                          errors.gender ? '1px solid red' : ' 1px solid #E3E5E8'
                        }
                        disabled
                        fontWeight="300"
                        color="#454D54"
                      >
                        <option value="Female">Female</option>
                        <option value="Male">Male</option>
                      </Select>
                      <Text color="red" mt=".5rem">
                        {errors.gender?.message}
                      </Text>
                    </VStack>
                    <VStack w="full" gap="2" alignItems="flex-start">
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        Middle Name
                      </Text>
                      <Input
                        fontSize="13px"
                        borderRadius="4px"
                        placeholder=""
                        type="text"
                        w={['full', 'full', 'full', '308px']}
                        {...register('middlename')}
                        disabled
                        border={
                          errors.middlename
                            ? '1px solid red'
                            : ' 1px solid #E3E5E8'
                        }
                        fontWeight="300"
                        color="#454D54"
                        mb=".7rem"
                      />
                      <Text color="red" mt=".5rem">
                        {errors.middlename?.message}
                      </Text>
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        Date of Birth
                      </Text>
                      <Input
                        fontSize="13px"
                        borderRadius="4px"
                        placeholder=""
                        {...register('dob')}
                        border={
                          errors.dob ? '1px solid red' : ' 1px solid #E3E5E8'
                        }
                        value={moment(bvn?.dob).format('YYYY-MM-DD')}
                        disabled
                        fontWeight="300"
                        type="date"
                        w={['full', 'full', 'full', '308px']}
                        color="#454D54"
                      />
                      <Text color="red" mt=".5rem">
                        {errors.dob?.message}
                      </Text>
                    </VStack>
                    <VStack w="full" gap="2" alignItems="flex-start">
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        Last Name
                      </Text>
                      <Input
                        fontSize="13px"
                        borderRadius="4px"
                        placeholder=""
                        type="text"
                        w={['full', 'full', 'full', '308px']}
                        {...register('lastname')}
                        disabled
                        border={
                          errors.lastname
                            ? '1px solid red'
                            : ' 1px solid #E3E5E8'
                        }
                        fontWeight="300"
                        color="#454D54"
                        mb=".7rem"
                      />
                      <Text color="red" mt=".5rem">
                        {errors.lastname?.message}
                      </Text>
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        Phone Number
                      </Text>
                      <Input
                        fontSize="13px"
                        borderRadius="4px"
                        placeholder=""
                        {...register('phonenumber')}
                        border={
                          errors.phonenumber
                            ? '1px solid red'
                            : ' 1px solid #E3E5E8'
                        }
                        // disabled
                        fontWeight="300"
                        type="text"
                        w={['full', 'full', 'full', '308px']}
                        color="#454D54"
                        mb=".7rem"
                      />
                      <Text color="red" mt=".5rem">
                        {errors.phonenumber?.message}
                      </Text>
                    </VStack>
                  </Box>
                </div>
                <Box
                  display="flex"
                  flexDirection={['column', 'row']}
                  justifyContent="flex-start"
                  alignItems="baseline"
                  w="full"
                  gap={['2', '0']}
                >
                  <VStack w="full" gap="2" alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Email
                    </Text>
                    <Input
                      fontSize="13px"
                      type="email"
                      w={['full', 'full', 'full', '308px']}
                      borderRadius="4px"
                      _focus={{ outline: 'none' }}
                      _active={{ outline: 'none' }}
                      placeholder=""
                      {...register('email')}
                      border={
                        errors.email ? '1px solid red' : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      color="#454D54"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.email?.message}
                    </Text>
                  </VStack>

                  {!edit && (
                    <VStack w="full" gap="2" alignItems="flex-start">
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        State
                      </Text>
                      <Select
                        fontSize="13px"
                        w={['full', 'full', 'full', '308px']}
                        borderRadius="4px"
                        placeholder=""
                        onChange={e => setStatelga(e.target.value)}
                        border={' 1px solid #E3E5E8'}
                        fontWeight="300"
                        color="#454D54"
                      >
                        {Array?.isArray(NaijaStates.states())
                          ? NaijaStates.states()?.map((e, id) => {
                              return (
                                <option
                                  style={{ color: '#333' }}
                                  value={e}
                                  key={id}
                                >
                                  {' '}
                                  {e}
                                </option>
                              );
                            })
                          : null}
                      </Select>
                    </VStack>
                  )}

                  {!edit && (
                    <VStack w="full" gap="2" alignItems="flex-start">
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        Local Gorvernment Area
                      </Text>
                      <Select
                        fontSize="13px"
                        w={['full', 'full', 'full', '308px']}
                        borderRadius="4px"
                        placeholder=""
                        {...register('lga')}
                        border={
                          errors.lga ? '1px solid red' : ' 1px solid #E3E5E8'
                        }
                        fontWeight="300"
                        color="#454D54"
                      >
                        {Array?.isArray(NaijaStates.lgas(statelga).lgas)
                          ? NaijaStates.lgas(statelga).lgas?.map((e, id) => {
                              return (
                                <option
                                  style={{ color: '#333' }}
                                  value={e}
                                  key={id}
                                >
                                  {e}
                                </option>
                              );
                            })
                          : null}
                      </Select>
                      <Text color="red" mt=".5rem">
                        {errors.lga?.message}
                      </Text>
                    </VStack>
                  )}
                </Box>

                <Text fontSize="16px" fontWeight="500" color="#16192C">
                  Business Data
                </Text>
                <Box
                  display="flex"
                  flexDirection={['column', 'row']}
                  justifyContent="space-between"
                  alignItems="center"
                  w="full"
                  gap={['2', '0']}
                >
                  <VStack w="full" gap="2" alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Business Name
                    </Text>
                    <Input
                      fontSize="13px"
                      type="text"
                      borderRadius="4px"
                      w={['full', 'full', 'full', '308px']}
                      placeholder=""
                      {...register('businessname')}
                      border={
                        errors.businessname
                          ? '1px solid red'
                          : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      color="#454D54"
                      mb=".7rem"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.businessname?.message}
                    </Text>
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Email
                    </Text>
                    <Input
                      fontSize="13px"
                      type="email"
                      w={['full', 'full', 'full', '308px']}
                      borderRadius="4px"
                      placeholder=""
                      {...register('businessemail')}
                      border={
                        errors.businessemail
                          ? '1px solid red'
                          : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      color="#454D54"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.businessemail?.message}
                    </Text>
                  </VStack>
                  <VStack w="full" gap="2" alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Business Address
                    </Text>
                    <Input
                      fontSize="13px"
                      borderRadius="4px"
                      placeholder=""
                      type="text"
                      w={['full', 'full', 'full', '308px']}
                      {...register('businessaddress')}
                      border={
                        errors.businessaddress
                          ? '1px solid red'
                          : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      color="#454D54"
                      mb=".7rem"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.businessaddress?.message}
                    </Text>
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      CAC Business Name
                    </Text>
                    <Input
                      fontSize="13px"
                      borderRadius="4px"
                      placeholder=""
                      {...register('cacbusinessname')}
                      border={
                        errors.cacbusinessname
                          ? '1px solid red'
                          : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      type="text"
                      w={['full', 'full', 'full', '308px']}
                      color="#454D54"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.cacbusinessname?.message}
                    </Text>
                  </VStack>
                  <VStack w="full" gap="2" alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Business Phone number
                    </Text>
                    <Input
                      fontSize="13px"
                      borderRadius="4px"
                      placeholder=""
                      {...register('businessphone')}
                      border={
                        errors.businessphone
                          ? '1px solid red'
                          : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      type="text"
                      w={['full', 'full', 'full', '308px']}
                      color="#454D54"
                      mb=".7rem"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.businessphone?.message}
                    </Text>
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      CAC Number
                    </Text>
                    <Input
                      fontSize="13px"
                      borderRadius="4px"
                      placeholder=""
                      {...register('cacnumber')}
                      border={
                        errors.cacnumber
                          ? '1px solid red'
                          : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      type="text"
                      w={['full', 'full', 'full', '308px']}
                      color="#454D54"
                      mb=".7rem"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.cacnumber?.message}
                    </Text>
                  </VStack>
                </Box>
                <Text fontSize="16px" fontWeight="500" color="#16192C">
                  Verification
                </Text>
                <Box
                  display="flex"
                  flexDirection={['column', 'row']}
                  justifyContent="space-between"
                  alignItems={['center', 'flex-start']}
                  w="full"
                  gap={['2', '0']}
                >
                  <VStack w="full" gap="2" alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      BVN
                    </Text>
                    <Input
                      fontSize="13px"
                      type="text"
                      borderRadius="4px"
                      w={['full', 'full', 'full', '308px']}
                      placeholder=""
                      {...register('bvn')}
                      border={
                        errors.bvn ? '1px solid red' : ' 1px solid #E3E5E8'
                      }
                      value={unVerifiedBvn}
                      disabled={edit ? false : true}
                      fontWeight="300"
                      color="#454D54"
                      mb=".7rem"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.bvn?.message}
                    </Text>
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Valid ID Number
                    </Text>
                    <Input
                      fontSize="13px"
                      type="text"
                      w={['full', 'full', 'full', '308px']}
                      borderRadius="4px"
                      placeholder=""
                      {...register('validid')}
                      border={
                        errors.validid ? '1px solid red' : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      color="#454D54"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.validid?.message}
                    </Text>
                  </VStack>
                  <VStack w="full" gap="2" alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      NIN
                    </Text>
                    <Input
                      fontSize="13px"
                      borderRadius="4px"
                      placeholder=""
                      type="text"
                      w={['full', 'full', 'full', '308px']}
                      {...register('nin')}
                      border={
                        errors.nin ? '1px solid red' : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      color="#454D54"
                      mb=".7rem"
                    />
                    <Text color="red" mt=".5rem">
                      {errors.nin?.message}
                    </Text>
                    {!edit && (
                      <>
                        <Text fontSize="13px" fontWeight="300" color="#454D54">
                          Bank
                        </Text>
                        <Select
                          _focus={{ outline: 'none' }}
                          _active={{ outline: 'none' }}
                          // {...register('bank')}
                          border={' 1px solid #E3E5E8'}
                          borderRadius="4px"
                          fontWeight="300"
                          onChange={e => {
                            setBank(e.target.value);
                            mail?.forEach(element => {
                              if (element.bankName === e.target.value) {
                                setBankCode(element.bankCode);
                              }
                            });
                          }}
                          color="#454D54"
                          w={['full', 'full', 'full', '308px']}
                          fontSize="13px"
                          mb=".7rem"
                          placeholder="Select Bank"
                        >
                          {Array?.isArray(mail)
                            ? mail?.map((e, id) => {
                                return (
                                  <option
                                    style={{ color: '#333' }}
                                    value={e?.bankName}
                                    key={id}
                                  >
                                    {e?.bankName}
                                  </option>
                                );
                              })
                            : null}
                        </Select>
                        <Text color="red" mt=".5rem">
                          {/* {errors.bank?.message} */}
                        </Text>
                      </>
                    )}
                  </VStack>
                  <VStack gap="2" w="full" alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Valid ID Type
                    </Text>
                    <Select
                      fontSize="13px"
                      w={['full', 'full', 'full', '308px']}
                      borderRadius="4px"
                      placeholder=""
                      {...register('idtype')}
                      border={
                        errors.idtype ? '1px solid red' : ' 1px solid #E3E5E8'
                      }
                      fontWeight="300"
                      color="#454D54"
                    >
                      <option>Voter's Card</option>
                      <option>Driver's License</option>
                      <option>Intl. Passport</option>
                    </Select>
                    <Text color="red" mt=".5rem">
                      {errors.idtype?.message}
                    </Text>
                    {!edit && (
                      <>
                        <Text fontSize="13px" fontWeight="300" color="#454D54">
                          Account Number
                        </Text>
                        <Input
                          fontSize="13px"
                          type="text"
                          w={['full', 'full', 'full', '308px']}
                          borderRadius="4px"
                          placeholder=""
                          {...register('accountnumber')}
                          max={10}
                          border={
                            errors.accountnumber
                              ? '1px solid red'
                              : ' 1px solid #E3E5E8'
                          }
                          disabled={!bankCode}
                          onChange={e => {
                            if (bankCode && e.target.value.length === 10) {
                              setValue('accountnumber', e.target.value);
                              setAccountNumber(e.target.value);
                              handleGetBankAcctName(e.target.value);
                              return;
                            }

                            if (bankCode && e.target.value.length > 10) {
                              return;
                            }
                            setAccountNumber(e.target.value);
                            setValue('accountnumber', e.target.value);
                          }}
                          fontWeight="300"
                          color="#454D54"
                        />
                        {accountnumber.length === 10 &&
                          !loading &&
                          bankAcctName.beneficiaryName && (
                            <Text
                              color="blackAlpha.600"
                              mt=".2rem"
                              tw="text-sm"
                            >
                              {bankAcctName?.beneficiaryName}
                            </Text>
                          )}
                        {accountnumber.length === 10 &&
                          !loading &&
                          !bankAcctName.beneficiaryName && (
                            <Text color="red" mt=".2rem" tw="text-sm">
                              {bankAcctName?.message}
                            </Text>
                          )}
                        <Text color="red" mt=".5rem">
                          {errors.accountnumber?.message}
                        </Text>
                      </>
                    )}
                  </VStack>
                </Box>

                {edit && (
                  <div>
                    <Text fontSize="16px" fontWeight="500" color="#16192C">
                      Reason for Denial
                    </Text>
                    <Text color="red" mt=".3rem">
                      {reason}
                    </Text>
                  </div>
                )}

                <Text fontSize="16px" fontWeight="500" color="#16192C">
                  Utility File Upload
                </Text>
                <Widget
                  type="file"
                  onChange={info => setFiles(info)}
                  publicKey="d97dc41971f0ed0e74e3"
                  id="file"
                />
                <Text fontSize="16px" fontWeight="500" color="#16192C">
                  Passport Upload
                </Text>

                <Widget
                  type="file"
                  onChange={info => setPassport(info)}
                  publicKey="d97dc41971f0ed0e74e3"
                  id="file"
                />
              </Box>

              <Box w="full">
                <Button
                  isLoading={loading}
                  bg="#6500E0"
                  mt="2rem"
                  w={['full', 'full', 'full', '30%']}
                  color="#fff"
                  align="center"
                  p="1rem"
                  float="right"
                  borderRadius="4px"
                  role="group"
                  cursor="pointer"
                  _hover={{
                    bg: '#5403b8',
                    color: 'white',
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </form>
          )}
        </Box>
      </Box>

      {!edit && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          tw="items-center justify-center flex"
        >
          <Box tw="bg-white w-[400px] rounded-xl p-10 pt-14">
            <button tw="pb-[5px] mb-6" onClick={() => navigate(-1)}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M22.5 12C22.5 11.5858 22.1642 11.25 21.75 11.25H4.06066L8.78033 6.53033C9.07322 6.23744 9.07322 5.76256 8.78033 5.46967C8.48744 5.17678 8.01256 5.17678 7.71967 5.46967L1.71967 11.4697C1.42678 11.7626 1.42678 12.2374 1.71967 12.5303L7.71967 18.5303C8.01256 18.8232 8.48744 18.8232 8.78033 18.5303C9.07322 18.2374 9.07322 17.7626 8.78033 17.4697L4.06066 12.75H21.75C22.1642 12.75 22.5 12.4142 22.5 12Z"
                  fill="black"
                />
              </svg>
            </button>
            <h5 tw="text-lg font-medium text-gray-900 mb-6">
              Enter your BVN to proceed
            </h5>
            <Text fontSize="13px" fontWeight="300" color="#454D54" tw="mb-1">
              BVN
            </Text>
            <Input
              fontSize="13px"
              type="text"
              borderRadius="4px"
              w={['full', 'full', 'full', '308px']}
              placeholder="1234567899"
              fontWeight="300"
              color="#454D54"
              mb=".7rem"
              onChange={e => setUnVerifiedBvn(e.target.value)}
              value={unVerifiedBvn}
            />
            <Button
              isLoading={isLoading}
              bg="#6500E0"
              mt="2rem"
              w={['full', 'full', 'full', '30%']}
              color="#fff"
              align="center"
              p="1rem"
              float="right"
              borderRadius="4px"
              role="group"
              cursor="pointer"
              _hover={{
                bg: '#5403b8',
                color: 'white',
              }}
              onClick={handleVeriryBVN}
            >
              Proceed
            </Button>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default AddMerchant;
