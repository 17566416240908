/** @jsxImportSource @emotion/react */
import { ArrowForwardIcon, SpinnerIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Select,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Widget } from '@uploadcare/react-widget';
import axios from 'axios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import { swrfetcher } from '../../utils/swrFetcher';

const AddMerchant = () => {
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  /* useState hooks */
  // eslint-disable-next-line no-unused-vars
  const [files, setFiles] = React.useState();
  // eslint-disable-next-line no-unused-vars
  const [passport, setPassport] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [bankCode, setBankCode] = React.useState('');
  const [businessName, setBusinessName] = React.useState('');
  const [businessAddress, setBusinessAddress] = React.useState('');
  const [businessEmail, setBusinessEmail] = React.useState('');
  const [businessPhone, setBusinessPhone] = React.useState('');
  const [cacBusinessName, setCacBusinessName] = React.useState('');
  const [cacNumber, setCacNumber] = React.useState('');
  const [fdBvn, setFdBvn] = React.useState('');
  const [fdFirstName, setFdFirstName] = React.useState('');
  const [fdLastName, setFdLastName] = React.useState('');
  const [fdMiddleName, setFdMiddleName] = React.useState('');
  const [fdEmail, setFdEmail] = React.useState('');
  const [fdPhone, setFdPhone] = React.useState('');
  const [fdAddress, setFdAddress] = React.useState('');
  const [fdValidId, setFdValidId] = React.useState('');
  const [fdValidIdType, setFdValidIdType] = React.useState('');
  const [sdBvn, setSdBvn] = React.useState('');
  const [sdFirstName, setSdFirstName] = React.useState('');
  const [sdLastName, setSdLastName] = React.useState('');
  const [sdMiddleName, setSdMiddleName] = React.useState('');
  const [sdEmail, setSdEmail] = React.useState('');
  const [sdPhone, setSdPhone] = React.useState('');
  const [sdAddress, setSdAddress] = React.useState('');
  const [sdValidId, setSdValidId] = React.useState('');
  const [sdValidIdType, setSdValidIdType] = React.useState('');
  const [acctNumber, setAcctNumber] = React.useState('');
  const [bank, setBank] = React.useState('');
  const [gettingFd, setGettingFd] = React.useState(false);
  const [mail, setMail] = React.useState([]);
  const [gettingSd, setGettingSd] = React.useState(false);
  const [gettingFdError, setGettingFdError] = React.useState(false);
  const [bankAcctName, setBankAcctName] = React.useState('');
  const [gettingSdError, setGettingSdError] = React.useState(false);

  const getfdBvnUrl = `/apis/v1/bizzdesk/admin/checker/getBvnDetails?bvn=${fdBvn}`;

  const getsdBvnUrl = `/apis/v1/bizzdesk/admin/checker/getBvnDetails?bvn=${sdBvn}`;

  const addOrganizationUrl =
    'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/user/new/addUser';

  const getbankListUrl =
    'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/signup/bank/bankList';

  const transferNameEnquiryUrl = `/apis/v1/bizzdesk/signup/bank/transferNameEnquiry`;

  React.useEffect(() => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getbankListUrl,
    })
      .then(response => {
        setLoading(false);
        setMail(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  }, [getbankListUrl, token]);

  const handleGetBankAcctName = acctNumber => {
    setLoading(true);

    swrfetcher(transferNameEnquiryUrl, token, 'POST', {
      bankCode: bankCode,
      beneficiaryAccountNumber: acctNumber,
    })
      .then(res => {
        setBankAcctName(res);
        setLoading(false);
      })
      .catch(err => {
        const errorData = JSON.parse(err.message);

        if (errorData.status === 400) {
          setBankAcctName({ message: 'Account name not found!' });

          setLoading(false);

          return;
        }

        setLoading(false);
      });
  };

  /* Other hooks */
  let toast = useToast();
  let navigate = useNavigate();

  /* functions */
  const handleSubmit = event => {
    event.preventDefault();

    // validation
    if (
      !businessName ||
      !businessAddress ||
      !businessEmail ||
      !businessPhone ||
      !cacBusinessName ||
      !cacNumber ||
      !fdBvn ||
      !fdFirstName ||
      !fdLastName ||
      !fdMiddleName ||
      !fdEmail ||
      !fdPhone ||
      !fdAddress ||
      !fdValidId ||
      !fdValidIdType ||
      !bankAcctName ||
      !acctNumber ||
      !bank
    ) {
      toast({
        title: 'Fill all required fields',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setLoading(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: addOrganizationUrl,
      data: {
        bankAccName: bankAcctName?.beneficiaryName,
        bankAccNumber: acctNumber,
        bankName: bank,
        bdsUserName: '',
        businessEmail: businessEmail,
        businessaddress: businessAddress,
        businessname: businessName,
        businessphone: businessPhone,
        bvn: '',
        cacBusinessName: cacBusinessName,
        cacnumber: cacNumber,
        city: '',
        dob: '',
        email: businessEmail,
        firstDirector: {
          bvn: fdBvn,
          dob: '',
          firstName: fdFirstName,
          gender: '',
          lastName: fdLastName,
          middleName: fdMiddleName,
          phoneNumber: fdPhone,
          directorEmail: fdEmail,
          validId: fdValidIdType,
          validIdNum: fdValidId,
          address: fdAddress,
          validIdUrl: '',
        },
        firstname: '',
        fullname: '',
        gender: '',
        housenumber: '',
        landmark: '',
        lastname: '',
        latitude: '',
        lga: '',
        lgacode: '',
        locationUrl: '',
        longitude: '',
        middlename: '',
        nin: '',
        organizationUserName: '',
        passportUrl: '',
        password: '',
        phonenumber: businessPhone,
        pin: '',
        secondDirector: {
          bvn: sdBvn,
          dob: '',
          firstName: sdFirstName,
          gender: '',
          lastName: sdLastName,
          middleName: sdMiddleName,
          phoneNumber: sdPhone,
          directorEmail: sdEmail,
          validId: sdValidIdType,
          validIdNum: sdValidId,
          address: sdAddress,
          validIdUrl: '',
        },
        settlementPlan: '',
        signatureUrl: '',
        state: '',
        streetname: '',
        timestamp: '',
        usertype: 'organization',
        utilityUrl: '',
        validIdNumber: '',
        validIdType: '',
      },
    })
      .then(response => {
        toast({
          // title: 'A New Merchant Added!.',
          position: 'top',
          description: 'Organization added successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        navigate('/organizations');
      })
      .catch(err => {
        toast({
          title: 'Organization not added!',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  const handleGetFDBVN = async e => {
    setGettingFd(true);
    setGettingFdError('');

    swrfetcher(getfdBvnUrl, token, 'GET')
      .then(res => {
        if (res.status === '400') {
          toast({
            title: 'BVN not found!',
            position: 'top',
            description: res.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          setGettingFdError('BVN not found');
          setGettingFd(false);
          return;
        }

        setFdFirstName(res.firstName);
        setFdLastName(res.lastName);
        setFdMiddleName(res.middleName);
        setFdPhone(res.phoneNumber);

        setGettingFd(false);
      })
      .catch(err => {
        setGettingFd(false);
        console.log(err);
      });
  };

  const handleGetSDBVN = async e => {
    setGettingSd(true);
    setGettingSdError();

    swrfetcher(getsdBvnUrl, token, 'GET')
      .then(res => {
        if (res.status === '400') {
          toast({
            title: 'BVN not found!',
            position: 'top',
            description: res.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          setGettingSdError('BVN not found');
          setGettingSd(false);
          return;
        }

        setSdFirstName(res.firstName);
        setSdLastName(res.lastName);
        setSdMiddleName(res.middleName);
        setSdPhone(res.phoneNumber);

        setGettingSd(false);
      })
      .catch(err => {
        setGettingSd(false);
        console.log(err);
      });
  };

  return (
    <>
      <Box>
        <button
          onClick={() => navigate(-1)}
          style={{ fontSize: '14px', fontWeight: '300' }}
          tw="mb-4 hover:underline"
        >
          Back
        </button>
        <Box
          display="flex"
          gap={['3', '0']}
          flexDirection={['column', 'column', 'column', 'row']}
          justifyContent="space-between"
          w="full"
          mt="1rem"
        >
          <HStack float="left" gap="3">
            <Image src="/assets/merchantframe.png" alt="" />
            <VStack alignItems="flex-start">
              <Text fontWeight="600" fontSize="24px">
                Add Organization
              </Text>
            </VStack>
          </HStack>
        </Box>
        {/* form */}
        <Box w="full" pb="8rem" mt="2rem">
          <form>
            <Box
              alignItems="flex-start"
              w="full"
              display="flex"
              gap="6"
              flexDirection="column"
            >
              <Text fontSize="16px" fontWeight="500" color="#16192C">
                Business Data
              </Text>

              <div tw="grid grid-cols-3 w-full gap-4">
                <InputWWidget
                  title="Business Name"
                  state={businessName}
                  setState={setBusinessName}
                />
                <InputWWidget
                  title="Business Address"
                  state={businessAddress}
                  setState={setBusinessAddress}
                />
                <InputWWidget
                  title="Email"
                  state={businessEmail}
                  setState={setBusinessEmail}
                  type="email"
                />
                <InputWWidget
                  title="Business Phone number"
                  state={businessPhone}
                  setState={setBusinessPhone}
                  type="tel"
                />
                <InputWWidget
                  title="CAC Business Name"
                  state={cacBusinessName}
                  setState={setCacBusinessName}
                />
                <InputWWidget
                  title="CAC Number"
                  state={cacNumber}
                  setState={setCacNumber}
                  type="number"
                />
                <InputWWidget
                  title="Bank"
                  state={bank}
                  setState={setBank}
                  options={Array?.isArray(mail) ? mail : []}
                  hasDivision
                  mail={mail}
                  setBankCode={setBankCode}
                />
                <div>
                  <InputWWidget
                    title="Account Number"
                    state={acctNumber}
                    setState={setAcctNumber}
                    type="number"
                    disabled={!bankCode}
                    onChange={e => {
                      if (bankCode && e.target.value.length === 10) {
                        setAcctNumber(e.target.value);
                        handleGetBankAcctName(e.target.value);
                        return;
                      }

                      if (bankCode && e.target.value.length > 10) {
                        return;
                      }
                      setAcctNumber(e.target.value);
                    }}
                  />
                  {acctNumber.length === 10 &&
                    !loading &&
                    bankAcctName.beneficiaryName && (
                      <Text color="blackAlpha.600" mt=".2rem" tw="text-sm">
                        {bankAcctName?.beneficiaryName}
                      </Text>
                    )}
                  {acctNumber.length === 10 &&
                    !loading &&
                    !bankAcctName.beneficiaryName && (
                      <Text color="red" mt=".2rem" tw="text-sm">
                        {bankAcctName?.message}
                      </Text>
                    )}
                </div>
              </div>

              <Text fontSize="16px" fontWeight="500" color="#16192C">
                First Director
              </Text>

              <div tw="grid w-full gap-4">
                <div tw="grid grid-cols-3 gap-4 items-end">
                  <div tw="flex ">
                    <InputWWidget
                      title="BVN"
                      state={fdBvn}
                      setState={setFdBvn}
                      onChangeCheck={() => {
                        handleGetFDBVN();
                      }}
                    />
                  </div>
                  {gettingFd ? (
                    <SpinnerIcon tw="animate-spin" />
                  ) : (
                    <span tw="text-red-500">{gettingFdError}</span>
                  )}
                </div>
                <div tw="grid grid-cols-3 w-full gap-4 border rounded-xl p-4">
                  <InputWWidget
                    title="First Name"
                    state={fdFirstName}
                    setState={setFdFirstName}
                    disabled
                  />
                  <InputWWidget
                    title="Middle Name"
                    state={fdMiddleName}
                    setState={setFdMiddleName}
                    disabled
                  />
                  <InputWWidget
                    title="Last Name"
                    state={fdLastName}
                    setState={setFdLastName}
                    disabled
                  />
                  <InputWWidget
                    title="Phone Number"
                    state={fdPhone}
                    setState={setFdPhone}
                    disabled
                    type="tel"
                  />
                </div>
                <div tw="grid grid-cols-3 w-full gap-4">
                  <InputWWidget
                    title="Residential Address"
                    state={fdAddress}
                    setState={setFdAddress}
                  />
                  <InputWWidget
                    title="Email"
                    state={fdEmail}
                    setState={setFdEmail}
                    type="email"
                  />
                  <InputWWidget
                    title="Valid ID Type"
                    options={[
                      "Voter's Card",
                      "Driver's License",
                      'Intl. Passport',
                    ]}
                    state={fdValidIdType}
                    setState={setFdValidIdType}
                  />
                  <InputWWidget
                    title="Valid ID Number"
                    state={fdValidId}
                    setState={setFdValidId}
                    type="number"
                  />
                </div>
              </div>

              <Text fontSize="16px" fontWeight="500" color="#16192C">
                Second Director
              </Text>

              <div tw="grid w-full gap-4">
                <div tw="grid grid-cols-3 gap-4 items-end">
                  <InputWWidget
                    title="BVN"
                    state={sdBvn}
                    setState={setSdBvn}
                    onChangeCheck={() => {
                      handleGetSDBVN();
                    }}
                  />
                  {gettingSd ? (
                    <SpinnerIcon tw="animate-spin" />
                  ) : (
                    <span tw="text-red-500">{gettingSdError}</span>
                  )}
                </div>
                <div tw="grid grid-cols-3 w-full gap-4 border rounded-xl p-4">
                  <InputWWidget
                    title="First Name"
                    state={sdFirstName}
                    setState={setSdFirstName}
                    disabled
                  />
                  <InputWWidget
                    title="Middle Name"
                    state={sdMiddleName}
                    setState={setSdMiddleName}
                    disabled
                  />
                  <InputWWidget
                    title="Last Name"
                    state={sdLastName}
                    setState={setSdLastName}
                    disabled
                  />
                  <InputWWidget
                    title="Phone Number"
                    state={sdPhone}
                    setState={setSdPhone}
                    type="tel"
                    disabled
                  />
                </div>
                <div tw="grid grid-cols-3 w-full gap-4">
                  <InputWWidget
                    title="Residential Address"
                    state={sdAddress}
                    setState={setSdAddress}
                  />
                  <InputWWidget
                    title="Email"
                    state={sdEmail}
                    setState={setSdEmail}
                    type="email"
                  />
                  <InputWWidget
                    title="Valid ID Type"
                    options={[
                      "Voter's Card",
                      "Driver's License",
                      'Intl. Passport',
                    ]}
                    state={sdValidIdType}
                    setState={setSdValidIdType}
                  />
                  <InputWWidget
                    title="Valid ID Number"
                    state={sdValidId}
                    setState={setSdValidId}
                    type="number"
                  />
                </div>
              </div>

              <Text fontSize="16px" fontWeight="500" color="#16192C">
                Utility File Upload
              </Text>
              <Widget
                type="file"
                onChange={info => setFiles(info)}
                publicKey="d97dc41971f0ed0e74e3"
                id="file"
              />
              <Text fontSize="16px" fontWeight="500" color="#16192C">
                Passport Upload
              </Text>

              <Widget
                type="file"
                onChange={info => setPassport(info)}
                publicKey="d97dc41971f0ed0e74e3"
                id="file"
              />
            </Box>

            <Box w="full">
              <Button
                isLoading={loading}
                bg="#6500E0"
                mt="2rem"
                w={['full', 'full', 'full', '30%']}
                color="#fff"
                align="center"
                p="1rem"
                loadingText="Loading"
                float="right"
                borderRadius="4px"
                role="group"
                cursor="pointer"
                _hover={{
                  bg: '#5403b8',
                  color: 'white',
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

const InputWWidget = ({
  title,
  state,
  setState,
  options,
  disabled,
  onChangeCheck,
  type,
  hasDivision,
  onChange,
  mail,
  setBankCode,
}) => {
  const handleOnChange = e => {
    if (onChange) {
      onChange(e);
    } else {
      setState(e.target.value);
    }
  };

  return (
    <VStack w="full" gap="2" alignItems="flex-start">
      <Text fontSize="13px" fontWeight="300" color="#454D54">
        {title}
      </Text>
      {options ? (
        <Select
          fontSize="13px"
          // w={['full', 'full', 'full', '308px']}
          borderRadius="4px"
          placeholder=""
          border={'1px solid #E2E8F0'}
          fontWeight="300"
          color="#454D54"
          value={state}
          tw="w-full"
          onChange={e => {
            if (hasDivision) {
              setState(e.target.value);
              mail?.forEach(element => {
                if (element.bankName === e.target.value) {
                  setBankCode(element.bankCode);
                }
              });
            } else {
              setState(e.target.value);
            }
          }}
        >
          {hasDivision
            ? options.map((e, id) => (
                <option value={e?.bankName} key={id}>
                  {e?.bankName}
                </option>
              ))
            : options.map(item => <option>{item}</option>)}
        </Select>
      ) : (
        <div tw="flex w-full">
          <Input
            disabled={disabled}
            fontSize="13px"
            borderRadius="4px"
            // w={['full', 'full', 'full', '308px']}
            tw="w-full"
            placeholder=""
            border={'1px solid #E2E8F0'}
            fontWeight="300"
            color="#454D54"
            value={state}
            type={type || 'text'}
            onChange={handleOnChange}
          />
          {onChangeCheck && (
            <button type="button" tw="bg-gray-200 px-2" onClick={onChangeCheck}>
              <ArrowForwardIcon />
            </button>
          )}
        </div>
      )}
    </VStack>
  );
};

export default AddMerchant;
