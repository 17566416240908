/** @jsxImportSource @emotion/react */
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import useSWR from 'swr';
import numberFormatter from '../../utils/numberFormatter';
import DataGridViewTemp from '../Common/DataGridViewTemp';
import TransactionCard from '../Common/TransactionCard';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';
import DatRangePickerAndOthers from '../Common/DataGridFunctionalities/DatRangePickerAndOthers';
import FilterBox from '../Common/DataGridFunctionalities/FilterBox';
import SearchBar from '../Common/DataGridFunctionalities/SearchBar';
import moment from 'moment';

const POSTransactions = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const [searchValue, setSearchValue] = React.useState('');
  const [status, setStatus] = React.useState('');
  // const [date, setDate] = React.useState(['', '']);
  const [day, setDay] = React.useState('Today');
  const [date, setDate] = React.useState([moment().format('yyyy-MM-DD'), null]);

  React.useEffect(() => {
    if (day === 'Today') {
      setDate([moment().startOf('day').format('yyyy-MM-DD'), null]);
    } else if (day === 'Yesterday') {
      setDate([
        moment().subtract(1, 'days').startOf('day').format('yyyy-MM-DD'),
        null,
      ]);
    } else if (day === 'This Week') {
      setDate([moment().startOf('week').format('yyyy-MM-DD'), null]);
    } else if (day === 'This Month') {
      setDate([moment().startOf('month').format('yyyy-MM-DD'), null]);
    } else if (day === 'Last Month') {
      setDate([
        moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      ]);
    } else {
      setDate([moment().startOf('year').format('YYYY-MM-DD'), null]);
    }
  }, [day]);

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/merchant/analytics/getMerchantTransactionRecord?limit=30&offset=${
          offset || 0
        }&transMode=Bizzdesk%20income${
          !searchValue ? '' : `&searchParameter=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }${!status ? '' : `&status=${status}`}`
      : `/apis/bizzdesk/organization/transaction/getMerchantTransactionRecord?limit=30&offset=${
          offset || 0
        }&transMode=Bizzdesk%20income${
          !searchValue ? '' : `&searchParameter=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }${!status ? '' : `&status=${status}`}`;

  const countUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/merchant/analytics/getMerchantTransactionRecord/count?limit=30&offset=${
          offset || 0
        }&transMode=Bizzdesk%20income${
          !searchValue ? '' : `&searchParameter=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }${!status ? '' : `&status=${status}`}`
      : `/apis/bizzdesk/organization/transaction/getMerchantTransactionRecord/count?limit=30&offset=${
          offset || 0
        }&transMode=Bizzdesk%20income${
          !searchValue ? '' : `&searchParameter=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }${!status ? '' : `&status=${status}`}`;

  const analyicUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/transaction/Income/getMetrics?startDate=${
          date[0]
        }${!date[1] ? '' : `&endDate=${date[1]}`}`
      : `/apis/bizzdesk/organization/transaction/Income/getMetrics?startDate=${
          date[0]
        }${!date[1] ? '' : `&endDate=${date[1]}`}`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: analyticData } = useSWR(
    [analyicUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: countValue } = useSWR(
    [countUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  /* A data for the transactionStatsData component. */
  const transactionStatsData = [
    {
      amount: (
        <CurrencyFormat
          value={analyticData?.totalSuccessTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total successful transactions',
      value: numberFormatter(analyticData?.totalSuccessTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={analyticData?.totalFailedTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total failed transactions',
      value: numberFormatter(analyticData?.totalFailedTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={analyticData?.totalPendingTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total Pending transactions',
      value: numberFormatter(analyticData?.totalPendingTransactionsCount),
    },
  ];

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'Transaction Type',
    },
    {
      headerName: 'Merchant Name',
    },
    {
      headerName: 'Organization Name',
    },
    {
      headerName: 'Role',
    },
    {
      headerName: 'Wallet ID',
    },
    {
      headerName: 'Wallet Acct. No.',
    },
    {
      headerName: 'Wallet Bank Name',
    },
    {
      headerName: 'Transaction Ref.',
    },
    {
      headerName: 'Channel',
    },
    {
      headerName: 'Receivable Amount',
    },
    {
      headerName: 'Total Charge',
    },
    {
      headerName: 'Paysure Charge',
    },
    {
      headerName: 'Organization Charge',
    },
    {
      headerName: 'Status Code',
    },
    {
      headerName: 'Status',
    },
    {
      headerName: 'Date/Time',
    },
    {
      headerName: 'Action',
    },
  ];

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: Number(offset || 0) + index + 1,
          type: item?.transtype,
          merchantName: item?.fullName,
          organizationName: item?.organizationName,
          role: item?.role,
          walletId: item?.walletid,
          walletAccountNo: item?.walletAcctNo,
          walletBankName: item?.walletBankName,
          transactionRef: item?.transactionRef,
          channel: item?.channel,
          receivableAmountN: item?.receivableAmount,
          totalChargeN: item?.totalCharge,
          bizzDeskChargeN: item?.bizzdeskCharges,
          organizationChargeN: item?.organizationCharge,
          statusCode: item?.response,
          status: item?.status,
          dateTime: item?.date,
          action: {
            link: `/transactions/income/${item?.transactionRef}`,
          },
        };
      });
    }
  }

  return (
    <>
      <div tw="grid grid-cols-2 gap-3 md:grid-cols-3 xl:( gap-5) mt-5">
        {transactionStatsData.map(({ amount, value, link, title }, index) => {
          return (
            <TransactionCard
              key={index}
              title={title}
              amount={amount}
              value={value}
              isValidating={analyticData}
              link={link}
              hasFilter={index === 0 && day}
              setFilter={index === 0 && setDay}
            />
          );
        })}
      </div>

      <DataGridViewTemp
        link="/transactions/transactions_list/income"
        hasMT
        title={userType === 'admin' ? 'Paysure Income' : 'Organization Income'}
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
        hasExportBtn={tableData}
        pagination
        recordCount={countValue || 0}
        page={offset / 30 + 1}
      >
        <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
          <SearchBar value={searchValue} setSearchValue={setSearchValue} />
          <FilterBox
            dropdownData={['All', 'Completed', 'Failed']}
            value={status}
            setFilterValue={setStatus}
          />
          <DatRangePickerAndOthers date={date} setDate={setDate} />
        </div>
      </DataGridViewTemp>
    </>
  );
};

export default POSTransactions;
