import axios from 'axios';

export async function swrfetcher(url, USER_TOKEN, method, data) {
  if (method === 'GET') {
    // fetch data from server
    const response = await axios
      .get(`${process.env.REACT_APP_BASE_URL}${url}`, {
        headers: {
          Authorization: `Bearer ${USER_TOKEN}`,
        },
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (err.response) {
          console.log({ data: err.response.data, status: err.response.status });

          if (
            err.response.status === 401 ||
            err.response.data.message === 'Access is denied'
          ) {
            localStorage.removeItem('token');
            localStorage.removeItem('userType');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userType');
            window.location = '/';
          }

          throw new Error({
            data: err.response.data,
            status: err.response.status,
          });

          // return {
          //   data: err.response.data,
          //   status: err.response.status,
          // };
        } else {
          console.log('Error >>>>>', err);
          throw new Error(err);
        }
      });
    return response;
  } else {
    // post data to server
    const response = await axios
      .post(`${process.env.REACT_APP_BASE_URL}${url}`, data, {
        headers: {
          Authorization: `Bearer ${USER_TOKEN}`,
        },
      })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        if (err.response) {
          console.log({ data: err.response.data, status: err.response.status });

          if (
            err.response.status === 401 ||
            err.response.data.message === 'Access is denied'
          ) {
            localStorage.removeItem('token');
            localStorage.removeItem('userType');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userType');
            window.location = '/';
          }

          throw new Error(
            JSON.stringify({
              data: err.response.data,
              status: err.response.status,
            })
          );
        } else {
          console.log(err);
          throw new Error(err);
        }
      });
    return response;
  }
}
