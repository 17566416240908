/** @jsxImportSource @emotion/react */
import { Box, HStack, Skeleton, SkeletonText, Text } from '@chakra-ui/react';
import React from 'react';
import img1 from '../Home//Group 21.png';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

const MainCard = ({
  title,
  value,
  activeValue,
  inActiveValue,
  loading,
  isPlain,
}) => {
  return (
    <Box
      bg="#F3F7FC"
      borderRadius="8px"
      w={['100%', '359.67px']}
      h={['100%', '153px']}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      gap="3"
      p=".75rem"
    >
      <Box display="flex" w="full" justifyContent="space-between">
        <Box float="left">
          <SkeletonText isLoaded={loading}>
            <Text tw="text-[#425D8A] text-sm xl:text-base">{title}</Text>
          </SkeletonText>
          <Skeleton isLoaded={loading}>
            <Text fontWeight="600" tw="text-xl md:text-3xl 2xl:text-[40px]">
              {value}
              &nbsp;
            </Text>
          </Skeleton>
        </Box>
        {!isPlain && (
          <img
            float="right"
            src={img1}
            alt=""
            tw="w-[30px] h-[30px] lg:w-[40px] lg:h-[40px] 2xl:w-[50px] 2xl:h-[50px]"
          />
        )}
      </Box>
      {!isPlain && (
        <Box display="flex" gap="3">
          <HStack>
            <Skeleton isLoaded={loading}>
              <Text tw="text-lg md:text-xl 2xl:text-2xl" fontWeight="600">
                {activeValue}
                &nbsp;
                <Text
                  as="span"
                  color="#136F63"
                  fontWeight="400"
                  tw="text-xs xl:text-sm"
                >
                  Active
                </Text>
              </Text>
            </Skeleton>
          </HStack>
          <HStack>
            <Skeleton isLoaded={loading}>
              <Text tw="text-lg md:text-xl 2xl:text-2xl" fontWeight="600">
                {inActiveValue}
                &nbsp;
                <Text
                  as="span"
                  color="#ED6A5A"
                  fontWeight="400"
                  tw="text-xs xl:text-sm"
                >
                  Inactive
                </Text>
              </Text>
            </Skeleton>
          </HStack>
        </Box>
      )}
    </Box>
  );
};

export default MainCard;
