import { InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Select } from '@chakra-ui/select';
import PropTypes from 'prop-types';
import React from 'react';
import tw from 'twin.macro';

const FilterBox = ({
  dropdownData = [],
  label,
  value,
  setFilterValue,
  hasNoLabel,
  hasNoLeftElement,
}) => {
  // const router = useRouter()

  // UseState hook
  const [selectedDrop, setSelectedDrop] = React.useState('');

  // select dropdown value that has same value as statusValue
  React.useEffect(() => {
    const selectedDropdown = dropdownData.find(item => {
      if (!value) {
        return dropdownData[0];
      } else {
        return item === value;
      }
    });

    if (selectedDropdown) {
      setSelectedDrop(selectedDropdown);
    }
  }, [dropdownData, value]);

  // functions
  const handleDropdownSelected = event => {
    setFilterValue(event.target.value === 'All' ? '' : event.target.value);

    // label === 'Status' &&
    //   router.push({
    //     pathname: router.pathname,
    //     query: {
    //       ...router.query,
    //       status: event.target.value === 'All' ? '' : event.target.value,
    //     },
    //   })
  };

  return (
    <InputGroup width="auto">
      {!hasNoLeftElement && (
        <InputLeftElement
          pl={4}
          pointerEvents="none"
          children={<Span>{!hasNoLabel && `${label || 'Showing'}:`}</Span>}
        />
      )}
      <Select
        // placeholder="Select option"
        borderColor="#ccc"
        ml={hasNoLeftElement ? 0 : '50px'}
        outline="none !important"
        value={selectedDrop}
        onChange={handleDropdownSelected}
      >
        {dropdownData.map(option => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </Select>
    </InputGroup>
  );
};

// Proptype
FilterBox.prototype = {
  dropdownData: PropTypes.array,
};

// Tailwind Styles
const Span = tw.span`text-[13px] text-[#10101266]`;

export default FilterBox;
