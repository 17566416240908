import {
  Box,
  HStack,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import AddPlan from './AddPlan';

const TableContent = [
  {
    id: '1',
    name: 'Paysure Basic',
    chargetype: 'Percentage',
    chargerate: '0.4%',
  },
  {
    id: '2',
    name: 'Paysure Pro',
    chargetype: 'Percentage',
    chargerate: '0.4%',
  },
  {
    id: '3',
    name: 'Taraba',
    chargetype: 'Percentage',
    chargerate: '5%',
  },
];

const TableUI = ({ id, name, chargetype, chargerate }) => {
  return (
    <Tr color="#525252">
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {name}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {chargetype}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {chargerate}
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
        {' '}
        Edit
      </Td>
      <Td color="#ED6A5A" cursor="pointer" fontSize="14px" fontWeight="400">
        {' '}
        Delete
      </Td>
    </Tr>
  );
};

const Pos = () => {
  return (
    <Box display="flex" flexDirection={['column', 'row']} mt="1rem" w="full">
      <Box
        w={['full', '30%']}
        display="flex"
        flexDirection="column"
        borderRight="1px solid #E4ECF7"
        h={['100%', '100vh']}
      >
        <Text float="left" fontWeight="700" fontSize="32px">
          Settings
        </Text>
        <Box display="flex" flexDirection="column" gap="3" mt="3rem">
          <NavLink to="/settings/merchant-band">
            <HStack>
              <Image src="assets/home.png" alt="" />
              <Text
                _active={{ color: '#6500E0' }}
                _hover={{ color: '#6500E0' }}
                fontWeight="500"
                fontSize="16px"
              >
                Merchant Band
              </Text>
            </HStack>
          </NavLink>
          <NavLink style={{ marginTop: '1rem' }} to="/settings">
            <HStack>
              <Image src="assets/home.png" alt="" />
              <Text
                _active={{ color: '#6500E0' }}
                _hover={{ color: '#6500E0' }}
                fontWeight="500"
                fontSize="16px"
              >
                Charge Plan
              </Text>
            </HStack>
          </NavLink>
        </Box>
      </Box>
      <Box w={['full', '70%']} h="100%" p="2rem">
        <Link to="/settings">
          <Text color="#454D54" fontSize="13px" fontWeight="400">
            Back
          </Text>
        </Link>
        <Box>
          <Box
            display="flex"
            gap="6"
            justifyContent="space-between"
            w="full"
            flexDirection={['column', 'row']}
            mt="1rem"
          >
            <VStack alignItems="flex-start">
              <Text
                float="left"
                fontSize="20px"
                color="#16192C"
                fontWeight="500"
              >
                POS Withdrawal
              </Text>
              <Text
                float="left"
                fontSize="14px"
                color="#425D8A"
                fontWeight="400"
              >
                Create and manage charge plan
              </Text>
            </VStack>
            <Box float="right">
              <AddPlan />
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap="6" flexDirection="column" mt="2.5rem">
          <TableContainer bg="#F3F7FC" mt="1.5rem">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    s/n
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    Name
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    Charge Type
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    Charge Rate
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    textTransform="uppercase"
                  >
                    Actions
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    textTransform="uppercase"
                  ></Th>
                </Tr>
              </Thead>
              <Tbody>
                {TableContent.map(data => (
                  <TableUI
                    key={data.id}
                    id={data.id}
                    name={data.name}
                    chargetype={data.chargetype}
                    chargerate={data.chargerate}
                  />
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Pos;
