/** @jsxImportSource @emotion/react */
import {
  Box,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Box as MUIBox } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import Sidebar from '../component/Common/Sidebar';
import TabPanel from '../component/Common/TabPanel';
import Topnav from '../component/Common/Topnav';
import CheckoutOverview from '../component/checkout';
import CheckoutSignups from '../component/checkout/CheckoutSignups.jsx';
import CheckoutWallet from '../component/checkout/CheckoutWallet.jsx';
import CheckoutTransactions from '../component/checkout/CheckoutTransactions.jsx';

const OrganizationPage = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [tabValue, setTabValue] = React.useState(0);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTabValue(parseInt(tab));
    }
  }, []);

  const Tab = ({ label, index }) => {
    return (
      <button
        css={[
          tw`text-black text-sm 2xl:text-base py-1.5 md:py-2.5 2xl:py-3.5 px-2 xl:px-4 tracking-[-0.025em] font-medium`,
          index === tabValue &&
            tw`text-white bg-[#6500E0] rounded-lg xl:rounded-[12px]`,
        ]}
        onClick={() => {
          navigate({
            pathname: '/checkout',
            search: `?tab=${index}`,
          });

          setTabValue(index);
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* mobilenav */}
      <Topnav onOpen={onOpen} />

      <Box ml={{ base: 0, md: 60 }} p="4">
        <Text flat="left" fontWeight="700" fontSize="32px">
          Checkout panel
        </Text>

        <MUIBox sx={{ width: '100%', marginTop: '24px' }}>
          <MUIBox
            sx={{
              paddingBottom: {
                xs: '20px',
                lg: '40px',
              },
            }}
          >
            <div tw="space-x-2 md:space-x-3 xl:space-x-4">
              <Tab label="Checkout Merchants" index={0} />
              <Tab label="Signups" index={1} />
              <Tab label={'Wallet'} index={2} />
              <Tab label={'Transactions'} index={3} />
            </div>
          </MUIBox>
          <TabPanel tabvalue={tabValue} index={0}>
            <CheckoutOverview />
          </TabPanel>
          <TabPanel tabvalue={tabValue} index={1}>
            <CheckoutSignups />
          </TabPanel>
          <TabPanel tabvalue={tabValue} index={2}>
            <CheckoutWallet />
          </TabPanel>
          <TabPanel tabvalue={tabValue} index={3}>
            <CheckoutTransactions />
          </TabPanel>
        </MUIBox>
      </Box>
    </Box>
  );
};

export default OrganizationPage;
