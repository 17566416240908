/** @jsxImportSource @emotion/react */
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import { swrfetcher } from '../../../utils/swrFetcher';
import DataGridViewTemp from '../../Common/DataGridViewTemp';
import TransactionCard from '../../Common/TransactionCard';

const Overview = () => {
  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const url =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/signup/merchantSignups/metrics'
      : '/apis/bizzdesk/organization/merchantSignups/metrics';
  const tableUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/signup/agentsSignups/findAllByAggregatorsFullnameAndStatus?limit=10&offset=0&status=unapproved'
      : '/apis/bizzdesk/organization/merchantSignups/getTable?limit=10&offset=0&status=unapproved';

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data } = useSWR([url, token, 'GET'], ([url, USER_TOKEN, method]) =>
    swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  /* A data for the transactionStatsData component. */
  const transactionStatsData = [
    {
      amount: (
        <CurrencyFormat
          value={12}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
      title: 'New',
    },
    {
      amount: (
        <CurrencyFormat
          value={12}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
      title: 'Approved',
    },
    {
      amount: (
        <CurrencyFormat
          value={12}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
      title: 'Rejected',
    },
    {
      amount: (
        <CurrencyFormat
          value={12}
          displayType={'text'}
          thousandSeparator={true}
        />
      ),
      title: 'Pending',
    },
  ];

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          fullName: item?.fullName,
          email: item?.email,
          phoneNumber: item?.phoneNumber,
          bvn: item?.bvn,
          businessName: item?.businessName,
          businessAddress: item?.businessAddress,
          dateAdded: item?.dateAdded,
          status: item?.status,
          action: {
            link: `/business-merchant/merchant-details/${item?.email}`,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'full name',
    },
    {
      headerName: 'email',
    },
    {
      headerName: 'phone number',
    },
    {
      headerName: 'bvn',
    },
    {
      headerName: 'business name',
    },
    {
      headerName: 'business address',
    },
    {
      headerName: 'date added',
    },
    {
      headerName: 'status',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <>
      <div tw="grid grid-cols-2 gap-3 md:grid-cols-3 xl:(grid-cols-4 gap-5)">
        {transactionStatsData.map(({ amount, value, link, title }, index) => {
          return (
            <TransactionCard
              key={index}
              title={title}
              amount={amount}
              value={value}
              isValidating={true}
            />
          );
        })}
      </div>

      <DataGridViewTemp
        link="/checkout/signup-list"
        limited
        title="List of New Signups"
        rows={[]}
        columns={TransWalletColumn}
        // isValidating={isValidating}
      />
    </>
  );
};

export default Overview;
