import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const CreatePassword = () => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [newPassword, setNewPassword] = React.useState('');
  const [submittig, setSubmitting] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const email = query.get('email');
  const key = query.get('k');

  let navigate = useNavigate();
  let toast = useToast();

  const handleCreatePassword = event => {
    event.preventDefault();

    if (newPassword !== confirmPassword || !newPassword || !confirmPassword) {
      toast({
        title: 'Failed.',
        position: 'top',
        description: 'Password does not match',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });

      return;
    }

    setSubmitting(true);

    axios({
      method: 'post',
      responseType: 'json',
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/signup/new/admin/user/passwordChange',
      data: {
        oldpassword: key,
        password: newPassword,
        username: email,
      },
    })
      .then(response => {
        setSubmitting(false);

        toast({
          // title: 'Login Successful.',
          position: 'top',
          description: response.data.message,
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/auth/login');
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setSubmitting(false);
      });
  };

  return (
    <Box
      w="full"
      h={['100%', '100vh']}
      display="flex"
      alignItems="center"
      justifyContent="base-start"
      flexDirection="column"
      mt="2rem"
    >
      <Image src="assets/logo_purple.png" alt="" />
      <Box
        mt="4rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text fontWeight="400" fontSize="24px">
          Create new password
        </Text>
        <VStack mt="1rem" alignItems="baseline">
          <Text fontSize="13px" color="#454D54">
            Enter new Password
          </Text>
          <InputGroup size="md">
            <Input
              h="42px"
              bg="#fff"
              color="#191716"
              w={['100%', '308px']}
              borderRadius="4px"
              type={show ? 'text' : 'password'}
              placeholder="Enter password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              required
            />
            <InputRightElement>
              <Button h="full" size="sm" onClick={handleClick}>
                {show ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </VStack>
        <VStack mt="1rem" alignItems="baseline">
          <Text fontSize="13px" color="#454D54">
            Confirm new Password
          </Text>
          <InputGroup size="md">
            <Input
              h="42px"
              bg="#fff"
              color="#191716"
              w={['100%', '308px']}
              borderRadius="4px"
              type={show ? 'text' : 'password'}
              placeholder="Enter password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.target.value)}
              required
            />
            <InputRightElement>
              <Button h="full" size="sm" onClick={handleClick}>
                {show ? <ViewOffIcon /> : <ViewIcon />}
              </Button>
            </InputRightElement>
          </InputGroup>
        </VStack>
        {/* <Box mt="1rem" display="flex" justifyContent="space-between" w="full">
          <HStack>
            <Checkbox borderRadius="50% !important">
              <Text fontSize="13px" as="span">
                Remember me
              </Text>
            </Checkbox>
          </HStack>
          <Link
            fontSize="13px"
            textDecoration="underline"
            color="#6500E0"
            href=""
          >
            Forgotten password
          </Link>
        </Box> */}
        <Center w="full">
          <Button
            variant="outline"
            colorScheme="#6500E0"
            loadingText="Loading"
            isLoading={submittig}
            bg="#6500E0"
            mt="2rem"
            w="full"
            color="#fff"
            align="center"
            p="1rem"
            borderRadius="4px"
            role="group"
            cursor="pointer"
            _hover={{
              bg: '#5403b8',
              color: 'white',
            }}
            onClick={handleCreatePassword}
          >
            Create new password
          </Button>
        </Center>
      </Box>
    </Box>
  );
};

export default CreatePassword;
