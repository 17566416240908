/** @jsxImportSource @emotion/react */
import { Box, Button, Skeleton, Text, useToast } from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import WithdrawModal from '../Common/WithdrawModal';
import FundWalletModal from '../Wallet/Modals/FundWalletModal';

const WalletBalance = ({
  data,
  balance,
  label,
  hasNoFundWallet,
  hasNoWithdraw,
}) => {
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const [loading, setLoading] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [withdrawData, setWithdrawData] = React.useState({});

  const withdrawwFundDetailsUrl = `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/transfer/admin/withdrawFundDetails`;

  const toast = useToast();

  const handleWithdraw = async () => {
    if (userType !== 'admin') {
      setLoading(true);

      axios({
        method: 'get',
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
        url: withdrawwFundDetailsUrl,
      })
        .then(response => {
          setLoading(false);
          setWithdrawData(response.data);
          setOpen(true);
        })
        .catch(err => {
          toast({
            title: 'Failed.',
            position: 'top',
            description: err.response.data.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });

          setLoading(false);
        });
    }
  };

  return (
    <Box
      w="full"
      h={['100%', '163px']}
      p={['1rem', '0']}
      borderRadius="20px"
      bg="#EBF2FA"
      position="relative"
      mt="2rem"
    >
      <Box
        justifyContent="center"
        display="flex"
        w="full"
        h="full"
        flexDirection={['column', 'row']}
        alignItems="flex-start"
        zIndex="100"
        gap={['6', '0']}
        tw="bg-bgSVG bg-no-repeat bg-cover bg-center"
      >
        <Box
          justifyContent="center"
          display="flex"
          flexDirection="column"
          float="left"
          w={['full', '50%']}
          h="full"
          ml="2rem"
          alignItems="flex-start"
          zIndex="100"
          gap="3"
        >
          <Skeleton isLoaded={data}>
            <Text zIndex="100" fontSize="16px" fontWeight="500">
              {label}
            </Text>

            <Text
              as="div"
              zIndex="100"
              display="flex"
              fontSize="40px"
              fontWeight="500"
            >
              <CurrencyFormat
                value={balance}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₦'}
              />
            </Text>
          </Skeleton>
        </Box>
        <Box
          float="right"
          display="flex"
          gap="3"
          w={['full', '50%']}
          h="full"
          alignItems="center"
          justifyContent="flex-end"
          mr="2rem"
          flexDirection={['column', 'row']}
        >
          {!hasNoFundWallet && <FundWalletModal />}
          {!hasNoWithdraw && (
            <Button
              w="185px"
              h="56px"
              borderRadius="12px"
              color="#fff"
              bg="#191716"
              onClick={handleWithdraw}
              isLoading={loading}
            >
              Withdraw
            </Button>
          )}
        </Box>
      </Box>
      <WithdrawModal
        open={open}
        setOpen={setOpen}
        withdrawData={withdrawData}
      />
    </Box>
  );
};

export default WalletBalance;
