import { Box, Text } from '@chakra-ui/react';
import { Box as MUIBox } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Approved from './signups/Approved';
import Overview from './signups/Overview';
import Unapproved from './signups/Unapproved';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import TabPanel from '../Common/TabPanel';

const Signup = () => {
  const navigate = useNavigate();

  const [subtabValue, setsubtabValue] = React.useState(0);

  // get tab value from url param
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const subtab = urlParams.get('subtab');
    if (subtab) {
      setsubtabValue(parseInt(subtab));
    }
  }, []);

  const Tab = ({ label, index }) => {
    return (
      <button
        css={[
          tw`text-black text-sm 2xl:text-base py-1.5 md:py-2.5 2xl:py-3.5 px-2 xl:px-4 tracking-[-0.025em] font-medium`,
          index === subtabValue &&
            tw`text-white bg-[#6500E0] rounded-lg xl:rounded-[12px]`,
        ]}
        onClick={() => {
          navigate({
            pathname: '/checkout',
            search: `?tab=1&subtab=${index}`,
          });

          setsubtabValue(index);
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <Box>
      <MUIBox sx={{ width: '100%', marginTop: '24px' }}>
        <MUIBox
          sx={{
            paddingBottom: {
              xs: '20px',
              lg: '40px',
            },
          }}
        >
          <div tw="space-x-4">
            <Tab label="Overview" index={0} />
            <Tab label="Approved Signups" index={1} />
            <Tab label="Rejected Signups" index={2} />
          </div>
        </MUIBox>
        <TabPanel tabvalue={subtabValue} index={0}>
          <Overview />
        </TabPanel>
        <TabPanel tabvalue={subtabValue} index={1}>
          <Approved />
        </TabPanel>
        <TabPanel tabvalue={subtabValue} index={2}>
          <Unapproved />
        </TabPanel>
      </MUIBox>
    </Box>
  );
};

export default Signup;
