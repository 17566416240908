import { Box, Text } from '@chakra-ui/react';
import { Box as MUIBox } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Approved from './Approved';
import Overview from './Overview';
import Unapproved from './Unapproved';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import TabPanel from '../Common/TabPanel';

const Signup = () => {
  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState(0);

  // get tab value from url param
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTabValue(parseInt(tab));
    }
  }, []);

  const Tab = ({ label, index }) => {
    return (
      <button
        css={[
          tw`text-black text-sm 2xl:text-base py-1.5 md:py-2.5 2xl:py-3.5 px-2 xl:px-4 tracking-[-0.025em] font-medium`,
          index === tabValue &&
            tw`text-white bg-[#6500E0] rounded-lg xl:rounded-[12px]`,
        ]}
        onClick={() => {
          navigate({
            pathname: '/sign-up',
            search: `?tab=${index}`,
          });

          setTabValue(index);
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <Box>
      <Text flat="left" fontWeight="700" fontSize="32px">
        Merchants
      </Text>

      <MUIBox sx={{ width: '100%', marginTop: '24px' }}>
        <MUIBox
          sx={{
            paddingBottom: {
              xs: '20px',
              lg: '40px',
            },
          }}
        >
          <div tw="space-x-4">
            <Tab label="Overview" index={0} />
            <Tab label="Approved Signups" index={1} />
            <Tab label="Rejected Signups" index={2} />
          </div>
        </MUIBox>
        <TabPanel tabvalue={tabValue} index={0}>
          <Overview />
        </TabPanel>
        <TabPanel tabvalue={tabValue} index={1}>
          <Approved />
        </TabPanel>
        <TabPanel tabvalue={tabValue} index={2}>
          <Unapproved />
        </TabPanel>
      </MUIBox>
    </Box>
  );
};

export default Signup;
