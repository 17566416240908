import {
  Box,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import imgs from './assets/delete.png';
import imgs3 from './assets/disable.png';

const TableUI = ({ id, documenttitle, type, size, url }) => {
  return (
    <Tr>
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {documenttitle}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {type}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {size}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {url}{' '}
      </Td>
      <Td color="#ED6A5A" display="flex" gap="2">
        <Link to="">
          <img src={imgs} alt="" style={{ width: '24px', height: '24px' }} />
        </Link>
        <Link to="">
          <img src={imgs3} alt="" style={{ width: '24px', height: '24px' }} />
        </Link>
      </Td>
    </Tr>
  );
};

const AddMerchantTable = ({ userEmail }) => {
  const [loading, setLoading] = React.useState(false);
  const [val, setVal] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  const validate = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/checker/getFileDetailsForMerchant?userName=${userEmail}`,
    })
      .then(response => {
        setLoading(false);
        setVal(response.data);
        // console.log('valllld>>>', response);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box w="full">
      <TableContainer w="full">
        <Table w="full" variant="simple">
          <Thead>
            <Tr>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                s/n
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                document-title
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                type
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                size
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                URL
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                noOfLines={2}
                textTransform="uppercase"
              >
                actions
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                noOfLines={2}
                textTransform="uppercase"
              ></Th>
            </Tr>
          </Thead>
          <Tbody>
            {loading ? (
              <Skeleton w="full" h="50%"></Skeleton>
            ) : Array?.isArray(val) ? (
              val.map((data, index) => (
                <TableUI
                  key={data.id}
                  id={index + 1}
                  documenttitle={data.documentType}
                  type={data.documentTitle}
                  size={data.documentSize}
                  url={data.imageUrl}
                />
              ))
            ) : null}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AddMerchantTable;
