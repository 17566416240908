/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import * as Yup from 'yup';

function AddTerminalModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  //   validation

  const validationSchema = Yup.object().shape({
    serialnumber: Yup.string().required('Serial number is required'),
    modelType: Yup.string().required('model type is required'),
    transactionlimit: Yup.string().required('Transaction Limit is required'),
    // organizationName: Yup.string().required('Organization Name is required'),
    osType: Yup.string().required('Os type is required'),
    tid: Yup.string().required('Tid is required'),
    assignmerchant: Yup.string().required('Transaction Limit is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  const [value, setValue] = React.useState('');
  const [mail, setMail] = React.useState([]);
  const [org, setOrg] = React.useState([]);
  const [orgLoading, setOrgLoading] = React.useState(false);

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const getNameList =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/Merchants/getNamesList'
      : 'https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/merchant/getNamesList';

  const getOrgNameList =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/Organization/getNamesList'
      : '';

  const uploadTerminal =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/upload/singleUpload/uploadTerminal'
      : 'https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/upload/singleUpload/uploadTerminal';

  const validates = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getNameList,
    })
      .then(response => {
        setLoading(false);
        setMail(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  const validatesOrg = () => {
    setOrgLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getOrgNameList,
    })
      .then(response => {
        setOrgLoading(false);
        setOrg(response.data);
      })
      .catch(err => {
        console.log('OrgNameList >>>', err);
        setOrgLoading(false);
      });
  };
  React.useEffect(() => {
    validates();
    validatesOrg();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validate = (data, event) => {
    event.preventDefault();

    setLoading(true);

    const getBankByTid =
      userType === 'admin'
        ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/getBankByTid?tid=${data?.tid}`
        : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/getBankByTid?tid=${data?.tid}`;

    const input = {
      serialnumber: data?.serialnumber,
      modelType: data?.modelType,
      osType: data?.osType,
      tid: data?.tid,
      bank: value,
      merchantUsername: data?.assignmerchant,
      nibbsRate: '',
      transactionLimit: data?.transactionlimit,
      organizationUsername: userType === 'admin' ? data?.organizationName : '',
      terminalType: '',
    };

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getBankByTid,
    })
      .then(response => {
        setLoading(false);

        setValue(response.data.bank);

        axios({
          method: 'post',
          headers: {
            Authorization: `Bearer ${token}`,
            'Access-Control-Allow-Origin': '*',
          },
          url: uploadTerminal,
          data: input,
        })
          .then(response => {
            setLoading(false);
            toast({
              // title: 'A New Role Created!.',
              position: 'top',
              description: 'Terminal created successfully.',
              status: 'success',
              duration: 9000,
              isClosable: true,
            });
            onClose();
          })
          .catch(err => {
            toast({
              title: 'Failed.',
              position: 'top',
              description: err.response.data.message,
              status: 'error',
              duration: 9000,
              isClosable: true,
            });
            setLoading(false);
            onClose();
          });
      })
      .catch(err => {
        setLoading(false);
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Image src="/assets/add-square.png" alt="" />}
        bg="#6500E0"
        w="149px"
        h="42px"
        borderRadius="8px"
        colorScheme="#6500E0"
        variant="solid"
      >
        Add Terminal
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Add Terminal
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <Box
              mt="1rem"
              p="1rem"
              display="flex"
              flexDirection="column"
              gap="3"
            >
              <form onSubmit={handleSubmit(validate)} tw="space-y-2">
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Terminal ID
                  </Text>
                  <Input
                    placeholder=""
                    {...register('tid')}
                    border={errors.tid ? '1px solid red' : ' 1px solid #E3E5E8'}
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.tid?.assignmerchant}
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Serial Number
                  </Text>
                  <Input
                    placeholder=""
                    {...register('serialnumber')}
                    border={
                      errors.serialnumber
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.title?.serialnumber}
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Model
                  </Text>
                  <Input
                    placeholder=""
                    {...register('modelType')}
                    border={
                      errors.modelType ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.modelType?.message}
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Transaction Limit
                  </Text>
                  <Input
                    placeholder=""
                    {...register('transactionlimit')}
                    border={
                      errors.transactionlimit
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.title?.transactionlimit}
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    OS Type
                  </Text>
                  <Input
                    placeholder=""
                    {...register('osType')}
                    border={
                      errors.osType ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.title?.osType}
                  </Text>
                </VStack>

                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Bank
                  </Text>
                  <Input
                    placeholder={value}
                    disabled
                    border=" 1px solid #E3E5E8"
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                </VStack>
                {userType === 'admin' && (
                  <VStack alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Organization Name
                    </Text>
                    <Select
                      _focus={{ outline: 'none' }}
                      _active={{ outline: 'none' }}
                      {...register('organizationName')}
                      border={
                        errors.organizationName
                          ? '1px solid red'
                          : ' 1px solid #E3E5E8'
                      }
                      borderRadius="4px"
                      fontSize="13px"
                      placeholder="Select organiztion name"
                    >
                      {Array?.isArray(org)
                        ? org.map((e, id) => {
                            return (
                              <Box
                                key={id}
                                as="option"
                                value={e?.userName}
                                bg="#F3F7FC"
                                borderBottom="1px solid #ccc"
                              >
                                <Text>
                                  {' '}
                                  {orgLoading ? 'Name' : e?.fullName}{' '}
                                  &nbsp;&nbsp;&nbsp;
                                </Text>
                                <Text>
                                  &#40;{orgLoading ? 'Name' : e?.userName}&#41;
                                </Text>
                              </Box>
                            );
                          })
                        : null}
                    </Select>
                    <Text color="red" mt=".5rem">
                      {errors.title?.organizationName}
                    </Text>
                  </VStack>
                )}
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Assign Merchant
                  </Text>
                  <Select
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                    {...register('assignmerchant')}
                    border={
                      errors.assignmerchant
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    fontSize="13px"
                    placeholder="Select Merchant"
                  >
                    {Array?.isArray(mail)
                      ? mail.map((e, id) => {
                          return (
                            <Box
                              key={id}
                              as="option"
                              value={e?.userName}
                              bg="#F3F7FC"
                              borderBottom="1px solid #ccc"
                            >
                              <Text>
                                {' '}
                                {loading ? 'Name' : e?.fullName}{' '}
                                &nbsp;&nbsp;&nbsp;
                              </Text>
                              <Text>
                                &#40;{loading ? 'Name' : e?.userName}&#41;
                              </Text>
                            </Box>
                          );
                        })
                      : null}
                  </Select>
                  <Text color="red" mt=".5rem">
                    {errors.title?.assignmerchant}
                  </Text>
                </VStack>
                <Center w="full">
                  {loading ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  )}
                </Center>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddTerminalModal;
