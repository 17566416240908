import { Box, HStack, Link, Text } from '@chakra-ui/react';
import React from 'react';
import MerchantWalletList from './MerchantWalletList';

const MerchantWalletPage = () => {
  return (
    <Box p="4">
      <Box mb="1rem">
        <Link style={{ ontSize: '14px', fontWeight: '300' }} href="/wallet">
          Back
        </Link>
      </Box>
      <Box>
        <Box
          display="flex"
          gap="6"
          justifyContent="space-between"
          w="full"
          flexDirection={['row', 'row']}
          mt="2rem"
        >
          <Text fontWeight="500" color="#191716" fontSize="20px" float="left">
            BBM Merchant Wallet
          </Text>
          <HStack float="right">
            {/* <Link style={{ color: '#6500E0' }} to="/terminals/list">
                View All
              </Link> */}
            {/* <Image src="assets/merchant_blue_arrow_right.png" alt="" /> */}
          </HStack>
        </Box>
        <MerchantWalletList />
      </Box>
    </Box>
  );
};

export default MerchantWalletPage;
