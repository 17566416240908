import {
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import useSWR from 'swr';
import { swrfetcher } from '../../../utils/swrFetcher';
import image1 from '../../BusinessMerchant/assets/viewterminal.png';
import DisableTerminal from './DisableTerminal';
import EditTerminal from './EditTerminal';
import EditTerminalModal from './EditTerminalModal';

function ViewTerminal({ userEmail, serialnumber }) {
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  let toast = useToast();

  const url =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/terminals/profile/getTerminalProfile?tid=${userEmail}`
      : `/apis/bizzdesk/organization/terminals/getTerminalProfile?tid=${userEmail}`;

  const rawurl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/profile/getTerminalProfile?tid=${userEmail}`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/getTerminalProfile?tid=${userEmail}`;

  const approveUrl =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/actions/activateSuspendedTerminal'
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/actions/activateSuspendedTerminal`;

  const { data } = useSWR([url, token, 'GET'], ([url, USER_TOKEN, method]) =>
    swrfetcher(url, USER_TOKEN, method)
  );

  // eslint-disable-next-line
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUserDetails] = React.useState([]);

  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);

  const handleActivate = event => {
    event.preventDefault();
    const input = {
      serialnumber: data?.serialnumber,
      tid: userEmail,
      reason: '',
    };

    setLoading(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: approveUrl,
      data: input,
    })
      .then(response => {
        setLoading(false);
        setUserDetails(response.data);
        toast({
          // title: 'Terminal has been disabled successfully!.',
          position: 'top',
          description: 'Terminal activated successfully!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
      });
  };

  const validate = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: rawurl,
    })
      .then(response => {
        setLoading(false);
        setUserDetails(response.data);
      })
      .catch(err => {
        setLoading(false);
        //   onClose();
      });
  };

  React.useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Image
        onClick={onOpen}
        cursor="pointer"
        src={image1}
        alt=""
        h="24px"
        w="24px"
      />

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            View Terminal
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <Box mt="1rem">
              <Box
                w="full"
                h="full"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                gap="6"
              >
                <Text fontSize="24px" color="#191716" fontWeight="500">
                  {userEmail}
                </Text>
                <Text
                  fontSize="10px"
                  textTransform="uppercase"
                  color={
                    data?.status?.toLowerCase() === 'active'
                      ? '#136F63'
                      : data?.status?.toLowerCase() === 'inactive'
                      ? '#EDA95A'
                      : '#A6B7D4'
                  }
                  fontWeight="400"
                  bg={
                    data?.status?.toLowerCase() === 'active'
                      ? '#E9FBF9'
                      : data?.status?.toLowerCase() === 'inactive'
                      ? '#FCEAE8'
                      : '#E4ECF7'
                  }
                  borderRadius="4px"
                  p="1"
                >
                  {data?.status}
                </Text>
                <Text fontSize="12px" color="#425D8A" fontWeight="500">
                  Last Seen: {data?.lastSeen}
                </Text>
              </Box>
              <Box p=".2rem" borderBottom="1px solid #EBF2FA">
                <Text fontSize="1rem" color="#191716" fontWeight="500">
                  Overview
                </Text>
                <Box
                  mt=".75rem"
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="400">
                      Battery & Power
                    </Text>
                  </VStack>
                  <VStack alignItems="baseline">
                    <Text fontSize="13px" color="#425D8A" fontWeight="400">
                      Network
                    </Text>
                  </VStack>
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="400">
                      Printer
                    </Text>
                  </VStack>
                </Box>
                <Box
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <VStack alignItems="baseline">
                    <Text fontSize="16px" color="#000" fontWeight="500">
                      {data?.batteryPercentage
                        ? data?.batteryPercentage + '%'
                        : 'Nil'}
                    </Text>
                  </VStack>
                  <VStack alignItems="baseline">
                    <Text fontSize="16px" color="#000" fontWeight="500">
                      {data?.signals || 'Nil'}
                    </Text>
                  </VStack>
                  <VStack>
                    <Text fontSize="12px" color="#EDA95A" fontWeight="400">
                      {data?.printerStatus}
                    </Text>
                  </VStack>
                </Box>
                <Box
                  display="flex"
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <VStack>
                    <Text fontSize="12px" color="#EDA95A" fontWeight="400">
                      {data?.isCharging ? 'Charging' : 'Not Charging'}
                    </Text>
                  </VStack>
                  <VStack alignItems="baseline">
                    <Text
                      fontSize="12px"
                      color="#EDA95A"
                      fontWeight="400"
                    ></Text>
                  </VStack>
                  <VStack>
                    <Text
                      fontSize="12px"
                      color="#EDA95A"
                      fontWeight="400"
                    ></Text>
                  </VStack>
                </Box>
              </Box>
              <Box p=".2rem" borderBottom="1px solid #EBF2FA" mt="1rem">
                <Text fontSize="1rem" color="#191716" fontWeight="500">
                  Transactions
                </Text>
                <Box mt=".75rem" display="flex" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="400">
                      Transaction Today
                    </Text>
                  </VStack>
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="400">
                      Last Transaction
                    </Text>
                  </VStack>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="16px" color="#000" fontWeight="500">
                      <CurrencyFormat
                        value={data?.totalAmount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={'₦'}
                      />
                    </Text>
                  </VStack>
                  <VStack>
                    <Text fontSize="16px" color="#000" fontWeight="500">
                      {data?.lastTransactionDate || ''}
                    </Text>
                  </VStack>
                </Box>
              </Box>
              <Box p=".2rem" borderBottom="1px solid #EBF2FA" mt="1rem">
                <Text fontSize="1rem" color="#191716" fontWeight="500">
                  Details
                </Text>
                <Box mt=".75rem" display="flex" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="400">
                      Merchant
                    </Text>
                  </VStack>
                </Box>
                <Box
                  p=".2rem"
                  borderBottom="1px solid #EBF2FA"
                  display="flex"
                  justifyContent="space-between"
                >
                  <VStack>
                    <Box display="flex" gap="2">
                      <Image
                        src={data?.merchantProfilePicUrl}
                        w="40px"
                        h="40px"
                        bg="#C4C4C4"
                        borderRadius="100px"
                        objectFit={'cover'}
                      />
                      <Box display="flex" flexDirection="column">
                        <Text color="#191716" fontSize="14px" fontWeight="400">
                          {data?.merchantFullName}
                        </Text>
                        <Text color="#425D8A" fontSize="12px" fontWeight="300">
                          #{data?.merchantWalletId}
                        </Text>
                      </Box>
                    </Box>
                  </VStack>
                </Box>
                <Box mt=".75rem" display="flex" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="300">
                      Type
                    </Text>
                  </VStack>
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="300">
                      Model
                    </Text>
                  </VStack>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="14px" color="#000" fontWeight="400">
                      {data?.terminalType || 'Nil'}
                    </Text>
                  </VStack>
                  <VStack>
                    <Text fontSize="14px" color="#000" fontWeight="400">
                      {data?.modelType || 'Nil'}
                    </Text>
                  </VStack>
                </Box>
                <Box mt=".75rem" display="flex" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="300">
                      Software
                    </Text>
                  </VStack>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="14px" color="#000" fontWeight="400">
                      {data?.osType || 'Nil'}
                    </Text>
                  </VStack>
                </Box>
                <Box display="flex" mt=".75rem" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="300">
                      Serial number
                    </Text>
                  </VStack>
                </Box>
                <Box display="flex" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="14px" color="#000" fontWeight="400">
                      {data?.serialnumber || 'Nil'}
                    </Text>
                  </VStack>
                </Box>
                <Box display="flex" mt=".75rem" justifyContent="space-between">
                  <VStack>
                    <Text fontSize="13px" color="#425D8A" fontWeight="300">
                      Date Created: {data?.dateCreated}
                    </Text>
                  </VStack>
                </Box>
                <Box display="flex" mt="1rem" gap="6">
                  <EditTerminalModal
                    serialnumber={data?.serialnumber}
                    email={data?.email}
                    userEmail={userEmail}
                    isFullButton
                  />
                  {data?.status?.toLowerCase() === 'active' ? (
                    <DisableTerminal
                      serialnumber={data?.serialnumber}
                      userEmail={userEmail}
                    />
                  ) : (
                    <Button
                      onClick={handleActivate}
                      isLoading={loading}
                      loadingText={'loading'}
                      border="none"
                      borderRadius="12px"
                      w="full"
                      h="55px"
                      color="#fff"
                      bg="#136F63"
                      _hover={{
                        color: '#136F63',
                        bg: '#F3F7FC',
                      }}
                    >
                      Activate
                    </Button>
                  )}
                </Box>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ViewTerminal;
