import {
  Box,
  Image,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Stick from '../../Common/Stick';
// import Pagination from '../../Common/Pagination';
import image1 from '../../BusinessMerchant/assets/print.png';
import useSWR from 'swr'
import { swrfetcher } from '../../../utils/swrFetcher';
import FilterMainWallet from '../FilterMainWallet';

import image2 from '../../BusinessMerchant/assets/eye.png';

const TableUI = ({
  id,
  merchants,
  band,
  walletid,
  walletbalance,
  chargeback,
  createdon,
  walletuserId,
}) => {
  return (
    <Tr>
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {merchants}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {band}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {walletid}{' '}
      </Td>
      <Td fontSize="14px" textAlign="center" fontWeight="400">
        {walletbalance}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {chargeback}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {createdon}{' '}
      </Td>
      <Td color="#ED6A5A" display="flex" gap="2">
        <Link to={`/wallet/${walletuserId}`}>
          <Image src={image2} alt="" h="24px" w="24px" />
        </Link>
        <Link to="">
          <Image src={image1} alt="" h="24px" w="24px" />
        </Link>
      </Td>
    </Tr>
  );
};

const MerchantWalletList = () => {
  const [loading, setLoading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/getWalletRecord?limit=2000&offset=0`
      : `/apis/bizzdesk/organization/wallet/getWalletRecord?limit=2000&offset=0`;

  const { data: val } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = React.useState(1);
  // eslint-disable-next-line
  const [postPerPage, setPostPerPage] = React.useState(10);

  
  // get current post
  const indexOfLastPage = currentPage * postPerPage;

  const indexOfFirstPage = indexOfLastPage - postPerPage;

  const serial = Array?.isArray(val)
    ? val?.map((data, index) => ({ ...data, serialno: index + 1 }))
    : null;

  const currentPosts = Array?.isArray(serial)
    ? serial?.slice(indexOfFirstPage, indexOfLastPage)
    : serial?.toString().slice(indexOfFirstPage, indexOfLastPage);

  // const paginate = pageNumber => {
  //   setCurrentPage(pageNumber);
  // };
  // filter server data
  const [displayData, setDisplayData] = useState([]);
  const [firstDate, setFirstDate] = useState();
  const [secondDate, setSecondDate] = useState();
  const [search, setSearch] = useState();
  const [trues, setTrue] = useState(false);

  const filterTransactions = () => {
    setDisplayData(currentPosts);
    setTrue(true);
    // const firstDate = new Date().toISOString();
    // const secondDate = new Date().toISOString();

    const newData = val.filter(data => {
      const firstDateInNumber = new Date(firstDate).getTime();
      const secondDateInNumber = new Date(secondDate).getTime();
      const dataDate = new Date(data.date).getTime();

      if (
        (firstDateInNumber <= dataDate && secondDateInNumber <= dataDate) ||
        search === data.transType
      ) {
        return true;
      }
      return false;
    });
    setDisplayData(newData);
  };
  return (
    <Box mt="1rem">
      <FilterMainWallet
        setSecondDate={setSecondDate}
        setFirstDate={setFirstDate}
        setSearch={setSearch}
        filterTransactions={filterTransactions}
      />

      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                s/n
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                merchants
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                band
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                wallet id
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                noOfLines={2}
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                wallet balance
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                charge back
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                created on
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                action
              </Th>
            </Tr>
          </Thead>
          {trues ? (
            <Tbody>
              {loading ? (
                <Stick />
              ) : Array?.isArray(val) ? (
                displayData?.map((data, index) => (
                  <TableUI
                    key={data.userName}
                    id={data.serialno}
                    merchants={data.fullName}
                    band={data.band}
                    walletid={data.walletId}
                    walletbalance={data.walletBalance}
                    chargeback={data.chargeBack}
                    createdon={data.createOn}
                  />
                ))
              ) : null}
            </Tbody>
          ) : (
            <Tbody>
              {loading ? (
                <Stick />
              ) : Array?.isArray(val) ? (
                currentPosts?.map((data, index) => (
                  <TableUI
                    key={data.userName}
                    id={data.serialno}
                    merchants={data.fullName}
                    band={data.band}
                    walletid={data.walletId}
                    walletbalance={data.walletBalance}
                    chargeback={data.chargeBack}
                    createdon={data.createOn}
                  />
                ))
              ) : null}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {/* <Pagination
        postPerPage={postPerPage}
        paginate={paginate}
        totalPosts={val.length}
      /> */}
    </Box>
  );
};

export default MerchantWalletList;
