/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Drawer,
  DrawerContent,
  HStack,
  Image,
  Select,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import DataGridViewTemp from '../component/Common/DataGridViewTemp';
import Sidebar from '../component/Common/Sidebar';
import Topnav from '../component/Common/Topnav';
import { swrfetcher } from '../utils/swrFetcher';

const Transaction = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const [loading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const pageUrl = '/apis/v1/bizzdesk/admin/organizations/analytics';
  const tableUrl =
    '/apis/v1/bizzdesk/admin/admin/analytics/getOrganizationAnalyticsTable?limit=10&offset=0';

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: val } = useSWR(
    [pageUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          organizationId: item?.merchantId,
          name: item?.fullname,
          organizationName: item?.organizationName,
          terminals: item?.terminals,
          walletbalanceN: item?.walletBallance,
          walletaccount: item?.walletAccount,
          transactionN: item?.totalAmountInTransactions,
          nooftransactions: item?.noOfTransactions,
          charge: item?.charge,
          datecreated: item?.dateAdded,
          status: item?.status,
          supportstaff: item?.supportStaff,
          action: {
            disable: true,
            link: `/organizations/${item?.username}`,
            edit: true,
          },
        };
      });
    }
  }

  const TransColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'organization id',
    },
    {
      headerName: 'name',
    },
    {
      headerName: 'Organization Name',
    },
    {
      headerName: 'terminals',
    },
    {
      headerName: 'wallet balance',
    },
    {
      headerName: 'wallet account',
    },
    {
      headerName: 'Total Transaction Amount(N)',
    },
    {
      headerName: 'No of Transaction',
    },
    {
      headerName: 'charges',
    },
    {
      headerName: 'date created',
    },
    {
      headerName: 'status',
    },
    {
      headerName: 'support staff',
    },
  ];

  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <>
          <Box>
            <Box
              display="flex"
              gap="6"
              justifyContent="space-between"
              w="full"
              flexDirection={['column', 'row']}
              mt="1rem"
            >
              <Text
                float="left"
                fontSize="32px"
                color="#16192C"
                fontWeight="700"
              >
                Organizations
              </Text>
              <Box float="right" tw="space-x-4">
                <Link to="/organizations/add-organization">
                  <Button
                    leftIcon={<Image src="/assets/add-square.png" alt="" />}
                    bg="#6500E0"
                    w="179px"
                    h="42px"
                    borderRadius="8px"
                    colorScheme="#6500E0"
                    variant="solid"
                  >
                    Add Organization
                  </Button>
                </Link>
              </Box>
            </Box>

            <Box
              display="flex"
              gap="6"
              flexDirection={['column', 'row']}
              mt="2rem"
            >
              <Box
                bg="#fff"
                borderRadius="4px"
                w={['100%', '359.67px']}
                h={['100%', '191px']}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="3"
                p=".75rem"
              >
                <Box display="flex" w="full" justifyContent="space-between">
                  <Box float="left">
                    <HStack>
                      <Image
                        float="right"
                        w="24px"
                        h="24px"
                        src="/assets/trend-down.png"
                        alt=""
                      />
                      <Text fontSize="14px" fontWeight="500" color="#ED6A5A">
                        3.4%
                      </Text>
                    </HStack>
                    <Text fontSize="40px" fontWeight="600">
                      {loading ? (
                        <Skeleton w="50%" h="20px" />
                      ) : (
                        val?.totalAgents
                      )}
                    </Text>
                  </Box>
                  <Select
                    w="35%"
                    fontWeight="300"
                    textAlign="center"
                    borderRadius="25px"
                    fontSize="12px"
                    color="#425D8A"
                    placeholder="Last month"
                  >
                    <option style={{ margin: '.75rem' }} value="option1">
                      Option 1
                    </option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select>
                </Box>
                <Box display="flex" gap="3">
                  <HStack>
                    <Text
                      as="span"
                      textTransform="uppercase"
                      color="#425D8A"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      total organizations
                    </Text>
                  </HStack>
                </Box>
              </Box>
              <Box
                bg="#fff"
                borderRadius="4px"
                w={['100%', '359.67px']}
                h={['100%', '191px']}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="3"
                p=".75rem"
              >
                <Box display="flex" w="full" justifyContent="space-between">
                  <Box float="left">
                    <HStack>
                      <Image
                        float="right"
                        w="24px"
                        h="24px"
                        src="/assets/trend-down.png"
                        alt=""
                      />
                      <Text fontSize="14px" fontWeight="500" color="#ED6A5A">
                        3.4%
                      </Text>
                    </HStack>
                    <Text fontSize="40px" fontWeight="600">
                      {loading ? (
                        <Skeleton w="50%" h="20px" />
                      ) : (
                        val?.totalActiveAgents
                      )}
                    </Text>
                  </Box>
                  <Select
                    w="35%"
                    fontWeight="300"
                    textAlign="center"
                    borderRadius="25px"
                    fontSize="12px"
                    color="#425D8A"
                    placeholder="Last month"
                  >
                    <option style={{ margin: '.75rem' }} value="option1">
                      Option 1
                    </option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select>
                </Box>
                <Box display="flex" gap="3">
                  <HStack>
                    <Text
                      as="span"
                      textTransform="uppercase"
                      color="#425D8A"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      active organizations
                    </Text>
                  </HStack>
                </Box>
              </Box>
              <Box
                bg="#fff"
                borderRadius="4px"
                w={['100%', '359.67px']}
                h={['100%', '191px']}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                gap="3"
                p=".75rem"
              >
                <Box display="flex" w="full" justifyContent="space-between">
                  <Box float="left">
                    <HStack>
                      <Image
                        float="right"
                        w="24px"
                        h="24px"
                        src="/assets/trend-down.png"
                        alt=""
                      />
                      <Text fontSize="14px" fontWeight="500" color="#ED6A5A">
                        3.4%
                      </Text>
                    </HStack>
                    <Text fontSize="40px" fontWeight="600">
                      {loading ? (
                        <Skeleton w="50%" h="20px" />
                      ) : (
                        val?.totalInactiveAgents
                      )}
                    </Text>
                  </Box>
                  <Select
                    w="35%"
                    fontWeight="300"
                    textAlign="center"
                    borderRadius="25px"
                    fontSize="12px"
                    color="#425D8A"
                    placeholder="Last month"
                  >
                    <option style={{ margin: '.75rem' }} value="option1">
                      Option 1
                    </option>
                    <option value="option2">Option 2</option>
                    <option value="option3">Option 3</option>
                  </Select>
                </Box>
                <Box display="flex" gap="3">
                  <HStack>
                    <Text
                      as="span"
                      textTransform="uppercase"
                      color="#425D8A"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      inactive organizations
                    </Text>
                  </HStack>
                </Box>
              </Box>
            </Box>

            {/* <ListOforganizationTable /> */}

            <DataGridViewTemp
              link="/business-organizations/organizations_lists"
              limited
              title="List of Organizations"
              rows={rows}
              columns={TransColumn}
              isValidating={isValidating}
            />
          </Box>
        </>
      </Box>
    </Box>
  );
};

export default Transaction;
