import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class MerchantDonutChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: {},
      sers: [44, 55],
      labels: ['A', 'B'],
    };
  }

  render() {
    return (
      <div className="donut">
        <Chart
          options={this.state.options}
          series={this.state.sers}
          type="donut"
          width="330"
        />
      </div>
    );
  }
}

export default MerchantDonutChart;
