import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import image2 from '../../BusinessMerchant/assets/disable.png';
import image1 from '../../BusinessMerchant/assets/removeProfile.png';

function ReassignTerminalModal({ userTid, serialno, email, status }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [disable, setAble] = React.useState(false);

  //   validation

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('This Field is required'),
    //serialnumber: Yup.string().required('This field is required'),
    //tid: Yup.string().required('This field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const unassignUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/actions/unAssignTerminal`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/actions/unAssignTerminal`;

  const assignUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/actions/assignTerminal`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/actions/assignTerminal`;

  const getNameList =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/Merchants/getNamesList'
      : 'https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/merchant/getNamesList';

  const handleUnAssign = () => {
    setLoading(true);

    const input = {
      agentUsername: email,
      serialnumber: serialno,
      tid: userTid,
    };
    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: unassignUrl,
      data: input,
    })
      .then(response => {
        setLoading(false);
        toast({
          // title: 'Merchant Unassigned From Terminal!.',
          position: 'top',
          description: 'Terminal unassigned successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch(err => {
        setLoading(false);

        toast({
          // title: 'Merchant Unassigned From Terminal!.',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        onClose();
      });
  };

  const deny = (data, event) => {
    event.preventDefault();

    setLoading(true);

    if (disable === true) {
      const input = {
        agentUsername: email,
        serialnumber: serialno,
        tid: userTid,
      };
      axios({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
        url: unassignUrl,
        data: input,
      })
        .then(response => {
          setLoading(false);
          toast({
            title: 'Merchant Removed!.',
            position: 'top',
            description: 'Merchant has been Removed Successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
        })
        .catch(err => {
          setLoading(false);
        });
    } else if (disable === false) {
      const merchant = {
        serialnumber: serialno,
        agentUsername: data.email,
        tid: userTid,
      };
      axios({
        method: 'post',
        headers: {
          Authorization: `Bearer ${token}`,
          'Access-Control-Allow-Origin': '*',
        },
        url: assignUrl,
        data: merchant,
      })
        .then(response => {
          setLoading(false);
          toast({
            // title: 'A New Merchant Added!.',
            position: 'top',
            description: 'Terminal assigned successfully.',
            status: 'success',
            duration: 9000,
            isClosable: true,
          });
          onClose();
        })
        .catch(err => {
          toast({
            title: 'Failed!',
            position: 'top',
            description: err.response.data.message,
            status: 'error',
            duration: 9000,
            isClosable: true,
          });
          setLoading(false);
        });
    }
  };

  // eslint-disable-next-line
  const [mails, setMails] = React.useState();

  const validatess = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getNameList,
    })
      .then(response => {
        setLoading(false);
        setMails(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validatess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Image
        src={status ? image1 : image2}
        onClick={() => {
          setAble(status ? true : false);
          onOpen();
        }}
        alt=""
        h="24px"
        w="24px"
      />

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            {disable ? 'Unassign Terminal' : 'Assign Terminal'}
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <form onSubmit={handleSubmit(deny)}>
              {disable ? (
                <Center w="full">
                  {loading ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      onClick={() => handleUnAssign()}
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Unassign Terminal
                    </Button>
                  )}
                </Center>
              ) : (
                <Box
                  mt="1rem"
                  p="1rem"
                  display="flex"
                  flexDirection="column"
                  gap="3"
                  w="full"
                >
                  <VStack alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Merchant's Fullname
                    </Text>
                    {/* <Input
                      placeholder="Note"
                      border={
                        errors.agentUsername
                          ? '1px solid red'
                          : ' 1px solid #E3E5E8'
                      }
                      borderRadius="4px"
                      color="#000"
                      fontSize="14px"
                      outline="none"
                      id="email"
                      type="text"
                      {...register('email')}
                      _focus={{ outline: 'none' }}
                      _active={{ outline: 'none' }}
                    /> */}
                    <Select
                      _focus={{ outline: 'none' }}
                      _active={{ outline: 'none' }}
                      {...register('email')}
                      border={
                        errors.email ? '1px solid red' : ' 1px solid #E3E5E8'
                      }
                      borderRadius="4px"
                      fontSize="13px"
                      placeholder="email"
                    >
                      {Array?.isArray(mails)
                        ? mails.map((e, id) => {
                            return (
                              <Box
                                key={id}
                                as="option"
                                value={e?.userName}
                                bg="#F3F7FC"
                                borderBottom="1px solid #ccc"
                              >
                                <Text>
                                  {' '}
                                  {loading ? 'Name' : e?.fullName}{' '}
                                  &nbsp;&nbsp;&nbsp;
                                </Text>
                                <Text>
                                  &#40; {loading ? 'Name' : e?.userName}&#41;
                                </Text>
                              </Box>
                            );
                          })
                        : null}
                    </Select>
                    <Text color="red" mt=".5rem">
                      {errors.email?.message}
                    </Text>
                  </VStack>

                  <VStack alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Serial Number
                    </Text>
                    <Input
                      placeholder={serialno}
                      // border={
                      //   errors.serialnumber
                      //     ? '1px solid red'
                      //     : ' 1px solid #E3E5E8'
                      // }
                      borderRadius="4px"
                      color="#000"
                      fontSize="14px"
                      outline="none"
                      id="email"
                      disabled
                      type="text"
                      _focus={{ outline: 'none' }}
                      _active={{ outline: 'none' }}
                    />
                    {/* <Text color="red" mt=".5rem">
                    {errors.serialnumber?.message}
                  </Text> */}
                  </VStack>
                  <VStack alignItems="flex-start">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      Tid
                    </Text>
                    <Input
                      placeholder={userTid}
                      border={
                        errors.tid ? '1px solid red' : ' 1px solid #E3E5E8'
                      }
                      borderRadius="4px"
                      color="#000"
                      fontSize="14px"
                      outline="none"
                      id="email"
                      disabled
                      type="text"
                      // {...register('tid')}
                      _focus={{ outline: 'none' }}
                      _active={{ outline: 'none' }}
                    />
                    {/* <Text color="red" mt=".5rem">
                    {errors.tid?.message}
                  </Text> */}
                  </VStack>

                  <Center w="full">
                    {loading ? (
                      <Button
                        isLoading
                        mt="2rem"
                        w="full"
                        align="center"
                        p="1rem"
                        borderRadius="4px"
                        role="group"
                        cursor="pointer"
                        _hover={{
                          bg: '#5403b8',
                          color: 'white',
                        }}
                        loadingText="Loading"
                        colorScheme="#6500E0"
                        variant="outline"
                      />
                    ) : (
                      <Button
                        bg="#6500E0"
                        mt="2rem"
                        w="full"
                        color="#fff"
                        align="center"
                        p="1rem"
                        borderRadius="4px"
                        role="group"
                        cursor="pointer"
                        _hover={{
                          bg: '#5403b8',
                          color: 'white',
                        }}
                        type="submit"
                      >
                        Done
                      </Button>
                    )}
                  </Center>
                </Box>
              )}
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ReassignTerminalModal;
