import {
  Box,
  Button,
  Center,
  Checkbox,
  HStack,
  Image,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import * as yup from 'yup';

const Permissions = () => {
  const title = JSON?.parse(sessionStorage?.getItem('roletitle'));

  let navigate = useNavigate();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const validationSchema = yup.object().shape({
    transactionsview: yup.bool(),
    transactionsupdate: yup.bool(),
    transactionsdelete: yup.bool(),
    transactionscreate: yup.bool(),
    transactionslist: yup.bool(),
    transactionsprint: yup.bool(),
    transactionsadd: yup.bool(),
    transactionsattach: yup.bool(),

    providersview: yup.bool(),
    providersupdate: yup.bool(),
    providersdelete: yup.bool(),
    providerscreate: yup.bool(),
    providerslist: yup.bool(),
    providersprint: yup.bool(),
    providersadd: yup.bool(),
    providersattach: yup.bool(),

    organizationsview: yup.bool(),
    organizationsupdate: yup.bool(),
    organizationsdelete: yup.bool(),
    organizationscreate: yup.bool(),
    organizationslist: yup.bool(),
    organizationsprint: yup.bool(),
    organizationsadd: yup.bool(),
    organizationsattach: yup.bool(),

    usersview: yup.bool(),
    usersupdate: yup.bool(),
    usersdelete: yup.bool(),
    userscreate: yup.bool(),
    userslist: yup.bool(),
    usersprint: yup.bool(),
    usersadd: yup.bool(),
    usersattach: yup.bool(),

    aggregatorview: yup.bool(),
    aggregatorupdate: yup.bool(),
    aggregatordelete: yup.bool(),
    aggregatorcreate: yup.bool(),
    aggregatorlist: yup.bool(),
    aggregatorprint: yup.bool(),
    aggregatoradd: yup.bool(),
    aggregatorattach: yup.bool(),

    agentsview: yup.bool(),
    agentsupdate: yup.bool(),
    agentsdelete: yup.bool(),
    agentscreate: yup.bool(),
    agentslist: yup.bool(),
    agentsprint: yup.bool(),
    agentsadd: yup.bool(),
    agentsattach: yup.bool(),

    roleview: yup.bool(),
    roleupdate: yup.bool(),
    roledelete: yup.bool(),
    rolecreate: yup.bool(),
    rolelist: yup.bool(),
    roleprint: yup.bool(),
    roleadd: yup.bool(),
    roleattach: yup.bool(),

    settlementsview: yup.bool(),
    settlementsupdate: yup.bool(),
    settlementsdelete: yup.bool(),
    settlementscreate: yup.bool(),
    settlementslist: yup.bool(),
    settlementsprint: yup.bool(),
    settlementsadd: yup.bool(),
    settlementsattach: yup.bool(),

    vasview: yup.bool(),
    vasupdate: yup.bool(),
    vasdelete: yup.bool(),
    vascreate: yup.bool(),
    vaslist: yup.bool(),
    vasprint: yup.bool(),
    vasadd: yup.bool(),
    vasattach: yup.bool(),

    walletsview: yup.bool(),
    walletsupdate: yup.bool(),
    walletsdelete: yup.bool(),
    walletscreate: yup.bool(),
    walletslist: yup.bool(),
    walletsprint: yup.bool(),
    walletsadd: yup.bool(),
    walletsattach: yup.bool(),

    terminalsview: yup.bool(),
    terminalsupdate: yup.bool(),
    terminalsdelete: yup.bool(),
    terminalscreate: yup.bool(),
    terminalslist: yup.bool(),
    terminalsprint: yup.bool(),
    terminalsadd: yup.bool(),
    terminalsattach: yup.bool(),

    subadminview: yup.bool(),
    subadminupdate: yup.bool(),
    subadmindelete: yup.bool(),
    subadmincreate: yup.bool(),
    subadminlist: yup.bool(),
    subadminprint: yup.bool(),
    subadminadd: yup.bool(),
    subadminattach: yup.bool(),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit } = useForm(formOptions);

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const handlesave = (data, event) => {
    event.preventDefault();
    const input = {
      agent: {
        add: data?.agentsadd,
        attach: data?.agentsattach,
        create: data?.agentscreate,
        delete: data?.agentsdelete,
        list: data?.agentslist,
        print: data?.agentsprint,
        update: data?.agentsupdate,
        view: data?.agentsview,
      },
      aggregators: {
        add: data?.aggregatoradd,
        attach: data?.aggregatorattach,
        create: data?.aggregatorcreate,
        delete: data?.aggregatordelete,
        list: data?.aggregatorlist,
        print: data?.aggregatorprint,
        update: data?.aggregatorupdate,
        view: data?.aggregatorview,
      },
      organization: {
        add: data?.organizationsadd,
        attach: data?.organizationsattach,
        create: data?.organizationscreate,
        delete: data?.organizationsdelete,
        list: data?.organizationslist,
        print: data?.organizationsprint,
        update: data?.organizationsupdate,
        view: data?.organizationsview,
      },
      permissionsTitle: JSON?.parse(sessionStorage?.getItem('roletitle')),
      providers: {
        add: data?.providersadd,
        attach: data?.providersattach,
        create: data?.providerscreate,
        delete: data?.providersdelete,
        list: data?.providerslist,
        print: data?.providersprint,
        update: data?.providersupdate,
        view: data?.providersview,
      },
      role: {
        add: data?.roleadd,
        attach: data?.roleattach,
        create: data?.rolecreate,
        delete: data?.roledelete,
        list: data?.rolelist,
        print: data?.roleprint,
        update: data?.roleupdate,
        view: data?.roleview,
      },
      settlements: {
        add: data?.settltementsadd,
        attach: data?.settltementsattach,
        create: data?.settltementscreate,
        delete: data?.settltementsdelete,
        list: data?.settltementslist,
        print: data?.settltementsprint,
        update: data?.settltementsupdate,
        view: data?.settltementsview,
      },
      subAdmin: {
        add: data?.subadminadd,
        attach: data?.subadminattach,
        create: data?.subadmincreate,
        delete: data?.subadmindelete,
        list: data?.subadminlist,
        print: data?.subadminprint,
        update: data?.subadminupdate,
        view: data?.subadminview,
      },
      terminals: {
        add: data?.terminalsadd,
        attach: data?.terminalsattach,
        create: data?.terminalscreate,
        delete: data?.terminalsdelete,
        list: data?.terminalslist,
        print: data?.terminalsprint,
        update: data?.terminalsupdate,
        view: data?.terminalsview,
      },
      transactions: {
        add: data?.transactionsadd,
        attach: data?.transactionsattach,
        create: data?.transactionscreate,
        delete: data?.transactionsdelete,
        list: data?.transactionslist,
        print: data?.transactionsprint,
        update: data?.transactionsupdate,
        view: data?.transactionsview,
      },
      users: {
        add: data?.usersadd,
        attach: data?.usersattach,
        create: data?.userscreate,
        delete: data?.usersdelete,
        list: data?.userslist,
        print: data?.usersprint,
        update: data?.usersupdate,
        view: data?.usersview,
      },
      vas: {
        add: data?.vasadd,
        attach: data?.vasattach,
        create: data?.vascreate,
        delete: data?.vasdelete,
        list: data?.vaslist,
        print: data?.vasprint,
        update: data?.vasupdate,
        view: data?.vasview,
      },
      wallets: {
        add: data?.walletsadd,
        attach: data?.walletsattach,
        create: data?.walletscreate,
        delete: data?.walletsdelete,
        list: data?.walletslist,
        print: data?.walletsprint,
        update: data?.walletsupdate,
        view: data?.walletsview,
      },
    };

    setLoading(true);
    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/rolesAndPermissions/permissions/savePermission',
      data: input,
    })
      .then(response => {
        setLoading(false);
        navigate('/roles/list-of-roles');
      })
      .catch(err => {
        setLoading(false);
      });
  };

  return (
    <Box
      display="flex"
      flexDirection={['column', 'column', 'column', 'column', 'row']}
      gap="3"
      h={['100%', '100vh']}
      w="full"
    >
      <Box
        w={['100%', '100%', '100%', '35%']}
        display="flex"
        h="75vh"
        justifyContent="center"
        alignItems="center"
        mt={['0', '-5rem']}
        borderRight="1px solid #E4ECF7"
      >
        <HStack gap="6">
          <Image src="assets/perm.png" alt="" w="24px" h="24px" />
          <Text fontWeight="500" color="#6500E0" fontSize="16px">
            Permissions
          </Text>
        </HStack>
      </Box>
      <Box
        w={['100%', '100%', '100%', '65%']}
        display="flex"
        h="100%"
        p="2rem"
        alignItems="flex-start !important"
        flexDirection="column"
      >
        <Link to="/roles" style={{ fontSize: '14px', fontWeight: '400' }}>
          Back
        </Link>
        <HStack mt="1.5rem" gap="6">
          <Text fontWeight="400" color=" #191716" fontSize="20px">
            Create Permissions
          </Text>
        </HStack>
        <form style={{ width: '100%' }} onSubmit={handleSubmit(handlesave)}>
          <Box
            alignItems="flex-end"
            justifyContent="center"
            w="full"
            gap="2"
            mr="3rem"
            display="flex"
          >
            <VStack alignItems="flex-start">
              <Text fontSize="13px" fontWeight="300" color="#454D54">
                Title
              </Text>
              <Input
                placeholder="Account Admin"
                border=" 1px solid #E3E5E8"
                borderRadius="4px"
                color="#000"
                value={title}
                disabled
                w="full"
                fontSize="14px"
                outline="none"
                type="text"
                _focus={{ outline: 'none' }}
                _active={{ outline: 'none' }}
              />
            </VStack>

            <Box>
              <Checkbox
                fontSize="13px"
                fontWeight="400"
                color="#ED6A5A"
                bg="#FCEAE8"
                borderRadius="4px"
                p=".46rem 1rem"
              >
                Allow all permissions
              </Checkbox>
            </Box>
            <Center w="full">
              {loading ? (
                <Button
                  isLoading
                  mt="2rem"
                  w="full"
                  align="center"
                  p="1rem"
                  borderRadius="4px"
                  role="group"
                  cursor="pointer"
                  _hover={{
                    bg: '#5403b8',
                    color: 'white',
                  }}
                  loadingText="Loading"
                  colorScheme="#6500E0"
                  variant="outline"
                />
              ) : (
                <Button
                  bg="#6500E0"
                  color="#fff"
                  align="center"
                  p=".46rem 2rem"
                  borderRadius="4px"
                  role="group"
                  cursor="pointer"
                  _hover={{
                    bg: '#5403b8',
                    color: 'white',
                  }}
                  type="submit"
                >
                  Save
                </Button>
              )}
            </Center>
          </Box>
          <TableContainer mt="1rem" w="full" fontSize="13px">
            <Table variant="simple">
              <Thead bg="#F3F7FC">
                <Tr>
                  <Th>Feature</Th>
                  <Th>View</Th>
                  <Th>Update</Th>
                  <Th>Delete</Th>
                  <Th>Create</Th>
                  <Th>List</Th>
                  <Th>Print</Th>
                  <Th>Add</Th>
                  <Th>Attach</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>Transactions</Td>
                  <Td>
                    <Checkbox {...register('transactionsview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('transactionsupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('transactionsdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('transactionscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('transactionslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('transactionsprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('transactionsadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('transactionsattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Providers</Td>
                  <Td>
                    <Checkbox {...register('providersview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('providersupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('providersdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('providerscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('providerslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('providersprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('providersadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('providersattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Organizations</Td>
                  <Td>
                    <Checkbox {...register('organizationsview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('organizationsupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('organizationsdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('organizationscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('organizationslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('organizationsprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('organizationsadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('organizationsattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Aggregators</Td>
                  <Td>
                    <Checkbox {...register('aggregatorsview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('aggregatorsupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('aggregatorsdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('aggregatorscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('aggregatorslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('aggregatorsprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('aggregatorsadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('aggregatorsattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Agents</Td>
                  <Td>
                    <Checkbox {...register('agentsview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('agentsupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('agentsdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('agentscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('agentslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('agentsprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('agentsadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('agentsattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Role</Td>
                  <Td>
                    <Checkbox {...register('roleview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('roleupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('roledelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('rolecreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('rolelist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('roleprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('roleadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('roleattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Settlement</Td>
                  <Td>
                    <Checkbox {...register('settlementsview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('settlementsupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('settlementsdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('settlementscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('settlementslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('settlementsprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('settlementsadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('settlementsattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Vas</Td>
                  <Td>
                    <Checkbox {...register('vasview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('vasupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('vasdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('vascreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('vaslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('vasprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('vasadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('vasattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Wallet</Td>
                  <Td>
                    <Checkbox {...register('walletsview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('walletsupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('walletsdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('walletscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('walletslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('walletsprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('walletsadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('walletsattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Users</Td>
                  <Td>
                    <Checkbox {...register('usersview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('usersupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('usersdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('userscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('userslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('usersprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('usersadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('usersattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Terminals</Td>
                  <Td>
                    <Checkbox {...register('terminalsview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('terminalsupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('terminalsdelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('terminalscreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('terminalslist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('terminalsprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('terminalsadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('terminalsattach')}></Checkbox>
                  </Td>
                </Tr>
                <Tr>
                  <Td>Sub Admin</Td>
                  <Td>
                    <Checkbox {...register('subadminview')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('subadminupdate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('subadmindelete')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('subadmincreate')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('subadminlist')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('subadminprint')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('subadminadd')}></Checkbox>
                  </Td>
                  <Td>
                    <Checkbox {...register('subadminattach')}></Checkbox>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </form>
      </Box>
    </Box>
  );
};

export default Permissions;
