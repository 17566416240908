import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

function EditMerchant() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Image src="/assets/updateprofile.png" />}
        bg="#E9FBF9"
        px={2.5}
        borderRadius="8px"
        color="#136F63"
        variant="solid"
      >
        Update Profile
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Edit Merchant
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <Box
              mt="1rem"
              p="1rem"
              display="flex"
              flexDirection="column"
              gap="3"
            >
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Name
                </Text>
                <Input
                  placeholder=""
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  color="#fff"
                  fontSize="14px"
                  outline="none"
                  type="text"
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                />
              </VStack>
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Email
                </Text>
                <Input
                  placeholder=""
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  color="#fff"
                  fontSize="14px"
                  outline="none"
                  type="mail"
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                />
              </VStack>
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Phone Number
                </Text>
                <Input
                  placeholder=""
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  color="#fff"
                  fontSize="14px"
                  outline="none"
                  type="text"
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                />
              </VStack>
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Account Number
                </Text>
                <Input
                  placeholder=""
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  color="#fff"
                  fontSize="14px"
                  outline="none"
                  type="text"
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                />
              </VStack>
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Bank
                </Text>
                <Select
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  fontSize="13px"
                  placeholder="Select Bank"
                >
                  <InputGroup>
                    <Input fontSize="13px" placeholder="Search All" />
                    <InputRightElement
                      children={<Image src="assets/search-normal.png" alt="" />}
                    />
                  </InputGroup>
                  <option value="option1">Option 1</option>
                  <option value="option2">Option 2</option>
                  <option value="option3">Option 3</option>
                </Select>
              </VStack>
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Charge
                </Text>
                <Input
                  placeholder=""
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  color="#fff"
                  fontSize="14px"
                  outline="none"
                  type="text"
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                />
              </VStack>
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Band
                </Text>
                <Input
                  placeholder=""
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  color="#fff"
                  fontSize="14px"
                  outline="none"
                  type="text"
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                />
              </VStack>
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Support Staff
                </Text>
                <Input
                  placeholder=""
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  color="#fff"
                  fontSize="14px"
                  outline="none"
                  type="text"
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                />
              </VStack>
              <Button
                border="none"
                borderRadius="12px"
                w="full"
                h="55px"
                color="#fff"
                bg="#6500E0"
              >
                Confirm
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default EditMerchant;
