/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  CircularProgress,
  HStack,
  Image,
  Skeleton,
  Spinner,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';
import AddMerchantFilter from '../BusinessMerchant/AddMerchantFilter';
import AddMerchantTable from '../BusinessMerchant/AddMerchantTable';
import DenyMerchantModal from './DenyMerchantModal';

const MerchantDetail = () => {
  const { userEmail } = useParams();
  const [confirm, setConfirm] = React.useState(false);
  const [res, setRes] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [confirmLoading, setconfirmLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loadings, setLoadings] = React.useState(false);
  const [val, setVal] = React.useState(false);
  const [text, setText] = React.useState(false);
  const [errBvn, setErrBvn] = React.useState(false);
  const [errNin, setErrNin] = React.useState(false);
  const [loadingNin, setLoadingNin] = React.useState(false);
  const [Ninres, setNinRes] = React.useState(false);

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const getUserDetalsUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/checker/getUserDetals?userName=${userEmail}`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/checker/getUserDetals?userName=${userEmail}`;

  const getBvnDetailsUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/checker/getBvnDetails?bvn=${val?.bvnNumber}`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/checker/getBvnDetails?bvn=${val?.bvnNumber}`;

  const getninDetailsUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/checker/getNINDetails?bvn=${val?.ninNumber}`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/checker/getNINDetails?nin=${val?.ninNumber}`;

  const approveUrl =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/merchant/approve'
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/signup/merchant/approve`;

  const usersInformationUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/signup/usersInformation?userName=${userEmail}`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/usersInformation?userName=${userEmail}`;

  const validate = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getUserDetalsUrl,
    })
      .then(response => {
        setLoading(false);
        setVal(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const confirmation = () => {
    setconfirmLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getBvnDetailsUrl,
    })
      .then(response => {
        setconfirmLoading(false);
        setSuccess(true);
        setConfirm(true);
        setRes(response.data);
      })
      .catch(err => {
        setconfirmLoading(false);
        setErrBvn(err.response.data.message.message);
      });
  };

  const confirmedNin = () => {
    setLoadingNin(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: getninDetailsUrl,
    })
      .then(response => {
        setLoadingNin(false);
        setNinRes(response.data);
      })
      .catch(err => {
        setLoadingNin(false);
        setErrNin(err.response.data.message.message);
      });
  };

  let toast = useToast();

  const approve = event => {
    event.preventDefault();

    const merchant = {
      approved: true,
      merchantUserName: val?.email,
      reason: '',
      userFirstName: val?.firstName,
    };

    setLoadings(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: approveUrl,
      data: merchant,
    })
      .then(response => {
        setLoadings(false);
        setText(true);
      })
      .catch(err => {
        toast({
          title: 'Merchant not approved!',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoadings(false);
      });
  };

  const [stat, setStat] = React.useState();
  const statuss = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: usersInformationUrl,
    })
      .then(response => {
        setLoading(false);
        setStat(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    statuss();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const navigate = useNavigate();

  return (
    <Box w="full">
      <button
        onClick={() => navigate(-1)}
        style={{ fontSize: '14px', fontWeight: '300' }}
        tw="mb-4 hover:underline"
      >
        Back
      </button>
      <Box
        display="flex"
        gap={['3', '0']}
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        w="full"
        mt="1rem"
      >
        <HStack float="left" gap="3">
          <Image src="/assets/merchantframe.png" alt="" />
          <VStack alignItems="flex-start">
            <Text fontWeight="600" fontSize="24px">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.firstName} &nbsp;
              {loading ? <Skeleton w="100%" h="20px" /> : val?.lastName}
            </Text>
            <Text
              padding="4px"
              borderRadius="4"
              bg={
                val?.status?.toLowerCase() === 'active'
                  ? '#E9FBF9'
                  : val?.status?.toLowerCase() === 'unapproved'
                  ? '#E4ECF7'
                  : '#FCEAE8'
              }
              fontWeight="400"
              fontSize="10px"
              color={
                val?.status?.toLowerCase() === 'active'
                  ? '#136F63'
                  : val?.status?.toLowerCase() === 'unapproved'
                  ? '#A6B7D4'
                  : '#EDA95A'
              }
            >
              {val?.status}
            </Text>
          </VStack>
        </HStack>
        <Box
          display="flex"
          flexDirection={['column', 'row']}
          gap="3"
          float="right"
        >
          {loading ? null : val?.status?.toLowerCase() === 'active' ? (
            <Button
              bg="#136F63"
              w="182px"
              h="42px"
              borderRadius="8px"
              color="#fff"
              variant="solid"
            >
              Approved
            </Button>
          ) : val?.status?.toLowerCase() === 'rejected' ? (
            <div tw="space-x-4">
              <Link
                to={`/business-merchant/add-merchant?edit=true&username=${val?.email}`}
              >
                <Button
                  bg="#6500E0"
                  w="182px"
                  h="42px"
                  borderRadius="8px"
                  color="#fff"
                  variant="solid"
                >
                  Edit
                </Button>
              </Link>
              <Button
                bg="#136F63"
                w="182px"
                h="42px"
                borderRadius="8px"
                color="#fff"
                variant="solid"
              >
                Denied Approval
              </Button>
            </div>
          ) : (
            <>
              {confirmLoading ? (
                <Button
                  bg="#6500E0"
                  w="182px"
                  h="42px"
                  borderRadius="8px"
                  color="#fff"
                  onClick={() => {
                    confirmation();
                    confirmedNin();
                  }}
                  variant="solid"
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  bg="#6500E0"
                  w="182px"
                  h="42px"
                  borderRadius="8px"
                  color="#fff"
                  onClick={e => confirmation(e)}
                  variant="solid"
                >
                  {confirm ? 'Confirmed' : 'Confirm'}
                </Button>
              )}
              {loadings ? (
                <Button
                  bg="#136F63"
                  w="182px"
                  h="42px"
                  borderRadius="8px"
                  color="#fff"
                  onClick={approve}
                  variant="solid"
                >
                  <Spinner />
                </Button>
              ) : (
                <Button
                  bg="#136F63"
                  w="182px"
                  h="42px"
                  disabled={success ? false : true}
                  borderRadius="8px"
                  color="#fff"
                  onClick={e => approve(e)}
                  variant="solid"
                >
                  {text ? 'Approved' : 'Approve account'}
                </Button>
              )}
              <DenyMerchantModal
                merchantUserName={val?.email}
                merchatFirstName={val?.firstName}
              />
            </>
          )}
        </Box>
      </Box>
      <Box
        border="1px solid #E4ECF7"
        p="1rem"
        w="full"
        gap={['3', '0']}
        h="100%"
        mt="2rem"
        display="flex"
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        borderRadius="8px"
      >
        <VStack gap="3" w={['full', '50%']} alignItems="flex-start">
          <Text color=" #16192C" mb="1rem" fontSize="1rem" fontWeight="500">
            Information Given
          </Text>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              First Name
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.firstName}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Email
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.email}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Gender
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.gender}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Date of Birth
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.dateOfBirth}
            </Text>
          </HStack>
        </VStack>
        <VStack gap="3" w={['full', '50%']} alignItems="flex-start">
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Last Name
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.lastName}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Phone
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.phone}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Address
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.address}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              State
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.state}
            </Text>
          </HStack>
        </VStack>
      </Box>
      <Box
        border="1px solid #E4ECF7"
        p="1rem"
        w="full"
        gap={['3', '0']}
        h="100%"
        mt="2rem"
        display="flex"
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        borderRadius="8px"
      >
        <VStack gap="3" w={['full', '50%']} alignItems="flex-start">
          <Text color=" #16192C" mb="1rem" fontSize="1rem" fontWeight="500">
            BVN Information
          </Text>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              First Name
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.firstName}
            </Text>
            <Text
              color={res?.firstName === val?.firstName ? '#136F63' : '#ff0000'}
              fontSize="1rem"
              fontWeight="500"
            >
              {confirmLoading ? (
                <Skeleton w="100%" h="20px" />
              ) : (
                res?.firstName || errBvn
              )}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Gender
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.gender}
            </Text>
            <Text
              color={res?.gender === val?.gender ? '#136F63' : '#ff0000'}
              fontSize="1rem"
              fontWeight="500"
            >
              {confirmLoading ? <Skeleton w="100%" h="20px" /> : res?.gender}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              BVN
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.bvnNumber}
            </Text>
            <Text
              color={!res?.message ? '#136F63' : '#ff0000'}
              fontSize="1rem"
              fontWeight="500"
            >
              {confirmLoading ? <CircularProgress /> : res?.message}
            </Text>
          </HStack>
        </VStack>
        <VStack gap="3" w={['full', '50%']} alignItems="flex-start">
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Last Name
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.lastName}
            </Text>
            <Text
              color={res?.lastName === val?.lastName ? '#136F63' : '#ff0000'}
              fontSize="1rem"
              fontWeight="500"
            >
              {confirmLoading ? <Skeleton w="100%" h="20px" /> : res?.lastName}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Phone
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.phone}
            </Text>
            <Text
              color={res?.phoneNumber === val?.phone ? '#136F63' : '#ff0000'}
              fontSize="1rem"
              fontWeight="500"
            >
              {loading ? <Skeleton w="100%" h="20px" /> : res?.phoneNumber}
            </Text>
          </HStack>
        </VStack>
      </Box>
      <Box
        border="1px solid #E4ECF7"
        p="1rem"
        w="full"
        gap={['3', '0']}
        h="100%"
        mt="2rem"
        display="flex"
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        borderRadius="8px"
      >
        <VStack gap="3" w={['full', '50%']} alignItems="flex-start">
          <Text color=" #16192C" mb="1rem" fontSize="1rem" fontWeight="500">
            NIN Information
          </Text>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              First Name
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.firstName}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Gender
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.gender}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              NIN
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.ninNumber}
            </Text>
            <Text color="#136F63" fontSize="1rem" fontWeight="500">
              {loadingNin ? (
                <Skeleton w="100%" h="20px" />
              ) : (
                Ninres?.message || errNin
              )}
            </Text>
          </HStack>
        </VStack>
        <VStack gap="3" w={['full', '50%']} alignItems="flex-start">
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Last Name
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.lastName}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Phone
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.phone}
            </Text>
          </HStack>
        </VStack>
      </Box>
      <Box
        border="1px solid #E4ECF7"
        p="1rem"
        w="full"
        h="100%"
        mt="2rem"
        display="flex"
        gap={['3', '0']}
        flexDirection={['column', 'row']}
        alignItems="center"
        justifyContent="space-between"
        borderRadius="8px"
      >
        <VStack gap="3" w={['full', '50%']} alignItems="flex-start">
          <Text color=" #16192C" mb="1rem" fontSize="1rem" fontWeight="500">
            Valid Information
          </Text>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              First Name
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.firstName}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Gender
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.gender}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              NIN
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.ninNumber}
            </Text>
          </HStack>
        </VStack>
        <VStack gap="3" w={['full', '50%']} alignItems="flex-start">
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Last Name
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.lastName}
            </Text>
          </HStack>
          <HStack>
            <Text color="#505780" fontSize="1rem" fontWeight="400">
              Phone
            </Text>
            <Text color="#16192C" fontSize="1rem" fontWeight="500">
              {loading ? <Skeleton w="100%" h="20px" /> : val?.phone}
            </Text>
          </HStack>
        </VStack>
      </Box>
      <Text
        mt="2rem"
        color=" #16192C"
        mb="1rem"
        fontSize="1rem"
        fontWeight="500"
      >
        File Upload
      </Text>
      {/* <AddMerchantFilter /> */}
      <AddMerchantTable userEmail={userEmail} />
    </Box>
  );
};

export default MerchantDetail;
