import React from 'react';
import { Chart } from 'react-google-charts';

export const data = [
  ['Year', 'Sales'],
  ['2004', 400],
  ['2005', 300],
  ['2006', 100],
  ['2007', 0],
];

export const options = {
  curveType: 'function',
  legend: { position: 'bottom' },
};

export default function ApexChart() {
  return (
    <Chart
      chartType="LineChart"
      width='100%'
      height="400px"
      data={data}
      options={options}
    />
  );
}
