import { Box, Text } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
//import FilterTerminal from './FilterTerminal'
import FilterMerchant from './FilterMerchant';
import MerchantList from './MerchantList';
import AddTerminalModal from './Modals/AddTerminalModal';

const TerminalMerchant = () => {
  const navigate = useNavigate();

  return (
    <Box>
      <button
        onClick={() => navigate(-1)}
        style={{ fontSize: '14px', fontWeight: '300' }}
        tw="mb-4 hover:underline"
      >
        Back
      </button>
      <Box
        w="full"
        h="100%"
        mt="1rem"
        display="flex"
        justifyContent="space-between"
      >
        <Text float="left" fontWeight="700" fontSize="32px">
          Terminals per Merchant
        </Text>
        <Box display="flex" flexDirection={['column', 'row']} gap="6">
          <AddTerminalModal />
        </Box>
      </Box>
      <Box
        mt="1rem"
        display="flex"
        gap={['3', '3']}
        flexDirection={['column', 'row']}
      >
        <Box w={['full', '100%']}>
          <FilterMerchant />
          <MerchantList />
        </Box>
      </Box>
    </Box>
  );
};

export default TerminalMerchant;
