/** @jsxImportSource @emotion/react */
import moment from 'moment';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import useSWR from 'swr';
import tw from 'twin.macro';
import numberFormatter from '../../utils/numberFormatter';
import { swrfetcher } from '../../utils/swrFetcher';
import TransactionCard from '../Common/TransactionCard';

const Overview = () => {
  const [day, setDay] = React.useState('Today');
  const [date, setDate] = React.useState([moment().format('yyyy-MM-DD'), null]);

  React.useEffect(() => {
    if (day === 'Today') {
      setDate([moment().startOf('day').format('yyyy-MM-DD'), null]);
    } else if (day === 'Yesterday') {
      setDate([
        moment().subtract(1, 'days').startOf('day').format('yyyy-MM-DD'),
        null,
      ]);
    } else if (day === 'This Week') {
      setDate([moment().startOf('week').format('yyyy-MM-DD'), null]);
    } else if (day === 'This Month') {
      setDate([moment().startOf('month').format('yyyy-MM-DD'), null]);
    } else if (day === 'Last Month') {
      setDate([
        moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      ]);
    } else {
      setDate([moment().startOf('year').format('YYYY-MM-DD'), null]);
    }
  }, [day]);

  /* SWR URLs */
  const PosUrl = `/apis/v1/bizzdesk/admin/transaction/POS/getMetrics?startDate=${
    date[0]
  }${!date[1] ? '' : `&endDate=${date[1]}`}`;
  const walletUrl = `/apis/v1/bizzdesk/admin/transaction/WalletWithdrawal/getMetrics?startDate=${
    date[0]
  }${!date[1] ? '' : `&endDate=${date[1]}`}`;
  const receivableUrl = `/apis/v1/bizzdesk/admin/transaction/Receivable/getMetrics?startDate=${
    date[0]
  }${!date[1] ? '' : `&endDate=${date[1]}`}`;
  const bizzDeskUrl = `/apis/v1/bizzdesk/admin/transaction/Income/getMetrics?startDate=${
    date[0]
  }${!date[1] ? '' : `&endDate=${date[1]}`}`;

  /* User_token getter */
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  /* SWR call functions */
  const { data: PosData } = useSWR(
    [PosUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: Walletata } = useSWR(
    [walletUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: receivableData } = useSWR(
    [receivableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: bizzDeskData } = useSWR(
    [bizzDeskUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  /* Data Arrays */
  const transactiionData = [
    {
      amount: (
        <CurrencyFormat
          value={PosData?.totalSuccessTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total successful transactions',
      value: numberFormatter(PosData?.totalSuccessTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={PosData?.totalFailedTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total failed transactions',
      value: numberFormatter(PosData?.totalFailedTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={PosData?.totalPendingTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total Pending transactions',
      value: numberFormatter(PosData?.totalPendingTransactionsCount),
    },
  ];

  const walletWithdrawalData = [
    {
      amount: (
        <CurrencyFormat
          value={Walletata?.totalSuccessTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total successful transactions',
      value: numberFormatter(Walletata?.totalSuccessTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={Walletata?.totalFailedTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total failed transactions',
      value: numberFormatter(Walletata?.totalFailedTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={Walletata?.totalPendingTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total Pending transactions',
      value: numberFormatter(Walletata?.totalPendingTransactionsCount),
    },
  ];

  const receivableDataArray = [
    {
      amount: (
        <CurrencyFormat
          value={receivableData?.totalSuccessTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total successful transactions',
      value: numberFormatter(receivableData?.totalSuccessTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={receivableData?.totalFailedTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total failed transactions',
      value: numberFormatter(receivableData?.totalFailedTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={receivableData?.totalPendingTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total Pending transactions',
      value: numberFormatter(receivableData?.totalPendingTransactionsCount),
    },
  ];

  const bizzDeskDataArray = [
    {
      amount: (
        <CurrencyFormat
          value={bizzDeskData?.totalSuccessTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total successful transactions',
      value: numberFormatter(bizzDeskData?.totalSuccessTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={bizzDeskData?.totalFailedTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total failed transactions',
      value: numberFormatter(bizzDeskData?.totalFailedTransactionsCount),
    },
    {
      amount: (
        <CurrencyFormat
          value={bizzDeskData?.totalPendingTransactionsAmount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
      title: 'Total Pending transactions',
      value: numberFormatter(bizzDeskData?.totalPendingTransactionsCount),
    },
  ];

  return (
    <>
      <div>
        <div tw="flex justify-between items-center">
          <Title className="font-500">POS Transactions</Title>
        </div>

        <div tw="grid grid-cols-2 gap-3 md:grid-cols-3 xl:( gap-5) mt-5">
          {transactiionData.map(({ amount, value, title }, index) => {
            return (
              <TransactionCard
                key={index}
                title={title}
                amount={amount}
                value={value}
                isValidating={PosData}
                hasFilter={index === 0 && day}
                setFilter={index === 0 && setDay}
              />
            );
          })}
        </div>
      </div>

      <div tw="mt-10">
        <Title className="font-500">Wallet Withdrawal</Title>

        <div tw="grid grid-cols-2 gap-3 md:grid-cols-3 xl:( gap-5) mt-5">
          {walletWithdrawalData.map(({ amount, value, title }, index) => {
            return (
              <TransactionCard
                key={index}
                title={title}
                amount={amount}
                value={value}
                isValidating={Walletata}
              />
            );
          })}
        </div>
      </div>

      <div tw="mt-10">
        <Title className="font-500">Receivable Transaction</Title>

        <div tw="grid grid-cols-2 gap-3 md:grid-cols-3 xl:( gap-5) mt-5">
          {receivableDataArray.map(({ amount, value, title }, index) => {
            return (
              <TransactionCard
                key={index}
                title={title}
                amount={amount}
                value={value}
                isValidating={receivableData}
              />
            );
          })}
        </div>
      </div>

      <div tw="my-10">
        <Title className="font-500">Paysure Income</Title>

        <div tw="grid grid-cols-2 gap-3 md:grid-cols-3 xl:( gap-5) mt-5">
          {bizzDeskDataArray.map(({ amount, value, title }, index) => {
            return (
              <TransactionCard
                key={index}
                title={title}
                amount={amount}
                value={value}
                isValidating={bizzDeskData}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

const Title = tw.h3`tracking-[-0.02em] text-black`;

export default Overview;
