import {
  Box,
  Button,
  Center,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  // Select,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

function DenyMerchantModal({ merchantUserName, merchatFirstName }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  //   validation

  const validationSchema = Yup.object().shape({
    reason: Yup.string().required('Tis Field is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const approveUrl =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/merchant/approve'
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/signup/merchant/approve`;

  const deny = (data, event) => {
    event.preventDefault();

    const merchant = {
      approved: false,
      merchantUserName: merchantUserName,
      reason: data.reason,
      userFirstName: merchatFirstName,
    };

    setLoading(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: approveUrl,
      data: merchant,
    })
      .then(response => {
        toast({
          // title: 'Failed!',
          position: 'top',
          description: 'Merchant Denied Successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
      })
      .catch(err => {
        toast({
          title: 'Failed!',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg="#ED6A5A"
        w="122px"
        h="42px"
        borderRadius="8px"
        color="#fff"
        variant="solid"
      >
        Deny approval
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Reasons for Denial
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <form onSubmit={handleSubmit(deny)}>
              <Box
                mt="1rem"
                p="1rem"
                display="flex"
                flexDirection="column"
                gap="3"
                w="full"
              >
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Reason
                  </Text>
                  <Textarea
                    placeholder="Note"
                    border={
                      errors.reason ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#000"
                    fontSize="14px"
                    outline="none"
                    id="email"
                    type="text"
                    {...register('reason')}
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.reason?.message}
                  </Text>
                </VStack>

                <Center w="full">
                  {loading ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Done
                    </Button>
                  )}
                </Center>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DenyMerchantModal;
