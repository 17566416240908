/** @jsxImportSource @emotion/react */
import { Box, HStack, Text } from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';
// import DataGridViewTemp from '../Common/DataGridViewTemp';
import { Link } from 'react-router-dom';
import WalletBalance from '../Common/WalletBalance';

const Overview = () => {
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const url =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/wallet/analytics/getAmountInWallets'
      : '/apis/bizzdesk/organization/wallet/getIncomeWalletBalance';

  const { data } = useSWR([url, token, 'GET'], ([url, USER_TOKEN, method]) =>
    swrfetcher(url, USER_TOKEN, method)
  );

  console.log(data);

  // let rows = [];
  // if (tableData) {
  //   if (tableData?.status >= 400) {
  //     rows = [];
  //   } else {
  //     rows = tableData?.map((item, index) => {
  //       return {
  //         id: index + 1,
  //         fullName: item?.fullName,
  //         email: item?.email,
  //         phoneNumber: item?.phoneNumber,
  //         bvn: item?.bvn,
  //         businessName: item?.businessName,
  //         businessAddress: item?.businessAddress,
  //         dateAdded: item?.dateAdded,
  //         status: item?.status,
  //         action: {
  //           link: `/business-merchant/merchant-details/${item?.email}`,
  //         },
  //       };
  //     });
  //   }
  // }

  // const TransWalletColumn = [
  //   {
  //     headerName: 'S/N',
  //   },
  //   {
  //     headerName: 'full name',
  //   },
  //   {
  //     headerName: 'email',
  //   },
  //   {
  //     headerName: 'phone number',
  //   },
  //   {
  //     headerName: 'bvn',
  //   },
  //   {
  //     headerName: 'business name',
  //   },
  //   {
  //     headerName: 'business address',
  //   },
  //   {
  //     headerName: 'date added',
  //   },
  //   {
  //     headerName: 'status',
  //   },
  //   {
  //     headerName: 'Action',
  //   },
  // ];

  return (
    <>
      <Box
        w="full"
        h={['100%', '163px']}
        p={['1rem', '0']}
        borderRadius="20px"
        bg="#EBF2FA"
        position="relative"
        mt="2rem"
      >
        <WalletBalance
          label="BBM POS Sales/Verification Wallet Balance"
          balance={data?.posSalesWallet}
          data={data}
          hasNoFundWallet={userType === 'admin' ? false : true}
          // hasNoWithdraw
        />
      </Box>
      {/* <DataGridViewTemp
        link="/sign-up/new-signup-list"
        limited
        title="List of New Signups"
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
      /> */}

      <>
        <Box
          display="flex"
          gap="6"
          justifyContent="space-between"
          w="full"
          flexDirection={['row', 'row']}
          mt="2rem"
        >
          <Text fontWeight="500" color="#191716" float="left">
            Pos Transactions Wallet
          </Text>
          <HStack float="right">
            <Link style={{ color: '#6500E0' }} href="/wallet/pos-wallet">
              View All
            </Link>
          </HStack>
        </Box>
        {/* <PosWallet />; */}
      </>
    </>
  );
};

export default Overview;
