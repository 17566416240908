import { Box, Grid, HStack, Image, Link, Text } from '@chakra-ui/react';
import React from 'react';
//import { Link } from 'react-router-dom';
import axios from 'axios';
import Stick from '../Common/Stick';
import AddRoleModal from './Modal/AddRoleModal';

const Roles = () => {
  const [loading, setLoading] = React.useState(false);
  const [val, setVal] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  const validate = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/rolesAndPermissions/roles/getAllRoles',
    })
      .then(response => {
        setLoading(false);
        setVal(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box
        w="full"
        h="100%"
        mt="1rem"
        display="flex"
        justifyContent="space-between"
      >
        <Text flat="left" fontWeight="700" fontSize="32px">
          Roles & Permissions
        </Text>
      </Box>
      <Grid
        mt="1rem"
        templateColumns={[
          'repeat(1,1fr)',
          'repeat(2,1fr)',
          'repeat(2,1fr)',
          'repeat(4,1fr)',
        ]}
        gap="6"
      >
        {loading ? (
          <>
            <Stick />
            <Stick />
            <Stick />
          </>
        ) : Array?.isArray(val) ? (
          val?.map(e => {
            return (
              <Link
                href="/roles/details"
                display="flex"
                textDecoration="none"
                _hover={{ textDecoration: 'none' }}
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                w={['100%', '257px']}
                h="100%"
                p="1rem"
                borderRadius="8px"
                bg="whitesmoke"
                border="1px solid #E4ECF7"
                gap="2"
              >
                <Text fontWeight="500" color="#191716" fontSize="24px">
                  {loading ? 'Administrator (ADM)' : e?.roleTitle}
                </Text>
                <Text
                  bg="whitesmoke"
                  mt="1rem"
                  fontWeight="400"
                  color="#425D8A"
                  borderRadius="4px"
                  fontSize="16px"
                >
                  3 Accounts
                </Text>
                <HStack gap="-1">
                  <Image src="assets/1.png" alt="" w="24px" h="24px" />
                  <Image src="assets/2.png" alt="" w="24px" h="24px" />
                  <Image src="assets/3.png" alt="" w="24px" h="24px" />
                </HStack>
              </Link>
            );
          })
        ) : null}
        <AddRoleModal />
      </Grid>
    </Box>
  );
};

export default Roles;
