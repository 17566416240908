import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Checkbox,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import * as yup from 'yup';

const Login = () => {
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [rememberMe, setRememberMe] = React.useState(false);

  let navigate = useNavigate();
  let toast = useToast();
  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    password: yup.string().required('Password is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  //  end of validation

  React.useEffect(() => {
    if (localStorage.getItem('token') || sessionStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, [navigate]);

  const validate = (data, event) => {
    event.preventDefault();
    const input = {
      username: data?.email,
      password: data?.password,
    };

    setLoading(true);

    axios({
      method: 'post',
      responseType: 'json',
      url: 'https://bbm.bizzdesk.ng:8080/authenticate',
      data: input,
    })
      .then(response => {
        // console.log('>>>>>>>dfef>>>>', response);
        setLoading(false);

        if (rememberMe) {
          localStorage.setItem(
            'token',
            JSON.stringify(response?.data?.jwtToken)
          );
          localStorage.setItem(
            'userType',
            JSON.stringify(response?.data?.userType)
          );
          localStorage.setItem(
            'businessName',
            JSON.stringify(response?.data?.businessName)
          );
        } else {
          sessionStorage.setItem(
            'token',
            JSON.stringify(response?.data?.jwtToken)
          );
          sessionStorage.setItem(
            'userType',
            JSON.stringify(response?.data?.userType)
          );
          sessionStorage.setItem(
            'businessName',
            JSON.stringify(response?.data?.businessName)
          );
        }

        toast({
          title: 'Login Successful.',
          position: 'top',
          description: 'You are now logged in.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/dashboard');
      })
      .catch(err => {
        if (err.response.data.message === 'Change password to continue') {
          navigate(`/create-password?email=${data?.email}&k=${data?.password}`);
          return;
        }

        toast({
          title: 'Failed.',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <Box
      w="full"
      h={['100%', '100vh']}
      display="flex"
      alignItems="center"
      justifyContent="base-start"
      flexDirection="column"
      mt="2rem"
    >
      <Image src="assets/logo_purple.png" alt="" />
      <Box
        mt="4rem"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text fontWeight="400" fontSize="24px">
          Login to your dashboard
        </Text>
        <form onSubmit={handleSubmit(validate)}>
          <VStack mt="3rem" alignItems="baseline">
            <Text fontSize="13px" color="#454D54">
              Email
            </Text>
            <Input
              h="42px"
              color="#191716"
              bg="#fff"
              placeholder="youremail@email.example"
              w={['100%', '308px']}
              borderRadius="4px"
              {...register('email')}
              border={errors.email ? '1px solid red' : ' 1px solid #E3E5E8'}
            />
            <Text color="red" mt=".5rem">
              {errors.email?.message}
            </Text>
          </VStack>
          <VStack mt="1rem" alignItems="baseline">
            <Text fontSize="13px" color="#454D54">
              Password
            </Text>
            <InputGroup size="md">
              <Input
                h="42px"
                bg="#fff"
                color="#191716"
                w={['100%', '308px']}
                borderRadius="4px"
                type={show ? 'text' : 'password'}
                placeholder="Enter password"
                {...register('password')}
                border={errors.password ? '1px solid red' : '1px solid #E3E5E8'}
                paddingRight={0}
              />
              <InputRightElement h={'100%'}>
                <Button h="100%" size="sm" onClick={handleClick}>
                  {show ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <Text color="red" mt=".5rem">
              {errors.password?.message}
            </Text>
          </VStack>
          <Box mt="1rem" display="flex" justifyContent="space-between" w="full">
            <HStack>
              <Checkbox
                borderRadius="50% !important"
                value={rememberMe}
                onChange={e => setRememberMe(e.target.checked)}
                colorScheme="purple"
              >
                <Text fontSize="13px" as="span">
                  Remember me
                </Text>
              </Checkbox>
            </HStack>
            <Link
              fontSize="13px"
              href="/recover-account"
              textDecoration="underline"
              color="#6500E0"
            >
              Forgotten password
            </Link>
          </Box>
          <Center w="full">
            <Button
              isLoading={loading}
              bg="#6500E0"
              mt="2rem"
              w="full"
              color="#fff"
              align="center"
              p="1rem"
              borderRadius="4px"
              role="group"
              cursor="pointer"
              _hover={{
                bg: '#5403b8',
                color: 'white',
              }}
              type="submit"
            >
              Login to your account
            </Button>
          </Center>
        </form>
      </Box>
    </Box>
  );
};

export default Login;
