/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import Sidebar from '../component/Common/Sidebar';
import Topnav from '../component/Common/Topnav';
import MerchantProfiles from '../component/BusinessMerchant/MerchantProfile';
// import {
//   Button,
//   HStack,
//   Image,
//   Skeleton,
//   Text,
//   useToast,
//   VStack,
// } from '@chakra-ui/react';
// import CurrencyFormat from 'react-currency-format';
// import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// import useSWR from 'swr';
// import OverviewCard from '../component/Common/OverviewCard';
// import WalletBalance from '../component/Common/WalletBalance';
// import EditMerchant from '../component/BusinessMerchant/Modals/EditMerchant';
// // eslint-disable-next-line no-unused-vars
// import axios from 'axios';
// import tw from 'twin.macro';
// import { swrfetcher } from '../utils/swrFetcher'; 
// import DatRangePickerAndOthers from '../component/Common/DataGridFunctionalities/DatRangePickerAndOthers'; 
// import FilterBox from '../component/Common/DataGridFunctionalities/FilterBox';
// import SearchBar from '../component/Common/DataGridFunctionalities/SearchBar';
// import DataGridViewTemp from '../component/Common/DataGridViewTemp';
// import EditTerminalModal from '../component/Terminals/Modals/EditTerminalModal';
// import ReassignTerminalModal from '../component/Terminals/Modals/ReassignTerminalModal';
// import ViewTerminal from '../component/Terminals/Modals/ViewTerminal';

const MerchantProfile = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <MerchantProfiles />
      </Box>
    </Box>
  );
};

export default MerchantProfile;
