import {
  Box,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { swrfetcher } from '../../../utils/swrFetcher';
import Pagination from '../../Common/Pagination';
import FilterMerchant from '../FilterMerchant';

const TableUI = ({
  id,
  fullname,
  email,
  phonenumber,
  bvn,
  businessname,
  businessaddress,
  dateadded,
  status,
  userEmail,
}) => {
  return (
    <Tr>
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {fullname}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {email}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {phonenumber}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {bvn}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {businessname}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {businessaddress}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {dateadded}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        <Text
          borderRadius="8px"
          p=".45rem"
          textAlign="center"
          bg={status === 'ACTIVE' ? '#E9FBF9' : '#FCEAE8'}
          color={status === 'ACTIVE' ? ' #136F63' : '#ED6A5A'}
        >
          {status}{' '}
        </Text>
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#6500E0" fontWeight="400">
        {' '}
        <Link to={`/business-merchant/merchant-details/${userEmail}`}>
          {' '}
          View{' '}
        </Link>
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
        {' '}
        Edit
      </Td>
    </Tr>
  );
};

const UnApprovedList = () => {
  const [loading, setLoading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = React.useState(1);
  // eslint-disable-next-line
  const [postPerPage, setPostPerPage] = React.useState(10);

  const unapprovedListUrl =
    '/apis/v1/bizzdesk/signup/agentsSignups/findAllByAggregatorsFullnameAndStatus?limit=2000&offset=0&status=rejected';
  const { data: val } = useSWR(
    [unapprovedListUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  // get current post
  const indexOfLastPage = currentPage * postPerPage;

  const indexOfFirstPage = indexOfLastPage - postPerPage;

  const serial = Array?.isArray(val)
    ? val?.map((data, index) => ({ ...data, serialno: index + 1 }))
    : null;
  const currentPosts = Array?.isArray(serial)
    ? serial?.slice(indexOfFirstPage, indexOfLastPage)
    : serial?.toString().slice(indexOfFirstPage, indexOfLastPage);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };

  // filter server data
  const [displayData, setDisplayData] = useState([]);
  const [firstDate, setFirstDate] = useState();
  const [search, setSearch] = useState();
  const [secondDate, setSecondDate] = useState();
  const [trues, setTrue] = useState(false);

  const filterTransactions = () => {
    setDisplayData(currentPosts);
    setTrue(true);
    // const firstDate = new Date().toISOString();
    // const secondDate = new Date().toISOString();

    const newData = val.filter(data => {
      const firstDateInNumber = new Date(firstDate).getTime();
      const secondDateInNumber = new Date(secondDate).getTime();
      const dataDate = new Date(data.date).getTime();

      if (
        (firstDateInNumber <= dataDate && secondDateInNumber <= dataDate) ||
        search === data.transType
      ) {
        return true;
      }
      return false;
    });
    setDisplayData(newData);
  };

  return (
    <Box>
      <FilterMerchant
        setSecondDate={setSecondDate}
        setFirstDate={setFirstDate}
        setSearch={setSearch}
        filterTransactions={filterTransactions}
      />
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                s/n
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                full name
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                email
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                phone number
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                bvn
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                business name
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                business address
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                date added
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                status
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                actions
              </Th>
            </Tr>
          </Thead>
          {trues ? (
            <Tbody>
              {loading ? (
                <Skeleton w="full" h="50%"></Skeleton>
              ) : Array?.isArray(val) ? (
                displayData.map((data, index) => (
                  <TableUI
                    key={index}
                    id={data.serialno}
                    fullname={data.fullName}
                    email={data.email}
                    phonenumber={data.nin}
                    bvn={data.bvn}
                    businessname={data.businessName}
                    businessaddress={data.businessAddress}
                    dateadded={data.dateAdded}
                    status={data.status}
                    userEmail={data.email}
                  />
                ))
              ) : null}
            </Tbody>
          ) : (
            <Tbody>
              {loading ? (
                <Skeleton w="full" h="50%"></Skeleton>
              ) : Array?.isArray(val) ? (
                currentPosts.map((data, index) => (
                  <TableUI
                    key={index}
                    id={data.serialno}
                    fullname={data.fullName}
                    email={data.email}
                    phonenumber={data.nin}
                    bvn={data.bvn}
                    businessname={data.businessName}
                    businessaddress={data.businessAddress}
                    dateadded={data.dateAdded}
                    status={data.status}
                    userEmail={data.email}
                  />
                ))
              ) : null}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      <Pagination
        postPerPage={postPerPage}
        paginate={paginate}
        totalPosts={val.length}
      />
    </Box>
  );
};

export default UnApprovedList;
