import {
  Box,
  Drawer,
  DrawerContent,
  HStack,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import { swrfetcher } from '../../utils/swrFetcher';
import Pagination from '../Common/Pagination';
import Sidebar from '../Common/Sidebar';
import Topnav from '../Common/Topnav';

const TableUI = ({
  id,
  fullname,
  email,
  phonenumber,
  bvn,
  businessname,
  businessaddress,
  dateadded,
  status,
  userEmail,
}) => {
  return (
    <Tr>
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {fullname}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {email}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {phonenumber}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {bvn}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {businessname}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {businessaddress}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {dateadded}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        <Text
          borderRadius="8px"
          p=".45rem"
          textAlign="center"
          bg={status === 'ACTIVE' ? '#E9FBF9' : '#FCEAE8'}
          color={status === 'ACTIVE' ? ' #136F63' : '#ED6A5A'}
        >
          {status}{' '}
        </Text>
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#6500E0" fontWeight="400">
        {' '}
        <Link to={`/business-merchant/merchant-details/${userEmail}`}>
          {' '}
          View{' '}
        </Link>
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
        {' '}
        Edit
      </Td>
    </Tr>
  );
};

const SignupList = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = React.useState(1);
  // eslint-disable-next-line
  const [postPerPage, setPostPerPage] = React.useState(10);

  const valsUrl =
    '/apis/v1/bizzdesk/signup/agentsSignups/findAllByAggregatorsFullnameAndStatus?limit=2000&offset=0';

  const { data: val } = useSWR(
    [valsUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  // get current post
  const indexOfLastPage = currentPage * postPerPage;

  const indexOfFirstPage = indexOfLastPage - postPerPage;

  const serial = Array?.isArray(val)
    ? val?.map((data, index) => ({ ...data, serialno: index + 1 }))
    : null;
  const currentPosts = Array?.isArray(serial)
    ? serial?.slice(indexOfFirstPage, indexOfLastPage)
    : serial?.toString().slice(indexOfFirstPage, indexOfLastPage);

  const paginate = pageNumber => {
    setCurrentPage(pageNumber);
  };
  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Box mb="1rem">
          <button
            onClick={() => navigate(-1)}
            style={{ fontSize: '14px', fontWeight: '300' }}
            tw="mb-4 hover:underline"
          >
            Back
          </button>
        </Box>
        <Box>
          <Box
            display="flex"
            gap="6"
            justifyContent="space-between"
            w="full"
            flexDirection={['row', 'row']}
            mt="2rem"
          >
            <Text fontWeight="500" color="#191716" fontSize="20px" float="left">
              Terminal
            </Text>
            <HStack float="right">
              {/* <Link style={{ color: '#6500E0' }} to="/terminals/list">
                View All
              </Link> */}
              {/* <Image src="assets/merchant_blue_arrow_right.png" alt="" /> */}
            </HStack>
          </Box>
          <Box>
            <TableContainer>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      s/n
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      full name
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      email
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      phone number
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      bvn
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      business name
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      business address
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      date added
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      status
                    </Th>
                    <Th
                      fontSize="14px"
                      fontWeight="400"
                      color="#505780"
                      textTransform="uppercase"
                    >
                      actions
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {loading ? (
                    <Skeleton w="full" h="50%"></Skeleton>
                  ) : Array?.isArray(val) ? (
                    currentPosts.map((data, index) => (
                      <TableUI
                        key={index}
                        id={data.serialno}
                        fullname={data.fullName || '-'}
                        email={data.email || '-'}
                        phonenumber={data.nin || '-'}
                        bvn={data.bvn || '-'}
                        businessname={data.businessName || '-'}
                        businessaddress={data.businessAddress || '-'}
                        dateadded={data.dateAdded || '-'}
                        status={data.status || '-'}
                        userEmail={data.email}
                      />
                    ))
                  ) : null}
                </Tbody>
              </Table>
            </TableContainer>
            <Pagination
              postPerPage={postPerPage}
              paginate={paginate}
              totalPosts={val.length}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SignupList;
