/** @jsxImportSource @emotion/react */
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import useSWR from 'swr';
import Sidebar from '../component/Common/Sidebar';
import Topnav from '../component/Common/Topnav';
// eslint-disable-next-line no-unused-vars
import { useLocation, useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import DatRangePickerAndOthers from '../component/Common/DataGridFunctionalities/DatRangePickerAndOthers';
import SearchBar from '../component/Common/DataGridFunctionalities/SearchBar';
import DataGridViewTemp from '../component/Common/DataGridViewTemp';
import { swrfetcher } from '../utils/swrFetcher';

const Subadmins = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const [searchValue, setSearchValue] = React.useState('');
  const [date, setDate] = React.useState(['', '']);

  const tableUrl = `/apis/v1/bizzdesk/admin/SubAdmin/getSubAdminTable?limit=30&offset=${
    offset || 0
  }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
    !date[0] ? '' : `&startDate=${date[0]}`
  }${!date[1] ? '' : `&endDate=${date[1]}`}`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          name: item?.fullNameOfAdmin,
          roles: item?.role,
          email: item?.email,
          datejoined: item?.dateCreated,
          action: {
            edit: true,
            delete: true,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'name',
    },
    {
      headerName: 'roles',
    },
    {
      headerName: 'email',
    },
    {
      headerName: 'date joined',
    },
    {
      headerName: 'actions',
    },
  ];

  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <button
          onClick={() => navigate(-1)}
          style={{ fontSize: '14px', fontWeight: '300' }}
          tw="mb-4 hover:underline"
        >
          Back
        </button>

        <DataGridViewTemp
          title="Sub Admins List"
          rows={rows}
          columns={TransWalletColumn}
          isValidating={isValidating}
          hasExportBtn={tableData}
          pagination
          recordCount={100}
          page={offset / 10 + 1}
        >
          <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
            <SearchBar value={searchValue} setSearchValue={setSearchValue} />
            <DatRangePickerAndOthers date={date} setDate={setDate} />
          </div>
        </DataGridViewTemp>
      </Box>
    </Box>
  );
};

export default Subadmins;
