import {
  Box,
  HStack,
  Image,
  Select,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import CreateMerchantModal from './CreateMerchantBandModal';

const TableUI = ({
  id,
  band,
  nooftransactions,
  noofmerchants,
  datecreated,
}) => {
  return (
    <Tr color="#525252">
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {band}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {nooftransactions}
      </Td>

      <Td fontSize="14px" fontWeight="400">
        {noofmerchants}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {datecreated}
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
        {' '}
        Edit
      </Td>
      <Td color="#ED6A5A" cursor="pointer" fontSize="14px" fontWeight="400">
        {' '}
        Delete
      </Td>
    </Tr>
  );
};

const Merchantband = () => {
  const [loading, setLoading] = React.useState(false);
  const [val, setVal] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  const validate = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/band/getBandTable`,
    })
      .then(response => {
        setLoading(false);
        setVal(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" flexDirection={['column', 'row']} mt="1rem" w="full">
      <Box
        w={['100%', '30%']}
        display="flex"
        flexDirection="column"
        borderRight="1px solid #E4ECF7"
        h={['100%', '100vh']}
      >
        <Text float="left" fontWeight="700" fontSize="32px">
          Settings
        </Text>
        <Box display="flex" flexDirection="column" gap="3" mt="3rem">
          <NavLink to="/settings/merchant-band">
            <HStack>
              <Image src="assets/home.png" alt="" />
              <Text
                _active={{ color: '#6500E0' }}
                _hover={{ color: '#6500E0' }}
                fontWeight="500"
                fontSize="16px"
              >
                Merchant Band
              </Text>
            </HStack>
          </NavLink>
          <NavLink style={{ marginTop: '1rem' }} to="/settings">
            <HStack>
              <Image src="assets/home.png" alt="" />
              <Text
                _active={{ color: '#6500E0' }}
                _hover={{ color: '#6500E0' }}
                fontWeight="500"
                fontSize="16px"
              >
                Charge Plan
              </Text>
            </HStack>
          </NavLink>
        </Box>
      </Box>
      <Box w={['full', '70%']} h="100%" p="2rem">
        <Link to="/settings">
          <Text color="#454D54" fontSize="13px" fontWeight="400">
            Back
          </Text>
        </Link>
        <Box>
          <Box
            display="flex"
            gap="6"
            justifyContent="space-between"
            w="full"
            flexDirection={['column', 'row']}
            mt="1rem"
          >
            <VStack alignItems="flex-start">
              <Text
                float="left"
                fontSize="20px"
                color="#16192C"
                fontWeight="500"
              >
                Merchant Band
              </Text>
              <Text
                float="left"
                fontSize="14px"
                color="#425D8A"
                fontWeight="400"
              >
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Scelerisque congue auctor est lacinia justo.
              </Text>
            </VStack>
            <Box float="right">
              <CreateMerchantModal />
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap="6" flexDirection={['column', 'row']} mt="2rem">
          <Box
            bg="#fff"
            borderRadius="4px"
            w={['100%', '359.67px']}
            h={['100%', '100%']}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="3"
          >
            <Box
              h="69px"
              bg="#F3F7FC"
              display="flex"
              w="full"
              alignItems="center"
              p="0.5rem"
              justifyContent="space-between"
            >
              <Box float="left">
                <HStack>
                  <Text fontSize="24px" fontWeight="500" color="#0B2C42">
                    Band A
                  </Text>
                </HStack>
              </Box>
              <Select
                w="35%"
                fontWeight="300"
                textAlign="center"
                borderRadius="25px"
                fontSize="12px"
                color="#425D8A"
                placeholder="Last month"
              >
                <option style={{ margin: '.75rem' }} value="option1">
                  Option 1
                </option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#425D8A"
                fontSize="14px"
                fontWeight="400"
              >
                total merchant
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#136F63"
                fontSize="14px"
                fontWeight="400"
              >
                on target
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#ED6A5A"
                fontSize="14px"
                fontWeight="400"
              >
                off target
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
          </Box>
          <Box
            bg="#fff"
            borderRadius="4px"
            w={['100%', '359.67px']}
            h={['100%', '100%']}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="3"
          >
            <Box
              h="69px"
              bg="#F3F7FC"
              display="flex"
              w="full"
              alignItems="center"
              p="0.5rem"
              justifyContent="space-between"
            >
              <Box float="left">
                <HStack>
                  <Text fontSize="24px" fontWeight="500" color="#0B2C42">
                    Band B
                  </Text>
                </HStack>
              </Box>
              <Select
                w="35%"
                fontWeight="300"
                textAlign="center"
                borderRadius="25px"
                fontSize="12px"
                color="#425D8A"
                placeholder="Last month"
              >
                <option style={{ margin: '.75rem' }} value="option1">
                  Option 1
                </option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#425D8A"
                fontSize="14px"
                fontWeight="400"
              >
                total merchant
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#136F63"
                fontSize="14px"
                fontWeight="400"
              >
                on target
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#ED6A5A"
                fontSize="14px"
                fontWeight="400"
              >
                off target
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
          </Box>
          <Box
            bg="#fff"
            borderRadius="4px"
            w={['100%', '359.67px']}
            h={['100%', '100%']}
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="3"
          >
            <Box
              h="69px"
              bg="#F3F7FC"
              display="flex"
              w="full"
              alignItems="center"
              p="0.5rem"
              justifyContent="space-between"
            >
              <Box float="left">
                <HStack>
                  <Text fontSize="24px" fontWeight="500" color="#0B2C42">
                    Band C
                  </Text>
                </HStack>
              </Box>
              <Select
                w="35%"
                fontWeight="300"
                textAlign="center"
                borderRadius="25px"
                fontSize="12px"
                color="#425D8A"
                placeholder="Last month"
              >
                <option style={{ margin: '.75rem' }} value="option1">
                  Option 1
                </option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#425D8A"
                fontSize="14px"
                fontWeight="400"
              >
                total merchant
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#136F63"
                fontSize="14px"
                fontWeight="400"
              >
                on target
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom="1px solid #EBF2FA"
              w="full"
              px="0.85rem"
              gap="3"
            >
              <Text
                as="span"
                textTransform="uppercase"
                color="#ED6A5A"
                fontSize="14px"
                fontWeight="400"
              >
                off target
              </Text>
              <Text fontWeight="500" float="right" fontSize="32px">
                231
              </Text>
            </Box>
          </Box>
        </Box>
        <Box display="flex" gap="6" flexDirection="column" mt="2.5rem">
          <Text float="left" fontSize="16px" color="#191716" fontWeight="500">
            List of Merchant Band
          </Text>
          <TableContainer bg="#F3F7FC" mt="1.5rem">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    s/n
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    Band
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    Transaction Target
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    No of Merchants
                  </Th>

                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    borderRight="1px solid #BAC7DE"
                    textTransform="uppercase"
                  >
                    Date Created
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    textTransform="uppercase"
                  >
                    Actions
                  </Th>
                  <Th
                    fontSize="14px"
                    fontWeight="400"
                    color="#505780"
                    textTransform="uppercase"
                  ></Th>
                </Tr>
              </Thead>
              <Tbody>
                {loading ? (
                  <Skeleton w="full" h="50%"></Skeleton>
                ) : Array?.isArray(val) ? (
                  val.map((data, index) => (
                    <TableUI
                      key={data.id}
                      id={index + 1}
                      band={data.merchantBand}
                      nooftransactions={data.transactionTarget}
                      noofmerchants={data.noOfMerchant}
                      datecreated={data.dateAdded}
                    />
                  ))
                ) : null}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Merchantband;
