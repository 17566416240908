import { Box } from '@chakra-ui/react';
import React from 'react';
// import FilterMainWallet from './FilterMainWallet';
// import axios from 'axios'
import IncomeWallet from './IncomeWallet';

const PosWallet = () => {
  // const [loading, setLoading] = React.useState(false);
  // const [val, setVal] = React.useState(false);
  //   const token = JSON?.parse(
  //      localStorage.getItem('token') || sessionStorage.getItem('token')
  //   );

  // const validate = () => {
  //   setLoading(true);

  //   axios({
  //     method: 'get',
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Access-Control-Allow-Origin': '*',
  //       'Content-type': 'Application/json',
  //     },
  //     url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/wallet/analytics/getAmountInWallets',
  //   })
  //     .then(response => {
  //       setLoading(false);
  //       setVal(response.data);
  //     })
  //     .catch(err => {
  //       setLoading(false);
  //     });
  // };
  // React.useEffect(() => {
  //   validate();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);
  return (
    <Box>
      {/* <button
        onClick={() => navigate(-1)}
        style={{ fontSize: '14px', fontWeight: '300' }}
        tw="mb-4 hover:underline"
      >
        Back
      </button> */}
      {/* <Box
        display="flex"
        gap="6"
        justifyContent="space-between"
        w="full"
        flexDirection={['column', 'row']}
        mt="1rem"
      >
        <Text float="left" fontSize="32px" color="#16192C" fontWeight="700">
          Wallet
        </Text>
      </Box> */}
      {/* wallet balance */}
      {/* <Box
        w="full"
        h={['100%', '163px']}
        p={['1rem', '0']}
        borderRadius="20px"
        bg="#EBF2FA"
        position="relative"
        mt="2rem"
      >
        <Image
          src="/assets/Ellipse 9.png"
          left={['300px', '300px', '300px', '327.13px']}
          alt=""
          display={['none', 'block']}
          position="absolute"
        />
        <Image
          src="/assets/Ellipse 11.png"
          alt=""
          left="1"
          bottom="1"
          display={['none', 'block']}
          position="absolute"
        />
        <Image
          src="/assets/Ellipse 10.png"
          left="614.08px"
          display={['none', 'none', 'none', 'block']}
          top="33px"
          alt=""
          position="absolute"
        />
        <Box
          justifyContent="center"
          display="flex"
          w="full"
          h="full"
          flexDirection={['column', 'row']}
          alignItems="flex-start"
          zIndex="100"
          gap={['6', '0']}
        >
          <Box
            justifyContent="center"
            display="flex"
            flexDirection="column"
            float="left"
            w={['full', '50%']}
            h="full"
            ml="2rem"
            alignItems="flex-start"
            zIndex="100"
            gap="3"
          >
            <Text zIndex="100" fontSize="16px" fontWeight="500">
              POS Sales/Verification Wallet
            </Text>
            <Text zIndex="100" fontSize="40px" fontWeight="500">
              N
              {loading ? (
                <Skeleton w="50%" h="20px" />
              ) : (
                val?.posSalesWallet?.toLocaleString()
              )}
            </Text>
          </Box>
          <Box
            float="right"
            display="flex"
            gap="3"
            w={['full', '50%']}
            h="full"
            alignItems="center"
            justifyContent="flex-end"
            mr="2rem"
            flexDirection={['column', 'row']}
          >
            <Button
              w="185px"
              h="56px"
              borderRadius="12px"
              color="#fff"
              bg="#6500E0"
            >
              Fund Wallet
            </Button>
            <Button
              w="185px"
              h="56px"
              borderRadius="12px"
              color="#fff"
              bg="#191716"
            >
              Withdraw
            </Button>
          </Box>
        </Box>
      </Box> */}
      <Box
        display="flex"
        gap="6"
        justifyContent="space-between"
        w="full"
        flexDirection={['row', 'row']}
      >
        {/* <Text fontWeight="500" color="#191716" float="left">
          Wallet History
        </Text> */}
        {/* <HStack float="right">
          <Link style={{ color: '#6500E0' }} to="/terminals/list">
            View All
          </Link>
           <Image src="assets/merchant_blue_arrow_right.png" alt="" /> 
        </HStack> */}
      </Box>
      {/* <FilterMainWallet /> */}
      <IncomeWallet />
    </Box>
  );
};

export default PosWallet;
