import React from 'react'
import {Box, Stack, Skeleton, HStack} from '@chakra-ui/react'

const Stick = () => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="center"
      w={['100%', '257px']}
      textDecoration="none"
      _hover={{ textDecoration: 'none' }}
      h="100%"
      p="1rem"
      borderRadius="8px"
      bg="whitesmoke"
      gap="2"
      border="1px solid #E4ECF7"
    >
      <Stack>
        <Skeleton height="20px" w="40%" mb="3rem" />
        <Skeleton height="20px" w="45%" mb="1rem" />
        <HStack gap="2">
          <Skeleton height="20px" w="20px" />
          <Skeleton height="20px" w="20px" />
          <Skeleton height="20px" w="20px" />
          <Skeleton height="20px" w="20px" />
        </HStack>
      </Stack>
    </Box>
  );
};

export default Stick