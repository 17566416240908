import {
  Box,
  Image,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import useSWR from 'swr'
import { swrfetcher } from '../../utils/swrFetcher';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import IncomeWalletFilter from './IncomeWalletFilter';
// import Pagination from '../Common/Pagination';
import image1 from '../BusinessMerchant/assets/print.png';

import image2 from '../BusinessMerchant/assets/eye.png';

const TableUI = ({
  id,
  transactiontype,
  description,
  debit,
  credit,
  balance,
  date,
}) => {
  return (
    <Tr>
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {transactiontype}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {description}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {debit}{' '}
      </Td>
      <Td fontSize="14px" textAlign="center" fontWeight="400">
        {credit}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {balance}{' '}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {' '}
        {date}{' '}
      </Td>
      <Td color="#ED6A5A" display="flex" gap="2">
        <Link to="/wallet/id">
          <Image src={image2} alt="" h="24px" w="24px" />
        </Link>
        <Link to="">
          <Image src={image1} alt="" h="24px" w="24px" />
        </Link>
      </Td>
    </Tr>
  );
};

const IncomeWallet = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();
  
  // eslint-disable-next-line
  const [currentPage, setCurrentPage] = React.useState(1);
  // eslint-disable-next-line
  const [postPerPage, setPostPerPage] = React.useState(10);
  
   const tableUrl = userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/IncomeWallet/getWalletActivities?limit=1000&offset=0`
      : '/apis/bizzdesk/organization/wallet/getIncomeWalletActivities?limit=1000&offset=0';
  
   const { data: val } = useSWR(
     [tableUrl, token, 'GET'],
     ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
   );



  // get current post
  const indexOfLastPage = currentPage * postPerPage;

  const indexOfFirstPage = indexOfLastPage - postPerPage;
  // const [search, setSearch] = React.useState('');

  const serial = Array?.isArray(val)
    ? val?.map((data, index) => ({ ...data, serialno: index + 1 }))
    : null;
  // const filteredData = serial?.filter(item => {
  //     return item.date < '2022-11-20 11:28:14';
  //   });
  const currentPosts = Array?.isArray(serial)
    ? serial?.slice(indexOfFirstPage, indexOfLastPage)
    : serial?.toString().slice(indexOfFirstPage, indexOfLastPage);

  // const paginate = pageNumber => {
  //   setCurrentPage(pageNumber);
  // };
  // filter server data
  const [displayData, setDisplayData] = useState([]);
  const [firstDate, setFirstDate] = useState();
  const [secondDate, setSecondDate] = useState();
  const [search, setSearch] = useState();
  const [trues, setTrue] = useState(false);

  const filterTransactions = () => {
    setDisplayData(currentPosts);
    setTrue(true);
    // const firstDate = new Date().toISOString();
    // const secondDate = new Date().toISOString();

    const newData = val.filter(data => {
      const firstDateInNumber = new Date(firstDate).getTime();
      const secondDateInNumber = new Date(secondDate).getTime();
      const dataDate = new Date(data.date).getTime();

      if (
        (firstDateInNumber <= dataDate && secondDateInNumber <= dataDate) ||
        search === data.transType
      ) {
        return true;
      }
      return false;
    });
    setDisplayData(newData);
  };
  return (
    <Box mt="1rem">
      <IncomeWalletFilter
        setSecondDate={setSecondDate}
        setFirstDate={setFirstDate}
        setSearch={setSearch}
        filterTransactions={filterTransactions}
      />
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                s/n
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                transaction type
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                description
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                debit
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                noOfLines={2}
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                credit
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                balance
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                date
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                borderRight="1px solid #BAC7DE"
                textTransform="uppercase"
              >
                action
              </Th>
            </Tr>
          </Thead>
          {trues ? (
            <Tbody>
              {loading ? (
                <Skeleton w="full" h="50%"></Skeleton>
              ) : Array?.isArray(val) ? (
                // currentPosts
                //   .filter(item => {
                //     return search.toLowerCase() === ''
                //       ? item
                //       : item.date.toLowerCase().includes(search);
                //   })
                displayData.map((data, index) => (
                  <TableUI
                    key={index}
                    id={data.serialno}
                    transactiontype={data?.transType}
                    description={data?.transinfo}
                    debit={data?.debit || '-'}
                    credit={data?.credit || '-'}
                    balance={data?.newamount}
                    date={data?.date}
                  />
                ))
              ) : null}
            </Tbody>
          ) : (
            <Tbody>
              {loading ? (
                <Skeleton w="full" h="50%"></Skeleton>
              ) : Array?.isArray(val) ? (
                // currentPosts
                //   .filter(item => {
                //     return search.toLowerCase() === ''
                //       ? item
                //       : item.date.toLowerCase().includes(search);
                //   })
                currentPosts.map((data, index) => (
                  <TableUI
                    key={index}
                    id={data.serialno}
                    transactiontype={data?.transType}
                    description={data?.transinfo}
                    debit={data?.debit || '-'}
                    credit={data?.credit || '-'}
                    balance={data?.newamount}
                    date={data?.date}
                  />
                ))
              ) : null}
            </Tbody>
          )}
        </Table>
      </TableContainer>
      {/* <Pagination
        postPerPage={postPerPage}
        paginate={paginate}
        totalPosts={val.length}
      /> */}
    </Box>
  );
};

export default IncomeWallet;
