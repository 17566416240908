import {
  Box,
  Button,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

function AddPlan() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Image src="/assets/add-square.png" alt="" />}
        bg="#6500E0"
        w="179px"
        h="42px"
        fontSize="13px"
        fontWeight="400"
        borderRadius="8px"
        colorScheme="#6500E0"
        variant="solid"
      >
        Add Plan
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Add transaction type
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <Box
              mt="1rem"
              p="1rem"
              display="flex"
              flexDirection="column"
              gap="3"
            >
              <VStack alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Title
                </Text>
                <Input
                  placeholder=""
                  border="1px solid #E3E6E8"
                  borderRadius="4px"
                  color="#fff"
                  fontSize="14px"
                  outline="none"
                  type="text"
                  _focus={{ outline: 'none' }}
                  _active={{ outline: 'none' }}
                />
              </VStack>
              <Button
                border="none"
                borderRadius="12px"
                w="full"
                h="55px"
                color="#fff"
                bg="#6500E0"
              >
                Save
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddPlan;
