import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';
import { Route, Routes } from 'react-router-dom';
import RecoverAccount from './auth/RecoverAccount';
import Dashboard from './component/Home/Dashboard';
import CreatePassword from './auth/CreatePassword';
import Login from './auth/Login';
import BusinessMerchant from './pages/BusinessMerchant';
import MerchantProfile from './pages/MerchantProfile';
import Terminals from './pages/Terminals';
import TerminalList from './pages/TerminalList';
import Wallet from './pages/Wallet';
import BizzdeskWallets from './pages/BizzdeskWallet';
import PosWallets from './pages/PosWallet';
import Transaction from './pages/Transaction';
import Pos from './pages/Pos';
import WalletSlipPage from './pages/WalletSlipPage';
import IncomeSlipPage from './pages/IncomeSlipPage';
import ReceivableSlipPage from './pages/ReceivableSlipPage';
import Role from './pages/Role';
import Permission from './pages/Permission';
import ListofRole from './pages/ListofRole';
import RoleDetails from './pages/RoleDetails';
import Subadmins from './pages/Subadmins';
import Setting from './pages/Setting';
import AddPlan from './pages/AddPlan';
import Merchant from './pages/Merchant';
import Signups from './pages/Signups';
import AddMerchants from './pages/AddMerchant';
import AddOrganizations from './pages/AddOrganization';
import MerchantDetails from './pages/MerchantDetails';
import SignupList from './component/Signup/SignupList';
import WalletDetail from './pages/WalletDetail';
import MerchantWalletList from './pages/MerchantWalletList';
import MerchantTerminal from './pages/MerchantTerminal';
import BankTerminal from './pages/BankTerminal';
import BbmMainWalletPage from './pages/BbmMainWalletPage';
import MerchantWalletpage from './pages/MerchantWalletpage';
import NewSignupPage from './pages/NewSignupPage';
import ApprovedSignupPage from './pages/ApprovedSignupPage';
import UnApprovedSignup from './pages/UnApprovedSignup';
import BizzdeskTransactionPage from './pages/BizzdeskTransactionPage';
import MerchantTransactionPage from './pages/MerchantTransactionPage';
import PosTransactionPage from './pages/PosTransactionPage';
import SubadminsList from './pages/SubadminsList.jsx';
import LOS from './pages/ListOfSignups';
import LOO from './pages/ListOfOrganizations';
import WalletWithdrawalTransaction from './pages/WalletWithdrawalTransaction';
import TransactionList from './pages/Transaction_list';
import OrganizationsPage from './pages/OrganizationsPage.jsx';
import OrganizationPage from './pages/OrganizationPage.jsx';
import CheckoutPage from './pages/CheckoutPage.jsx';
import AddCheckoutMerchants from './pages/AddCheckoutMerchants.jsx';
import CheckoutMerchantList from './pages/CheckoutMerchantList.jsx';
import CheckoutMerchantProfile from './pages/CheckoutMerchantProfile.jsx';
import CheckoutSignupPage from './pages/CheckoutSignupPage.jsx';
import CheckoutMainWalletPage from './pages/CheckoutMainWalletPage.jsx';
import CheckoutIncomeWalletPage from './pages/CheckoutIncomeWalletPage.jsx';
import CheckoutTransactionList from './pages/CheckoutTransaction_list.jsx';
import LandingPage from './pages/LandingPage.jsx';

function App() {
  return (
    <ChakraProvider>
      <Box>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/create-password" element={<CreatePassword />} />
          <Route path="/recover-account" element={<RecoverAccount />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/business-merchants" element={<BusinessMerchant />} />
          <Route path="/organizations" element={<OrganizationsPage />} />
          <Route
            path="/organizations/:organizationUserName"
            element={<OrganizationPage />}
          />
          <Route path="/business-merchants/merchants_lists" element={<LOS />} />
          <Route
            path="/business-organizations/organizations_lists"
            element={<LOO />}
          />
          <Route
            path="/business-merchant/:userId"
            element={<MerchantProfile />}
          />
          <Route
            path="/business-merchant/merchant-details/:userEmail"
            element={<MerchantDetails />}
          />
          <Route
            path="/business-merchant/add-merchant"
            element={<AddMerchants />}
          />
          <Route
            path="/organizations/add-organization"
            element={<AddOrganizations />}
          />
          <Route path="/terminals" element={<Terminals />} />
          <Route path="/terminals/list" element={<TerminalList />} />
          <Route
            path="/terminals/terminals-per-bank"
            element={<BankTerminal />}
          />
          <Route
            path="/terminals/terminals-per-merchant"
            element={<MerchantTerminal />}
          />
          <Route path="/wallet" element={<Wallet />} />
          <Route
            path="/wallet/main-wallet-list"
            element={<BbmMainWalletPage />}
          />
          <Route
            path="/wallet/merchant-wallet-list"
            element={<MerchantWalletpage />}
          />
          <Route
            path="/wallet/income_wallet_list"
            element={<BizzdeskWallets />}
          />
          <Route path="/wallet/pos-wallet" element={<PosWallets />} />
          <Route path="/wallet/:walletuserId" element={<WalletDetail />} />
          <Route
            path="/wallet/:merchant-wallet-llist"
            element={<MerchantWalletList />}
          />
          <Route path="/transactions" element={<Transaction />} />
          <Route
            path="/transactions/pos-transactions"
            element={<PosTransactionPage />}
          />
          <Route
            path="/transactions/merchant-transactions"
            element={<MerchantTransactionPage />}
          />
          <Route
            path="/transactions/bizzdesk-income"
            element={<BizzdeskTransactionPage />}
          />
          <Route
            path="/transactions/wallet-withdrawal-transactions"
            element={<WalletWithdrawalTransaction />}
          />
          <Route path="/transactions/:transactionRef" element={<Pos />} />
          <Route
            path="/transactions/wallet/:transactionRef"
            element={<WalletSlipPage />}
          />
          <Route
            path="/transactions/receivable/:transactionRef"
            element={<ReceivableSlipPage />}
          />
          <Route
            path="/transactions/income/:transactionRef"
            element={<IncomeSlipPage />}
          />
          <Route path="/roles" element={<Role />} />
          <Route path="/roles/details" element={<RoleDetails />} />
          <Route path="/roles/add-permission" element={<Permission />} />
          <Route path="/roles/list-of-roles" element={<ListofRole />} />
          <Route path="/sub-admin" element={<Subadmins />} />
          <Route path="/sub-admin/sub_admin_list" element={<SubadminsList />} />
          <Route path="/settings" element={<Setting />} />
          <Route path="/settings/plan-packages" element={<AddPlan />} />
          <Route path="/settings/merchant-band" element={<Merchant />} />
          <Route path="/sign-up" element={<Signups />} />
          <Route
            path="/sign-up/approved-list"
            element={<ApprovedSignupPage />}
          />
          <Route path="/sign-up/new-signup-list" element={<NewSignupPage />} />
          <Route
            path="/sign-up/unapproved-list"
            element={<UnApprovedSignup />}
          />
          <Route path="/sign-up/list" element={<SignupList />} />
          <Route
            path="/transactions/transactions_list/:transMode"
            element={<TransactionList />}
          />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route
            path="/checkout/add-merchant"
            element={<AddCheckoutMerchants />}
          />
          <Route
            path="/checkout/merchants_lists"
            element={<CheckoutMerchantList />}
          />
          <Route
            path="/checkout/:userId"
            element={<CheckoutMerchantProfile />}
          />
          <Route
            path="/checkout/signup-list"
            element={<CheckoutSignupPage />}
          />
          <Route
            path="/checkout/main-wallet-list"
            element={<CheckoutMainWalletPage />}
          />
          <Route
            path="/checkout/income-wallet-list"
            element={<CheckoutIncomeWalletPage />}
          />
          <Route
            path="/checkout/transactions/transactions_list/:transMode"
            element={<CheckoutTransactionList />}
          />
        </Routes>
      </Box>
    </ChakraProvider>
  );
}

export default App;
