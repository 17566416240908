/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  HStack,
  Image,
  Select,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { swrfetcher } from '../../utils/swrFetcher';
import AssignBand from './Modals/AssignBand';
import AssignStaff from './Modals/AssignStaff';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import DataGridViewTemp from '../Common/DataGridViewTemp';

const BuisnessMerchant = () => {
  const [loading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const pageUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/merchants/analytics'
      : '/apis/bizzdesk/organization/businessMerchants/analytics';
  const tableUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/admin/analytics/getMerchantAnalyticsTable?limit=10&offset=0'
      : '/apis/bizzdesk/organization/businessMerchants/getMerchantAnalyticsTable?limit=10&offset=0';

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: val } = useSWR(
    [pageUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          merchantId: item?.merchantId,
          name: item?.fullname,
          organizationName: item?.organizationName,
          terminals: item?.terminals,
          walletbalanceN: item?.walletBallance,
          walletaccount: item?.walletAccount,
          transactionN: item?.totalAmountInTransactions,
          nooftransactions: item?.noOfTransactions,
          charge: item?.charge,
          datecreated: item?.dateAdded,
          status: item?.status,
          supportstaff: item?.supportStaff,
          action: {
            disable: true,
            link: `/business-merchant/${item?.username}`,
            edit: true,
          },
        };
      });
    }
  }

  const TransColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'merchant id',
    },
    {
      headerName: 'name',
    },
    {
      headerName: 'Organization Name',
    },
    {
      headerName: 'terminals',
    },
    {
      headerName: 'wallet balance',
    },
    {
      headerName: 'wallet account',
    },
    {
      headerName: 'Total Transaction Amount(N)',
    },
    {
      headerName: 'No of Transaction',
    },
    {
      headerName: 'charges',
    },
    {
      headerName: 'date created',
    },
    {
      headerName: 'status',
    },
    {
      headerName: 'support staff',
    },
  ];

  return (
    <>
      <Box>
        <Box
          display="flex"
          gap="6"
          justifyContent="space-between"
          w="full"
          // flexDirection={['column', 'row']}
          mt="1rem"
          css={[
            userType === 'admin' ? tw`flex-col 2xl:flex-row` : tw`flex-row`,
          ]}
        >
          <Text float="left" fontSize="32px" color="#16192C" fontWeight="700">
            Business Merchant
          </Text>
          <Box float="right" tw="space-x-4">
            {userType === 'admin' && <AssignBand />}
            {userType === 'admin' && <AssignStaff />}
            <Link to="/business-merchant/add-merchant">
              <Button
                leftIcon={<Image src="/assets/add-square.png" alt="" />}
                bg="#6500E0"
                w="149px"
                h="42px"
                borderRadius="8px"
                colorScheme="#6500E0"
                variant="solid"
              >
                Add Merchant
              </Button>
            </Link>
          </Box>
        </Box>

        <Box display="flex" gap="6" flexDirection={['column', 'row']} mt="2rem">
          <Box
            bg="#fff"
            borderRadius="4px"
            w={['100%', '359.67px']}
            h={['100%', '191px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="3"
            p=".75rem"
          >
            <Box display="flex" w="full" justifyContent="space-between">
              <Box float="left">
                <HStack>
                  <Image
                    float="right"
                    w="24px"
                    h="24px"
                    src="/assets/trend-down.png"
                    alt=""
                  />
                  <Text fontSize="14px" fontWeight="500" color="#ED6A5A">
                    3.4%
                  </Text>
                </HStack>
                <Skeleton isLoaded={val}>
                  <Text fontSize="40px" fontWeight="600">
                    {val?.totalAgents}
                  </Text>
                </Skeleton>
              </Box>
              <Select
                w="35%"
                fontWeight="300"
                textAlign="center"
                borderRadius="25px"
                fontSize="12px"
                color="#425D8A"
                placeholder="Last month"
              >
                <option style={{ margin: '.75rem' }} value="option1">
                  Option 1
                </option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Box>
            <Box display="flex" gap="3">
              <HStack>
                <Skeleton isLoaded={val}>
                  <Text
                    as="span"
                    textTransform="uppercase"
                    color="#425D8A"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    total merchant
                  </Text>
                </Skeleton>
              </HStack>
            </Box>
          </Box>
          <Box
            bg="#fff"
            borderRadius="4px"
            w={['100%', '359.67px']}
            h={['100%', '191px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="3"
            p=".75rem"
          >
            <Box display="flex" w="full" justifyContent="space-between">
              <Box float="left">
                <HStack>
                  <Image
                    float="right"
                    w="24px"
                    h="24px"
                    src="/assets/trend-down.png"
                    alt=""
                  />
                  <Text fontSize="14px" fontWeight="500" color="#ED6A5A">
                    3.4%
                  </Text>
                </HStack>
                <Skeleton isLoaded={val}>
                  <Text fontSize="40px" fontWeight="600">
                    {val?.totalActiveAgents}
                  </Text>
                </Skeleton>
              </Box>
              <Select
                w="35%"
                fontWeight="300"
                textAlign="center"
                borderRadius="25px"
                fontSize="12px"
                color="#425D8A"
                placeholder="Last month"
              >
                <option style={{ margin: '.75rem' }} value="option1">
                  Option 1
                </option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Box>
            <Box display="flex" gap="3">
              <HStack>
                <Skeleton isLoaded={val}>
                  <Text
                    as="span"
                    textTransform="uppercase"
                    color="#425D8A"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    active merchant
                  </Text>
                </Skeleton>
              </HStack>
            </Box>
          </Box>
          <Box
            bg="#fff"
            borderRadius="4px"
            w={['100%', '359.67px']}
            h={['100%', '191px']}
            display="flex"
            flexDirection="column"
            justifyContent="center"
            gap="3"
            p=".75rem"
          >
            <Box display="flex" w="full" justifyContent="space-between">
              <Box float="left">
                <HStack>
                  <Image
                    float="right"
                    w="24px"
                    h="24px"
                    src="/assets/trend-down.png"
                    alt=""
                  />
                  <Text fontSize="14px" fontWeight="500" color="#ED6A5A">
                    3.4%
                  </Text>
                </HStack>
                <Skeleton isLoaded={val}>
                  <Text fontSize="40px" fontWeight="600">
                    {val?.totalInactiveAgents}
                  </Text>
                </Skeleton>
              </Box>
              <Select
                w="35%"
                fontWeight="300"
                textAlign="center"
                borderRadius="25px"
                fontSize="12px"
                color="#425D8A"
                placeholder="Last month"
              >
                <option style={{ margin: '.75rem' }} value="option1">
                  Option 1
                </option>
                <option value="option2">Option 2</option>
                <option value="option3">Option 3</option>
              </Select>
            </Box>
            <Box display="flex" gap="3">
              <HStack>
                <Skeleton isLoaded={val}>
                  <Text
                    as="span"
                    textTransform="uppercase"
                    color="#425D8A"
                    fontSize="14px"
                    fontWeight="400"
                  >
                    inactive merchant
                  </Text>
                </Skeleton>
              </HStack>
            </Box>
          </Box>
        </Box>

        {/* <ListOfMerchantTable /> */}

        <DataGridViewTemp
          link="/business-merchants/merchants_lists"
          limited
          title="List of Merchants"
          rows={rows}
          columns={TransColumn}
          isValidating={isValidating}
        />
      </Box>
    </>
  );
};

export default BuisnessMerchant;
