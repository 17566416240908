import {
  Box,
  Text,
  Input,
  InputGroup,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Image,
  Td,
  TableContainer,
  InputRightElement,
} from '@chakra-ui/react';
import React from 'react'
import { Link } from 'react-router-dom'

const TableContent = [
  {
    id: '1',
    name: 'Wade Warren',
    roles: 'Account Admin',
    email: 'ozenuaoluwatobi@gmail.com',
    datejoined: 'oct 4, 2022 12:11am',
  },
  {
    id: '2',
    name: 'Wade Warren',
    roles: 'Account Admin',
    email: 'ozenuaoluwatobi@gmail.com',
    datejoined: 'oct 4, 2022 12:11am',
  },
  {
    id: '3',
    name: 'Wade Warren',
    roles: 'Account Admin',
    email: 'ozenuaoluwatobi@gmail.com',
    datejoined: 'oct 4, 2022 12:11am',
  },
];

const TableUI = ({
  name,
  roles,
  email,
  datejoined,
}) => {
  return (
    <Tr>
      <Td fontSize="16px" alignItems='center' display='flex' gap='3' color="#16192C" fontWeight="500">
        
          <Image w='50px' h='50px' src="assets/roledetail.png" alt="" />
          {name}
       
      </Td>
      <Td fontSize="16px" color="#425D8A" fontWeight="500">
        {roles}
      </Td>
      <Td fontSize="16px" color="#425D8A" fontWeight="500">
        {email}
      </Td>
      <Td fontSize="16px" color="#425D8A" fontWeight="500">
        {datejoined}{' '}
      </Td>
    </Tr>
  );
};


const RoleDetail = () => {
  return (
    <Box display="flex" flexDirection="column" gap="4">
      <Link to="/roles">Back</Link>
      <Text color="#191716" fontSize="20px" fontWeight="500" mt="1rem">
        Administrator
      </Text>
      {/* description */}
      <Text color="#191716" fontSize="20px" fontWeight="500" mt="1rem">
        Description
      </Text>
      <Text color="#425D8A" fontSize="14px" fontWeight="400" mt="1rem">
        Eros quam senectus pharetra quis in cras viverra. Amet pulvinar interdum
        neque ultrices egestas ac maecenas. Imperdiet urna et curabitur morbi
        pharetra lacinia facilisi etiam. Velit pellentesque nunc quam orci
        sodales hac arcu eget felis. Risus, enim ipsum pellentesque mus sit sit
        lorem lorem. Tristique sodales adipiscing dignissim odio viverra massa
        in fusce. Sodales cum vestibulum velit fames ac feugiat rhoncus eget.
        Egestas dictum etiam blandit pulvinar nascetur lacus enim volutpat.
        Risus curabitur pretium id a cras orci laoreet ut. Sagittis commodo urna
        tristique senectus curabitur sem consequat gravida. Amet elementum
        eleifend quam adipiscing.
      </Text>
      <Box display="flex" w="full" justifyContent="space-between">
        <Text fontSize="16px" fontWeight="500">
          Users
        </Text>
        <Box float='right' w="30%">
          <InputGroup>
            <Input fontSize="13px" placeholder="Search All" />
            <InputRightElement
              children={<Image src="assets/search-normal.png" alt="" />}
            />
          </InputGroup>
        </Box>
      </Box>
      <TableContainer>
        <Table variant="simple">
          <Thead fontWeight='500' color='#A6B7D4'>
            <Tr>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                name
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                roles
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                email
              </Th>
              <Th
                fontSize="14px"
                fontWeight="400"
                color="#505780"
                textTransform="uppercase"
              >
                date joined
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {TableContent.map(data => (
              <TableUI
                key={data.id}
                id={data.id}
                name={data.name}
                roles={data.roles}
                email={data.email}
                datejoined={data.datejoined}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default RoleDetail