/** @jsxImportSource @emotion/react */
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';

const Pagination = ({
  totalPages,
  currentPage,
  handlePageChange,
  offsetLabel,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [displayPageNumbers, setDisplayPageNumbers] = React.useState([]);

  const pageNumbers = Array.from(Array(totalPages).keys()).map(i => i + 1);
  const calculatedDisplayPageNumbers = React.useMemo(() => {
    if (totalPages <= 5) {
      return pageNumbers;
    }

    if (currentPage < 4) {
      return [1, 2, 3, 4, '...', totalPages];
    }

    if (currentPage > totalPages - 3) {
      return [
        1,
        '...',
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }

    return [
      1,
      '...',
      currentPage - 1,
      currentPage,
      currentPage + 1,
      '...',
      totalPages,
    ];
  }, [totalPages, currentPage, pageNumbers]);

  React.useEffect(() => {
    setDisplayPageNumbers(calculatedDisplayPageNumbers);
  }, [calculatedDisplayPageNumbers]);

  const handlePreviousClick = () => {
    navigate({
      pathname: location.pathname,
      search: `?${offsetLabel || 'offset'}=${(currentPage - 2) * 30}`,
    });
  };

  const handleNextClick = () => {
    navigate({
      pathname: location.pathname,
      search: `?${offsetLabel || 'offset'}=${currentPage * 30}`,
    });
  };

  return (
    <div tw="flex items-center justify-end mt-5">
      <button
        onClick={handlePreviousClick}
        css={[
          currentPage === 1
            ? tw`text-gray-400`
            : tw`hover:(bg-gray-200) text-gray-900`,
          tw`py-1.5 px-2.5 rounded text-sm transition`,
        ]}
        disabled={currentPage === 1}
      >
        &lt;
      </button>
      <div tw="flex items-center justify-center mx-1">
        {displayPageNumbers.map((number, index) => {
          if (number === '...') {
            return (
              <span key={index} tw="p-2 m-2">
                ...
              </span>
            );
          }

          return (
            <button
              key={number}
              css={[
                tw`py-1.5 px-2.5 mx-0.5 rounded text-gray-900 text-sm transition hover:(bg-gray-200)`,
                currentPage === number ? tw`bg-gray-300` : tw``,
              ]}
              onClick={() => handlePageChange(number)}
            >
              {number}
            </button>
          );
        })}
      </div>
      <button
        onClick={handleNextClick}
        css={[
          currentPage === totalPages
            ? tw`text-gray-400`
            : tw`hover:(bg-gray-200) text-gray-900`,
          tw`py-1.5 px-2.5 rounded text-sm transition`,
        ]}
        disabled={currentPage === totalPages}
      >
        &gt;
      </button>
    </div>
  );
};

export default Pagination;
