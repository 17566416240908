/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  HStack,
  Image,
  Select,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import { swrfetcher } from '../../utils/swrFetcher';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import DataGridViewTemp from '../Common/DataGridViewTemp';

const BuisnessMerchant = () => {
  const [loading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const pageUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/merchants/analytics'
      : '/apis/bizzdesk/organization/businessMerchants/analytics';
  const tableUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/admin/analytics/getMerchantAnalyticsTable?limit=10&offset=0'
      : '/apis/bizzdesk/organization/businessMerchants/getMerchantAnalyticsTable?limit=10&offset=0';

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: val } = useSWR(
    [pageUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  // let rows = [];
  // if (tableData) {
  //   if (tableData?.status >= 400) {
  //     rows = [];
  //   } else {
  //     rows = tableData?.map((item, index) => {
  //       return {
  //         id: index + 1,
  //         merchantId: item?.merchantId,
  //         name: item?.fullname,
  //         walletbalanceN: item?.walletBallance,
  //         walletaccount: item?.walletAccount,
  //         transactionN: item?.totalAmountInTransactions,
  //         nooftransactions: item?.noOfTransactions,
  //         charge: item?.charge,
  //         datecreated: item?.dateAdded,
  //         status: item?.status,
  //         action: {
  //           disable: true,
  //           link: `/checkout/${item?.username}`,
  //           edit: true,
  //         },
  //       };
  //     });
  //   }
  // }

  const TransColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'merchant id',
    },
    {
      headerName: 'name',
    },
    {
      headerName: 'wallet balance',
    },
    {
      headerName: 'wallet account',
    },
    {
      headerName: 'Total Transaction Amount(N)',
    },
    {
      headerName: 'No of Transaction',
    },
    {
      headerName: 'charges',
    },
    {
      headerName: 'date created',
    },
    {
      headerName: 'status',
    },
  ];

  const metric = [
    {
      title: 'Total Merchants',
      value: 104,
    },
    {
      title: 'Active Merchants',
      value: 104,
    },
    {
      title: 'Inactive Merchants',
      value: 104,
    },
  ];

  return (
    <>
      <Box>
        <Box
          display="flex"
          gap="6"
          justifyContent="space-between"
          w="full"
          // flexDirection={['column', 'row']}
          mt="1rem"
          tw="flex-row"
        >
          <Text float="left" fontSize="32px" color="#16192C" fontWeight="700">
            Checkout Merchant
          </Text>
          <Box float="right" tw="space-x-4">
            <Link to="/checkout/add-merchant">
              <Button
                leftIcon={<Image src="/assets/add-square.png" alt="" />}
                bg="#6500E0"
                w="149px"
                h="42px"
                borderRadius="8px"
                colorScheme="#6500E0"
                variant="solid"
              >
                Add Merchant
              </Button>
            </Link>
          </Box>
        </Box>

        <Box display="flex" gap="6" flexDirection={['column', 'row']} mt="2rem">
          {metric.map((item, index) => (
            <Box
              bg="#fff"
              borderRadius="4px"
              w={['100%', '359.67px']}
              h={['100%', '191px']}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              gap="3"
              p="1.5rem"
            >
              <Box display="flex" w="full" justifyContent="space-between">
                <Box float="left">
                  <Skeleton isLoaded={val}>
                    <Text fontSize="40px" fontWeight="600">
                      {item.value}
                    </Text>
                  </Skeleton>
                </Box>
              </Box>
              <Box display="flex" gap="3">
                <HStack>
                  <Skeleton isLoaded={val}>
                    <Text
                      as="span"
                      textTransform="uppercase"
                      color="#425D8A"
                      fontSize="14px"
                      fontWeight="400"
                    >
                      {item.title}
                    </Text>
                  </Skeleton>
                </HStack>
              </Box>
            </Box>
          ))}
        </Box>

        {/* <ListOfMerchantTable /> */}
        <DataGridViewTemp
          link="/checkout/merchants_lists"
          limited
          title="List of Merchants"
          rows={[]}
          columns={TransColumn}
          // isValidating={isValidating}
        />
      </Box>
    </>
  );
};

export default BuisnessMerchant;
