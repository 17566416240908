/** @jsxImportSource @emotion/react */
import React from 'react';
import useSWR from 'swr';
import { useLocation } from 'react-router-dom';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';
import DatRangePickerAndOthers from '../Common/DataGridFunctionalities/DatRangePickerAndOthers';
import DataGridViewTemp from '../Common/DataGridViewTemp';

const Overview = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');

  const [date, setDate] = React.useState(['', '']);

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/getWalletRecord?limit=30&offset=${
          offset || 0
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`
      : `/apis/bizzdesk/organization/wallet/getWalletRecord?limit=30&offset=${
          offset || 0
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`;

  const tableCountUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/getWalletRecord/count?limit=30&offset=${
          offset || 0
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`
      : `/apis/bizzdesk/organization/wallet/getWalletRecord/count?limit=30&offset=${
          offset || 0
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`;

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableCount } = useSWR(
    [tableCountUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          merchants: item?.fullName,
          walletid: item?.walletId,
          walletbalanceN: item?.walletBalance,
          createdon: item?.createOn,
          action: {
            link: `/wallet/${item?.userName}`,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'merchants',
    },
    {
      headerName: 'wallet id',
    },
    {
      headerName: 'wallet balance',
    },
    {
      headerName: 'created on',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <>
      <DataGridViewTemp
        title="All Merchants Wallets"
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
        hasExportBtn={tableData}
        pagination
        recordCount={tableCount || 0}
        page={offset / 30 + 1}
      >
        <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
          <DatRangePickerAndOthers date={date} setDate={setDate} />
        </div>
      </DataGridViewTemp>
    </>
  );
};

export default Overview;
