import { Box} from '@chakra-ui/react';
import { Box as MUIBox } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import BankCard from './transaction/BankCard.jsx';
import Payout from './transaction/Payout.jsx';
import Overview from './transaction/Overview.jsx';
import Ussd from './transaction/Ussd.jsx';
import BankTransfer from './transaction/BankTransfer.jsx';
import Barter from './transaction/Barter.jsx';
import Paga from './transaction/Paga.jsx';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import TabPanel from '../Common/TabPanel';
import FilterBox from '../Common/DataGridFunctionalities/FilterBox';

const Signup = () => {
  const navigate = useNavigate();

  const [subtabValue, setsubtabValue] = React.useState(0);
  const [method, setMethod] = React.useState(null);

  // get tab value from url param
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const subtab = urlParams.get('subtab');

    if (subtab) {
      if (parseInt(subtab) > 0 && parseInt(subtab) < 6) {
        switch (parseInt(subtab)) {
          case 1:
            setMethod('Bank Card');
            break;
          case 2:
            setMethod('USSD');
            break;
          case 3:
            setMethod('Bank Transfer');
            break;
          case 4:
            setMethod('Barter');
            break;
          case 5:
            setMethod('Paga');
            break;
          default:
            break;
        }
      }

      setsubtabValue(parseInt(subtab));
    }
  }, []);

  React.useEffect(() => {
    if (method) {
      let index;

      switch (method) {
        case 'Bank Card':
          index = 1;
          break;
        case 'USSD':
          index = 2;
          break;
        case 'Bank Transfer':
          index = 3;
          break;
        case 'Barter':
          index = 4;
          break;
        case 'Paga':
          index = 5;
          break;
        default:
          index = 0;
          break;
      }

      navigate({
        pathname: '/checkout',
        search: `?tab=3&subtab=${index}`,
      });

      setsubtabValue(index);
    }
  }, [method, navigate]);

  const Tab = ({ label, index }) => {
    return (
      <button
        css={[
          tw`text-black text-sm 2xl:text-base py-1.5 md:py-2.5 2xl:py-3.5 px-2 xl:px-4 tracking-[-0.025em] font-medium`,
          index === subtabValue &&
            tw`text-white bg-[#6500E0] rounded-lg xl:rounded-[12px]`,
        ]}
        onClick={() => {
          navigate({
            pathname: '/checkout',
            search: `?tab=3&subtab=${index}`,
          });

          setsubtabValue(index);
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <Box>
      <MUIBox sx={{ width: '100%', marginTop: '24px' }}>
        <MUIBox
          sx={{
            paddingBottom: {
              xs: '20px',
              lg: '40px',
            },
          }}
        >
          <div tw="space-x-4 flex items-center">
            <Tab label="Overview" index={0} />
            <Tab label="Checkout Payout" index={6} />
            <FilterBox
              dropdownData={[
                'Payment Methods',
                'Bank Card',
                'USSD',
                'Bank Transfer',
                'Barter',
                'Paga',
              ]}
              value={method}
              setFilterValue={setMethod}
              hasNoLabel
              hasNoLeftElement
            />
          </div>
        </MUIBox>
        <TabPanel tabvalue={subtabValue} index={0}>
          <Overview />
        </TabPanel>
        <TabPanel tabvalue={subtabValue} index={1}>
          <BankCard />
        </TabPanel>
        <TabPanel tabvalue={subtabValue} index={2}>
          <Ussd />
        </TabPanel>
        <TabPanel tabvalue={subtabValue} index={3}>
          <BankTransfer />
        </TabPanel>
        <TabPanel tabvalue={subtabValue} index={4}>
          <Barter />
        </TabPanel>
        <TabPanel tabvalue={subtabValue} index={5}>
          <Paga />
        </TabPanel>
        <TabPanel tabvalue={subtabValue} index={6}>
          <Payout />
        </TabPanel>
      </MUIBox>
    </Box>
  );
};

export default Signup;
