/** @jsxImportSource @emotion/react */
import { Box, Skeleton, Text } from '@chakra-ui/react';
import React from 'react';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import FilterBox from './DataGridFunctionalities/FilterBox';

const OverviewCard = ({
  title,
  value,
  countValue,
  loading,
  hasFilter,
  setFilter,
  hasTerminalNo,
}) => {
  return (
    <Box
      p=".75rem"
      display="flex"
      flexDirection="column"
      gap="3"
      w={'100%'}
      border="1px solid #E4ECF7"
      borderRadius="8px"
    >
      <div tw="w-3/5 ml-auto" css={[hasFilter ? tw`visible` : tw`invisible`]}>
        <FilterBox
          dropdownData={[
            'Today',
            'Yesterday',
            'This Week',
            'This Month',
            'Last Month',
            'This Year',
          ]}
          value={hasFilter}
          setFilterValue={setFilter}
          hasNoLabel
        />
      </div>
      <Skeleton isLoaded={loading}>
        <Text
          fontSize={['2rem', '1.35rem', '1.35rem', '1.75rem']}
          display="flex"
          gap="3"
          whiteSpace="nowrap"
          fontWeight="700"
          color="#101225"
        >
          {value}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={loading}>
        <Text fontSize="15px" fontWeight="500">
          {countValue}
        </Text>
      </Skeleton>
      <Skeleton isLoaded={loading}>
        <Text
          fontSize={['1rem', '.85rem', '.85rem', '.85rem']}
          overflow="hidden"
          whiteSpace="nowrap"
          fontWeight="400"
          color="#425D8A"
          mt={countValue ? '0' : '1.1rem'}
        >
          {title}
        </Text>
      </Skeleton>
      {hasTerminalNo && (
        <Skeleton isLoaded={loading}>
          <Text
            fontSize={['1rem', '.85rem', '.85rem', '.85rem']}
            overflow="hidden"
            whiteSpace="nowrap"
            fontWeight="400"
            color="#425D8A"
            mt={'4px'}
            tw="flex flex-col"
          >
            <span tw="font-medium text-[15px] text-gray-900">
              {hasTerminalNo}
            </span>
            <span tw= "mt-1">Total Terminal that Transacted</span>
          </Text>
        </Skeleton>
      )}
    </Box>
  );
};

export default OverviewCard;
