import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

function AddSubadminModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = React.useState(false);
  const [val, setVal] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  const validates = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/rolesAndPermissions/roles/getAllRoles',
    })
      .then(response => {
        setLoading(false);
        setVal(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  React.useEffect(() => {
    validates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let toast = useToast();
  // eslint-disable-next-line
  const [loadings, setLoadings] = React.useState(false);
  //   validation

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email is required'),
    fullNameOfAdmin: Yup.string().required('Full Name of Admin is required'),
    password: Yup.string().required('Password is required'),
    phoneNumber: Yup.string().required('Phone number is required'),
    role: Yup.string().required('Role is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const validate = (data, event) => {
    event.preventDefault();
    const input = {
      email: data?.email,
      fullNameOfAdmin: data?.fullNameOfAdmin,
      password: data?.password,
      phoneNumber: data?.phoneNumber,
      role: data?.role,
    };

    setLoadings(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/SubAdmin/add',
      data: input,
    })
      .then(response => {
        setLoadings(false);
        toast({
          title: 'A New Sub-admin Created!.',
          position: 'top',
          description: 'A New Role has been Created Successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: 'It is not you, it i us, wait as we resolve the issue.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoadings(false);
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Image src="/assets/add-square.png" alt="" />}
        bg="#6500E0"
        w="149px"
        h="42px"
        borderRadius="8px"
        colorScheme="#6500E0"
        variant="solid"
      >
        Add sub-admin
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Add admin user
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <form onSubmit={handleSubmit(validate)}>
              <Box
                mt="1rem"
                p="1rem"
                display="flex"
                flexDirection="column"
                gap="3"
              >
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Name
                  </Text>
                  <Input
                    placeholder=""
                    {...register('fullNameOfAdmin')}
                    border={
                      errors.fullNameOfAdmin
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.title?.fullNameOfAdmin}
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Email
                  </Text>
                  <Input
                    placeholder=""
                    {...register('email')}
                    border={
                      errors.email ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="mail"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.title?.email}
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Phone Number
                  </Text>
                  <Input
                    placeholder=""
                    {...register('phoneNumber')}
                    border={
                      errors.phoneNumber
                        ? '1px solid red'
                        : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.title?.phoneNumber}
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Role
                  </Text>
                  <Select
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                    {...register('role')}
                    border={
                      errors.role ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    fontSize="13px"
                    placeholder="Select Band"
                  >
                    {loading ? (
                      <>
                        <Skeleton
                          _hover={{ background: '#F3F7FC' }}
                          borderBottom="1px solid #ccc"
                          p="1rem"
                        ></Skeleton>
                        <Skeleton
                          _hover={{ background: '#F3F7FC' }}
                          borderBottom="1px solid #ccc"
                          p="1rem"
                        ></Skeleton>
                        <Skeleton
                          _hover={{ background: '#F3F7FC' }}
                          borderBottom="1px solid #ccc"
                          p="1rem"
                        ></Skeleton>
                      </>
                    ) : Array?.isArray(val) ? (
                      val?.map(e => {
                        return (
                          <Text
                            as="option"
                            cursor="pointer"
                            value={e?.roleTitle}
                            _hover={{ background: '#F3F7FC' }}
                            borderBottom="1px solid #ccc"
                            p="1rem"
                          >
                            {' '}
                            {loading ? 'Band A' : e?.roleTitle}{' '}
                          </Text>
                        );
                      })
                    ) : null}
                  </Select>
                  <Text color="red" mt=".5rem">
                    {errors.title?.role}
                  </Text>
                </VStack>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Password
                  </Text>
                  <Input
                    placeholder=""
                    {...register('password')}
                    border={
                      errors.password ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.title?.password}
                  </Text>
                </VStack>
                <Center w="full">
                  {loadings ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Add Admin User
                    </Button>
                  )}
                </Center>
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddSubadminModal;
