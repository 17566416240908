/** @jsxImportSource @emotion/react */
import { Box, HStack, Image, Select, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import useSWR from 'swr';
import { swrfetcher } from '../../utils/swrFetcher';
import moment from 'moment';
import MainCard from '../Common/MainCard';
import OverviewCard from '../Common/OverviewCard';
import { ErrorBoundary } from '../ErrorBoundary';
import ApexChart from './MerchantChart';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import MerchantDonutChart from './MerchantDonutChart';
import numberFormatter from '../../utils/numberFormatter';

const Home = () => {
  const [day, setDay] = React.useState('Today');
  const [date, setDate] = React.useState([moment().format('yyyy-MM-DD'), null]);

  React.useEffect(() => {
    if (day === 'Today') {
      setDate([moment().startOf('day').format('yyyy-MM-DD'), null]);
    } else if (day === 'Yesterday') {
      setDate([
        moment().subtract(1, 'days').startOf('day').format('yyyy-MM-DD'),
        null,
      ]);
    } else if (day === 'This Week') {
      setDate([moment().startOf('week').format('yyyy-MM-DD'), null]);
    } else if (day === 'This Month') {
      setDate([moment().startOf('month').format('yyyy-MM-DD'), null]);
    } else if (day === 'Last Month') {
      setDate([
        moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      ]);
    } else {
      setDate([moment().startOf('year').format('YYYY-MM-DD'), null]);
    }
  }, [day]);

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  let TbusinessName;

  if (userType !== 'admin') {
    TbusinessName = JSON?.parse(
      localStorage.getItem('businessName') ||
        sessionStorage.getItem('businessName')
    );
  }

  const walletUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/wallet/analytics/getAmountInWallets'
      : '/apis/bizzdesk/organization/wallet/getIncomeWalletBalance';
  const transactionsUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/terminals/analytics'
      : '/apis/bizzdesk/organization/terminals/analytics';
  const mainWalletBalanceUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/accounts/mainWallet/getBalance'
      : '/apis/bizzdesk/organization/wallet/getIncomeWalletBalance';
  const valsUrl =
    userType === 'admin'
      ? '/apis/v1/bizzdesk/admin/merchants/analytics'
      : '/apis/bizzdesk/organization/businessMerchants/analytics';
  const bbmUrl =
    '/apis/v1/bizzdesk/admin/settlement/analytics/settlementOverview';
  const valueUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/transaction/POS/getMetrics?startDate=${
          date[0]
        }${!date[1] ? '' : `&endDate=${date[1]}`}`
      : `/apis/bizzdesk/organization/transaction/s/pos/getMetrics?startDate=${
          date[0]
        }${!date[1] ? '' : `&endDate=${date[1]}`}`;
  const payoutcUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/transaction/WalletWithdrawal/getMetrics?startDate=${
          date[0]
        }${!date[1] ? '' : `&endDate=${date[1]}`}`
      : `/apis/bizzdesk/organization/transaction/WalletWithdrawal/getMetrics?startDate=${
          date[0]
        }${!date[1] ? '' : `&endDate=${date[1]}`}`;

  const incomeBalanceUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/transaction/Income/getMetrics?startDate=${
          date[0]
        }${!date[1] ? '' : `&endDate=${date[1]}`}`
      : `/apis/bizzdesk/organization/transaction/Income/getMetrics?startDate=${
          date[0]
        }${!date[1] ? '' : `&endDate=${date[1]}`}`;

  const { data: wallet } = useSWR(
    [walletUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: payout } = useSWR(
    [payoutcUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: mainWalletBalance } = useSWR(
    [mainWalletBalanceUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: val } = useSWR(
    [transactionsUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: bbm } = useSWR(
    [bbmUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: value } = useSWR(
    [valueUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: vals } = useSWR(
    [valsUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: incomeBalanceData } = useSWR(
    [incomeBalanceUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  return (
    <ErrorBoundary>
      <Box>
        <Text
          fontSize={['2rem', '1.35rem', '1.35rem', '1.75rem']}
          color="#16192C"
          fontWeight="700"
        >
          Welcome{' '}
          {userType === 'organization'
            ? TbusinessName
            : 'to Paysure Business Merchant'}
        </Text>
        {/* Main Cards */}
        <Box
          display="flex"
          gap="4"
          flexWrap={'wrap'}
          flexDirection={['column', 'row']}
          mt="2rem"
        >
          <MainCard
            title="Total Terminals"
            value={val?.totalTerminals.toLocaleString()}
            activeValue={val?.totalActiveTerminals.toLocaleString()}
            inActiveValue={val?.totalInactiveTerminals.toLocaleString()}
            loading={val}
          />
          <MainCard
            title="Total Business Merchants"
            value={vals?.totalAgents.toLocaleString()}
            activeValue={vals?.totalActiveAgents.toLocaleString()}
            inActiveValue={vals?.totalInactiveAgents.toLocaleString()}
            loading={vals}
          />
          {userType === 'admin' && (
            <MainCard
              title="Total Organizations"
              value={0}
              activeValue={0}
              inActiveValue={0}
              loading={vals}
            />
          )}
          <MainCard
            title="Main Wallet Balance"
            value={
              <CurrencyFormat
                value={
                  userType === 'admin'
                    ? mainWalletBalance?.availableBalance
                    : mainWalletBalance?.data?.walletBalance
                }
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₦'}
              />
            }
            loading={mainWalletBalance}
            isPlain
          />
          <MainCard
            title="Income Wallet Balance"
            value={
              <CurrencyFormat
                value={
                  userType === 'admin'
                    ? wallet?.bizzdeskIncomeWallet
                    : wallet?.data?.walletBalance
                }
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₦'}
              />
            }
            loading={mainWalletBalance}
            isPlain
          />
        </Box>

        {/* Transaction Overview */}
        <Box mt="10">
          <Text color="#16192C" fontSize="16px" fontWeight="500">
            POS Transaction overview (Inbound)
          </Text>
          <Box mt="1rem" gap="6" tw="grid grid-cols-2 md:grid-cols-3">
            <OverviewCard
              title="Total Successful Transactions"
              value={
                <CurrencyFormat
                  value={value?.totalSuccessTransactionsAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₦'}
                />
              }
              countValue={numberFormatter(value?.totalSuccessTransactionsCount)}
              loading={value}
              hasFilter={day}
              setFilter={setDay}
              hasTerminalNo={numberFormatter(
                value?.totalSuccessTransactionsCountByTid
              )}
            />

            <OverviewCard
              title="Total Pending Transactions"
              value={
                <CurrencyFormat
                  value={value?.totalPendingTransactionsAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₦'}
                />
              }
              countValue={numberFormatter(value?.totalPendingTransactionsCount)}
              loading={value}
            />

            <OverviewCard
              title="Total Failed Transactions"
              value={
                <CurrencyFormat
                  value={value?.totalFailedTransactionsAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₦'}
                />
              }
              countValue={numberFormatter(value?.totalFailedTransactionsCount)}
              loading={value}
            />
          </Box>
        </Box>

        {/* Settlement Overview */}
        <Box mt="10">
          <Text color="#16192C" fontSize="16px" fontWeight="500">
            Wallet Withdrawal Transaction overview (Outbound)
          </Text>
          <Box mt="1rem" gap="6" tw="grid grid-cols-2 md:grid-cols-3">
            <OverviewCard
              title="Business Merchant Payout"
              value={
                <CurrencyFormat
                  value={payout?.totalSuccessTransactionsAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₦'}
                />
              }
              countValue={numberFormatter(
                payout?.totalSuccessTransactionsCount
              )}
              loading={payout}
            />

            <OverviewCard
              title={
                userType === 'admin'
                  ? 'Paysure Income Balance'
                  : 'Organization Income Balance'
              }
              value={
                <CurrencyFormat
                  value={incomeBalanceData?.totalSuccessTransactionsAmount}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₦'}
                />
              }
              loading={wallet}
            />

            {userType === 'admin' && (
              <OverviewCard
                title="Organization Wallet"
                value={
                  <CurrencyFormat
                    value={0}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'₦'}
                  />
                }
                countValue={numberFormatter(0)}
                loading={bbm}
              />
            )}
          </Box>
        </Box>

        {/* chart section */}
        <Box
          display="flex"
          w="full"
          justifyContent="center"
          gap="6"
          h="100%"
          alignItems="center"
          mt="3rem"
          flexDirection={['column', 'row']}
        >
          <Box
            mt="1rem"
            w={['100%', '100%']}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
          >
            <Box w="full" display="flex" justifyContent="space-between">
              <VStack alignItems="flex-start" float="left">
                <Text color="#A2A2B9" fontWeight="400" fontSize="14px">
                  Income from Business Merchants
                </Text>
                <HStack>
                  <Text fontWeight="500" fontSize="24px">
                    N342k
                  </Text>
                  <Text fontWeight="500" color="#136F63" fontSize="12px">
                    73%{' '}
                    <Text
                      as="span"
                      color="#A2A2B9"
                      fontWeight="500"
                      fontSize="12px"
                    >
                      vs Last year
                    </Text>
                  </Text>
                </HStack>
              </VStack>
              <Select
                color="#425D8A"
                bg="#EBF2FA"
                w="109px"
                fontSize="13px"
                fontWeight="500"
                textTransform="capitalize"
              >
                <option style={{ padding: '.75rem' }}>Last year</option>
                <option style={{ padding: '.75rem' }}>2021</option>
                <option style={{ padding: '.75rem' }}>2020</option>
              </Select>
            </Box>
            <ApexChart />
          </Box>
        </Box>

        {/* Pie chart section */}
        <Box
          display="flex"
          w="full"
          justifyContent="space-between"
          gap="3"
          mt="3rem"
          h="100%"
        >
          <Box
            w="full"
            display="flex"
            gap={['0', '4']}
            h="100%"
            flexDirection={['column', 'column', 'column', 'row']}
          >
            <VStack
              p={['1rem', '0']}
              alignItems={['center', 'flex-start']}
              gap="3"
            >
              <Text
                color="#A2A2B9"
                textTransform="uppercase"
                fontWeight="500"
                fontSize="16px"
              >
                transactions
              </Text>
              <MerchantDonutChart />
              <VStack alignItems="flex-start">
                <Text color="#A2A2B9" fontWeight="400" fontSize="12px">
                  4,343 declined transaction
                </Text>
                <HStack>
                  <Image src="assets/Ellipse 5.png" alt="" />
                  <Text color="#191716" fontWeight="400" fontSize="16px">
                    Approved Transactions
                  </Text>
                </HStack>
                <HStack>
                  <Image src="assets/Ellipse 6.png" alt="" />
                  <Text color="#191716" fontWeight="400" fontSize="16px">
                    Declined Transaction
                  </Text>
                </HStack>
              </VStack>
            </VStack>
            <VStack
              p={['1rem', '0']}
              alignItems={['center', 'flex-start']}
              gap="3"
            >
              <Text
                color="#A2A2B9"
                textTransform="uppercase"
                fontWeight="500"
                fontSize="16px"
              >
                settlements
              </Text>
              <MerchantDonutChart />
              <VStack alignItems="flex-start">
                <Text color="#A2A2B9" fontWeight="400" fontSize="12px">
                  N34,204,343 Settlements made
                </Text>
                <HStack>
                  <Image src="assets/Ellipse 1.png" alt="" />
                  <Text color="#191716" fontWeight="400" fontSize="16px">
                    {userType === 'organization' ? 'Organization' : 'PAYSURE'}{' '}
                    INCOME``
                  </Text>
                </HStack>
                <HStack>
                  <Image src="assets/Ellipse 2.png" alt="" />
                  <Text color="#191716" fontWeight="400" fontSize="16px">
                    BUSINESS MERCHANTS SETTLEMENT
                  </Text>
                </HStack>
              </VStack>
            </VStack>
          </Box>
        </Box>
      </Box>
    </ErrorBoundary>
  );
};

export default Home;
