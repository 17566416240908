import {
  Box,
  HStack,
  Image,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';

const Show = () => {
  return (
    <>
      <Tr>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
          {' '}
          Edit
        </Td>
        <Td color="#ED6A5A" cursor="pointer" fontSize="14px" fontWeight="400">
          {' '}
          Delete
        </Td>
      </Tr>
      <Tr>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
          {' '}
          Edit
        </Td>
        <Td color="#ED6A5A" cursor="pointer" fontSize="14px" fontWeight="400">
          {' '}
          Delete
        </Td>
      </Tr>
      <Tr>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" fontWeight="400">
          <Skeleton w="50%" h="20px"></Skeleton>
        </Td>
        <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
          {' '}
          Edit
        </Td>
        <Td color="#ED6A5A" cursor="pointer" fontSize="14px" fontWeight="400">
          {' '}
          Delete
        </Td>
      </Tr>
    </>
  );
};

const TableUI = ({ id, role, permission }) => {
  return (
    <Tr>
      <Td fontSize="14px" fontWeight="400">
        {id}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {role}
      </Td>
      <Td fontSize="14px" fontWeight="400">
        {permission}
      </Td>
      <Td fontSize="14px" cursor="pointer" color="#95C7EC" fontWeight="400">
        {' '}
        Edit
      </Td>
      <Td color="#ED6A5A" cursor="pointer" fontSize="14px" fontWeight="400">
        {' '}
        Delete
      </Td>
    </Tr>
  );
};

const ListofRoles = () => {
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [val, setVal] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const validate = () => {
    setLoading(true);

    axios({
      method: 'get',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
        'Content-type': 'Application/json',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/rolesAndPermissions/roles/getAllRoles',
    })
      .then(response => {
        setLoading(false);
        setVal(response.data);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box display="flex" h={['100%', '100vh']} w="full">
      <Box
        w="35%"
        display="flex"
        h="75vh"
        justifyContent="center"
        alignItems="center"
        mt={['0', '-5rem']}
        borderRight="1px solid #E4ECF7"
      >
        <HStack gap="6">
          <Image src="assets/perm.png" alt="" w="24px" h="24px" />
          <Text fontWeight="500" color="#6500E0" fontSize="16px">
            Permissions
          </Text>
        </HStack>
      </Box>
      <Box
        w="65%"
        display="flex"
        h="100%"
        p="2rem"
        alignItems="flex-start !important"
        flexDirection="column"
      >
        <Text fontWeight="400" color=" #191716" fontSize="20px">
          List of Roles
        </Text>
        <TableContainer mt="1.5rem">
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th
                  fontSize="14px"
                  fontWeight="400"
                  color="#505780"
                  textTransform="uppercase"
                >
                  s/n
                </Th>
                <Th
                  fontSize="14px"
                  fontWeight="400"
                  color="#505780"
                  textTransform="uppercase"
                >
                  Role
                </Th>
                <Th
                  fontSize="14px"
                  fontWeight="400"
                  color="#505780"
                  textTransform="uppercase"
                >
                  Permissions
                </Th>
                <Th
                  fontSize="14px"
                  fontWeight="400"
                  color="#505780"
                  textTransform="uppercase"
                >
                  Actions
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? (
                <Show />
              ) : Array?.isArray(val) ? (
                val?.map((data, index) => (
                  <TableUI
                    key={data?.roleTitle}
                    id={index + 1}
                    role={data?.roleTitle || '-'}
                    permission={data?.description || '-'}
                  />
                ))
              ) : null}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default ListofRoles;
