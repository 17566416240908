/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  HStack,
  Image,
  Skeleton,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import OverviewCard from '../Common/OverviewCard';
import WalletBalance from '../Common/WalletBalance';
import EditMerchant from './Modals/EditMerchant';
// eslint-disable-next-line no-unused-vars
import axios from 'axios';
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';
import DatRangePickerAndOthers from '../Common/DataGridFunctionalities/DatRangePickerAndOthers';
import FilterBox from '../Common/DataGridFunctionalities/FilterBox';
import SearchBar from '../Common/DataGridFunctionalities/SearchBar';
import DataGridViewTemp from '../Common/DataGridViewTemp';
import EditTerminalModal from '../Terminals/Modals/EditTerminalModal';
import ReassignTerminalModal from '../Terminals/Modals/ReassignTerminalModal';
import ViewTerminal from '../Terminals/Modals/ViewTerminal';

const MerchantProfiles = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');
  const transOffset = query.get('transOffset');
  const { userId } = useParams();
  const [loading] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const [date, setDate] = React.useState(['', '']);
  const [transsearchValue, setTransSearchValue] = React.useState('');
  const [transType, setTransType] = React.useState('');
  const [transdate, setTransDate] = React.useState(['', '']);
  let toast = useToast();

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const valUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/checker/getUserDetals?userName=${userId}`
      : `/apis/bizzdesk/organization/checker/getUserDetals?userName=${userId}`;
  const transMetricUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/merchant/analytics/getTransactionMetricsByMerchant?userName=${userId}`
      : `/apis/bizzdesk/organization/merchant/analytics/getTransactionMetricsByMerchant?userName=${userId}`;
  const detailUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/signup/usersInformation?userName=${userId}`
      : `/apis/bizzdesk/organization/usersInformation?userName=${userId}`;

  const terminalsTableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/terminals/getTerminals?limit=30&offset=${
          offset || 0
        }&merchantUsername=${userId}${
          !searchValue ? '' : `&tid=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`
      : `/apis/bizzdesk/organization/terminals/getTerminals?limit=30&offset=${
          offset || 0
        }&merchantUsername=${userId}${
          !searchValue ? '' : `&tid=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`;

  const terminalsTableCountUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/terminals/getTerminals/count?limit=30&offset=${
          offset || 0
        }&merchantUsername=${userId}${
          !searchValue ? '' : `&tid=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`
      : `/apis/bizzdesk/organization/terminals/getTerminals/count?limit=30&offset=${
          offset || 0
        }&merchantUsername=${userId}${
          !searchValue ? '' : `&tid=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }`;

  const listTransTableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/terminals/admin/admin/analytics/getTerminalTransRecByMerchant?limit=30&offset=${
          transOffset || 0
        }&merchantUserName=${userId}${
          !searchValue ? '' : `&tid=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }${!transType ? '' : `&transType=${transType}`}`
      : `/apis/bizzdesk/organization/admin/analytics/getTerminalTransRecByMerchant?limit=30&offset=${
          transOffset || 0
        }&merchantUserName=${userId}${
          !searchValue ? '' : `&tid=${searchValue}`
        }${!date[0] ? '' : `&startDate=${date[0]}`}${
          !date[1] ? '' : `&endDate=${date[1]}`
        }${!transType ? '' : `&transType=${transType}`}`;

  const disableUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/actions/suspendAgent`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/businessMerchants/actions/suspendAgent`;

  const activateUrl =
    userType === 'admin'
      ? `https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/actions/activateAgent`
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/businessMerchants/actions/activateAgent`;

  const { data: val } = useSWR(
    [valUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: metrics } = useSWR(
    [transMetricUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: detail } = useSWR(
    [detailUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableData, isValidating } = useSWR(
    [terminalsTableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableCount } = useSWR(
    [terminalsTableCountUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const [disabling, setDisabling] = React.useState(false);

  const handleActivateAccount = () => {
    setDisabling(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: activateUrl,
      data: {
        reason: '',
        userName: val?.email,
      },
    })
      .then(response => {
        toast({
          // title: 'Failed!',
          position: 'top',
          description: 'Merchant Activated Successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setDisabling(false);
      })
      .catch(err => {
        toast({
          title: 'Failed!',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setDisabling(false);
      });
  };

  const handleDisableAccount = () => {
    setDisabling(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: disableUrl,
      data: {
        reason: '',
        userName: val?.email,
      },
    })
      .then(response => {
        toast({
          // title: 'Failed!',
          position: 'top',
          description: 'Merchant Disabled Successfully',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        setDisabling(false);
      })
      .catch(err => {
        toast({
          title: 'Failed!',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setDisabling(false);
      });
  };

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: Number(offset || 0) + index + 1,
          terminalId: item?.tid,
          serialno: item?.serialNo,
          bank: item?.bank,
          nooftransactions: item?.transactions,
          transactionN: item?.transactionAmount,
          status: item?.status,
          datecreated: item?.transactionDate,
          action: {
            edit: (
              <EditTerminalModal
                serialnumber={item?.serialNo}
                email={item?.agentEmail}
                userEmail={item?.tid}
              />
            ),
            assign: (
              <ReassignTerminalModal
                serialno={item?.serialNo}
                status={item?.status}
                email={item?.agentEmail}
                userTid={item?.tid}
              />
            ),
            wallet: (
              <ViewTerminal
                serialnumber={item?.serialNo}
                userEmail={item?.tid}
              />
            ),
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 's/n',
    },
    {
      headerName: 'terminal id',
    },
    {
      headerName: 'serial no',
    },
    {
      headerName: 'bank',
    },
    {
      headerName: 'no. of transactions',
    },
    {
      headerName: 'Transaction Amount (N)',
    },
    {
      headerName: 'Status',
    },
    {
      headerName: 'Date added',
    },
    {
      headerName: 'Action',
    },
  ];

  const { data: listTransTableData, isValidating: isValidatingListTrans } =
    useSWR([listTransTableUrl, token, 'GET'], ([url, USER_TOKEN, method]) =>
      swrfetcher(url, USER_TOKEN, method)
    );

  let listOfTransRows = [];
  if (listTransTableData) {
    if (listTransTableData?.status >= 400) {
      listOfTransRows = [];
    } else {
      listOfTransRows = listTransTableData?.map((item, index) => {
        return {
          id: Number(transOffset || 0) + index + 1,
          merchantname: item?.fullName,
          rrn: item?.rrn,
          terminalref: item?.ref,
          terminalid: item?.tid,
          bank: item?.bank,
          panaccount: item?.panAccount,
          cardtype: item?.cardType,
          currency: item?.currency,
          stan: item?.stan,
          authcode: item?.authCode,
          transactiontype: item?.transtype,
          amountN: item?.amount,
          bizzdeskcharges: item?.bizzdeskCharges,
          receiveableamountN: item?.receivableAmount,
          datetime: item?.date,
          status: item?.status,
          responsecode: item?.response === 'COMPLETED' ? '00' : item?.response,
          action: {
            link:
              item?.transtype === 'Purchase'
                ? `/transactions/${item?.ref}`
                : item?.transtype === 'WALLET WITHDRAWAL'
                ? `/transactions/wallet/${item?.ref}`
                : '',
            print: true,
          },
        };
      });
    }
  }

  const listTransColumn = [
    {
      headerName: 's/n',
    },
    {
      headerName: 'merchant name',
    },
    {
      headerName: 'rrn',
    },
    {
      headerName: 'transaction ref',
    },
    {
      headerName: 'terminal id',
    },
    {
      headerName: 'bank',
    },
    {
      headerName: 'pan account',
    },
    {
      headerName: 'card type',
    },
    {
      headerName: 'currency',
    },
    {
      headerName: 'stan',
    },
    {
      headerName: 'auth code',
    },
    {
      headerName: 'transaction type',
    },
    {
      headerName: 'amount',
    },
    {
      headerName: 'paysure charges',
    },
    {
      headerName: 'receiveable amount',
    },
    {
      headerName: 'date/time',
    },
    {
      headerName: 'status',
    },
    {
      headerName: 'response code',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <Box tw="mb-10">
      <button
        onClick={() => navigate(-1)}
        style={{ fontSize: '14px', fontWeight: '300' }}
        tw="mb-4 hover:underline"
      >
        Back
      </button>
      <Box
        display="flex"
        gap={['3', '0']}
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        w="full"
        mt="1rem"
      >
        <HStack float="left" gap="3">
          <Image
            w="70px"
            h="70px"
            src={detail?.profilePic || '/assets/merchantframe.png'}
            alt=""
          />
          <VStack alignItems="flex-start">
            <Skeleton isLoaded={val}>
              <Text fontWeight="600" fontSize="24px">
                {`${val?.firstName} ${val?.lastName}`}
              </Text>
            </Skeleton>
            <Skeleton w="fit-content" isLoaded={detail}>
              <Text
                padding="4px"
                borderRadius="4"
                bg={
                  val?.status?.toLowerCase() === 'active'
                    ? '#E9FBF9'
                    : val?.status?.toLowerCase() === 'inactive'
                    ? '#FCEAE8'
                    : '#E4ECF7'
                }
                fontWeight="400"
                fontSize="10px"
                color={
                  val?.status?.toLowerCase() === 'active'
                    ? '#136F63'
                    : val?.status?.toLowerCase() === 'inactive'
                    ? '#EDA95A'
                    : '#A6B7D4'
                }
                tw="capitalize"
              >
                {val?.status}
              </Text>
            </Skeleton>
          </VStack>
        </HStack>
        {detail && (
          <Box
            display="flex"
            flexDirection={['column', 'row']}
            gap="3"
            float="right"
          >
            <EditMerchant />
            <Button
              leftIcon={<Image src="/assets/dislike.png" />}
              bg="#F3F7FC"
              tw="px-2.5"
              borderRadius="8px"
              color="#6500E0"
              variant="solid"
            >
              Downgrade to Basic
            </Button>
            {val?.status?.toLowerCase() === 'active' ? (
              <Button
                bg="#ED6A5A"
                tw="px-2.5"
                borderRadius="8px"
                color="#fff"
                variant="solid"
                isLoading={disabling}
                onClick={handleDisableAccount}
                loadingText="Loading"
              >
                Disable account
              </Button>
            ) : (
              <Button
                bg="#136F63"
                tw="px-2.5"
                borderRadius="8px"
                color="#fff"
                variant="solid"
                isLoading={disabling}
                onClick={handleActivateAccount}
                loadingText="Loading"
              >
                Activate account
              </Button>
            )}
          </Box>
        )}
      </Box>
      {/* wallet balance */}
      <WalletBalance
        label="Total wallet balance"
        balance={detail?.walletDto?.walletBalance}
        data={detail}
        hasNoFundWallet
        hasNoWithdraw
      />
      <>
        <Box
          display="flex"
          gap="6"
          justifyContent="space-between"
          w="full"
          flexDirection={['row', 'row']}
          mt="2rem"
        >
          <Text fontWeight="500" color="#191716" float="left">
            Transactions
          </Text>
          <HStack float="right">
            <Link style={{ color: '#6500E0' }} to="/">
              See all activities
            </Link>
            {/* <Image src="assets/merchant_blue_arrow_right.png" alt="" /> */}
          </HStack>
        </Box>

        <Box
          mt="1rem"
          gap="6"
          tw="grid grid-cols-2 md:grid-cols-3 xl:grid-cols-4"
        >
          <OverviewCard
            title="Total Transactions"
            value={
              <CurrencyFormat
                value={metrics?.totalAmountInTransaction}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₦'}
              />
            }
            loading={metrics}
          />

          <OverviewCard
            title="Total Completed Transactions"
            value={
              <CurrencyFormat
                value={metrics?.totalCompletedTransactions}
                displayType={'text'}
                thousandSeparator={true}
              />
            }
            loading={metrics}
          />

          <OverviewCard
            title="Total Failed Transactions"
            value={
              <CurrencyFormat
                value={metrics?.totalFailedTransactions}
                displayType={'text'}
                thousandSeparator={true}
              />
            }
            loading={metrics}
          />

          <OverviewCard
            title="Total Pending Transaction"
            value={
              <CurrencyFormat
                value={metrics?.totalPendingTransactions}
                displayType={'text'}
                thousandSeparator={true}
              />
            }
            loading={metrics}
          />
        </Box>
      </>

      <Box
        display="flex"
        mt="1.25rem "
        flexDirection={['column', 'column', 'column', 'row']}
        gap="3"
      >
        <Box
          w={['100%', '100%', '100%', '50%']}
          borderRadius="8px"
          p="1rem"
          border="1px solid #E4ECF7"
          display="flex"
          flexDirection="column"
          gap="3"
        >
          <Text fontSize="16px" fontWeight="500" mb="1rem">
            Merchant Information
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Email &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? <Skeleton w="50%" h="20px" /> : val?.email}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Account number: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? <Skeleton w="50%" h="20px" /> : detail?.accountNumber}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Address: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? (
                <Skeleton w="50%" h="20px" />
              ) : (
                detail?.businessAddress
              )}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            BVN: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? <Skeleton w="50%" h="20px" /> : val?.bvnNumber}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Phone number: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? <Skeleton w="50%" h="20px" /> : val?.phone}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Band &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? <Skeleton w="50%" h="20px" /> : detail?.band}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Charge: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? <Skeleton w="50%" h="20px" /> : detail?.chargePlan}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Support Staff &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              Okay Coker
            </Text>
          </Text>
        </Box>
        <Box
          w={['100%', '100%', '100%', '50%']}
          borderRadius="8px"
          p="1rem"
          border="1px solid #E4ECF7"
          display="flex"
          flexDirection="column"
          gap="3"
        >
          <Text fontSize="16px" fontWeight="500" mb="1rem">
            Wallet Information
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Account number: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? <Skeleton w="50%" h="20px" /> : detail?.accountNumber}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Create on: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? (
                <Skeleton w="50%" h="20px" />
              ) : (
                detail?.walletDto?.createOn
              )}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Available Balance: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? (
                <Skeleton w="50%" h="20px" />
              ) : (
                <CurrencyFormat
                  value={detail?.walletDto?.walletBalance}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'₦'}
                />
              )}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Wallet ID: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              {loading ? (
                <Skeleton w="50%" h="20px" />
              ) : (
                detail?.walletDto?.walletId
              )}
            </Text>
          </Text>
          <Text fontSize="16px" fontWeight="500" color="#50578">
            Charge back: &nbsp;
            <Text fontSize="16px" fontWeight="400" color="#16192C" as="span">
              0.005
            </Text>
          </Text>
        </Box>
      </Box>

      <DataGridViewTemp
        title={'Terminals'}
        hasMT
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
        hasExportBtn={tableData}
        pagination
        recordCount={tableCount || 0}
        page={offset / 30 + 1}
      >
        <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
          <SearchBar value={searchValue} setSearchValue={setSearchValue} />
          <DatRangePickerAndOthers date={date} setDate={setDate} />
        </div>
      </DataGridViewTemp>

      <DataGridViewTemp
        title={'List of Transactions'}
        hasMT
        rows={listOfTransRows}
        columns={listTransColumn}
        isValidating={isValidatingListTrans}
        hasExportBtn={listTransTableData}
        pagination
        recordCount={100}
        page={transOffset / 30 + 1}
        offsetLabel={'transOffset'}
      >
        <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
          <SearchBar
            value={transsearchValue}
            setSearchValue={setTransSearchValue}
          />
          <FilterBox
            dropdownData={['All', 'Purchase', 'Wallet Withdrawal']}
            value={transType}
            setFilterValue={setTransType}
          />
          <DatRangePickerAndOthers date={transdate} setDate={setTransDate} />
        </div>
      </DataGridViewTemp>
    </Box>
  );
};

export default MerchantProfiles;
