import {
  Box,
  Button,
  Center,
  // Select,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  // InputRightElement,
  // InputGroup,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
//   import { useForm } from 'react-hook-form';
//   import { yupResolver } from '@hookform/resolvers/yup';
//   import * as Yup from 'yup';
import axios from 'axios';
import image1 from '../../BusinessMerchant/assets/delete.png';
//import { useParams } from 'react-router-dom';

function DisableTerminal({ userEmail, serialnumber }) {
  // eslint-disable-next-line
  // const { userEmail } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [user, setUserDetails] = React.useState([]);

  // const handleChange = e => {
  //   setUserDetails({ ...user, [e.target.name]: e.target.value });
  // };

  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const [reason, setReason] = React.useState('');
  //   validation

  // const validationSchema = Yup.object().shape({
  //   email: Yup.string().required('Email is required'),
  // });
  // const formOptions = { resolver: yupResolver(validationSchema) };

  // const { register, handleSubmit, formState } = useForm(formOptions);
  // const { errors } = formState;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const suspendUrl =
    userType === 'admin'
      ? 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/actions/suspendTerminal'
      : `https://bbm.bizzdesk.ng:8080/apis/bizzdesk/organization/terminals/actions/suspendTerminal`;

  const validate = event => {
    event.preventDefault();
    const input = {
      serialnumber: serialnumber,
      tid: userEmail,
      reason: reason,
    };

    setLoading(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: suspendUrl,
      data: input,
    })
      .then(response => {
        setLoading(false);
        setUserDetails(response.data);
        toast({
          // title: 'Terminal has been disabled successfully!.',
          position: 'top',
          description: 'Terminal disabled successfully!',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        onClose();
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: err.response.data.message,
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
        onClose();
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        border="none"
        borderRadius="12px"
        w="full"
        h="55px"
        color="#fff"
        bg="#ED6A5A"
        _hover={{
          color: '#ED6A5A',
          bg: '#F3F7FC',
        }}
      >
        Disable
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Disable Terminal
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <Box
              mt="1rem"
              p="1rem"
              display="flex"
              flexDirection="column"
              gap="3"
            >
              <form method="POST" onSubmit={validate}>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Reason
                  </Text>
                  <Input
                    placeholder="Reason for disabling terminal"
                    value={reason}
                    onChange={e => setReason(e.target.value)}
                    border=" 1px solid #E3E5E8"
                    borderRadius="4px"
                    color="#333"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  {/* <Text color="red" mt=".5rem">
                      {errors.title?.serialnumber}
                    </Text> */}
                </VStack>
                <Center w="full">
                  {loading ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Confirm
                    </Button>
                  )}
                </Center>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default DisableTerminal;

// merchant and actie = true
// merchant and inactive = false
// !merchant and active = true
// !merchant and inactive = true
// write a tertiary contition to satify all the above

// if (merchant && active) {
//   return true
// } else if (merchant && !active) {
//   return false
// } else if (!merchant && active) {
//   return true
// } else if (!merchant && !active) {
//   return true
// }
