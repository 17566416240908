/** @jsxImportSource @emotion/react */
import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import * as Yup from 'yup';

function WithdrawModal({ open, setOpen, withdrawData }) {
  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  // const validate = (data, event) => {
  //   event.preventDefault();

  //   setLoading(true);

  //   axios({
  //     method: 'get',
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       'Access-Control-Allow-Origin': '*',
  //       'Content-type': 'Application/json',
  //     },
  //     url: '',
  //   })
  //     .then(response => {
  //       setLoading(false);
  //     })
  //     .catch(err => {
  //       setLoading(false);
  //     });
  // };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal borderRadius="8px" isOpen={open} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Withdrawal Details
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            {/* <h5 tw="text-center flex flex-col space-y-1">
              <span tw="text-gray-500">Amount</span>

              <CurrencyFormat
                value={withdrawData?.amount}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'₦'}
                tw="text-4xl font-bold"
              />
            </h5> */}

            <div tw="my-8 space-y-4 flex flex-col justify-center">
              <Field
                label="Amount"
                value={
                  <CurrencyFormat
                    value={withdrawData?.amount}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'₦'}
                  />
                }
              />
              <Field
                label="Transaction Fee"
                value={
                  <CurrencyFormat
                    value={withdrawData?.transferFee}
                    displayType={'text'}
                    thousandSeparator={true}
                    prefix={'₦'}
                  />
                }
              />
              <Button
                _hover={{
                  bg: '#5403b8',
                  color: 'white',
                }}
                bg="#6500E0"
                borderRadius="8px"
                colorScheme="#6500E0"
                variant="solid"
                tw="!mt-8"
              >
                Proceed
              </Button>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

const Field = ({ label, value }) => {
  return (
    <h6 tw="flex justify-between items-center text-xl font-bold">
      <span tw="font-normal text-gray-500">{label}</span>
      <span>{value}</span>
    </h6>
  );
};

export default WithdrawModal;
