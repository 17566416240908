import {
  Box,
  Button,
  Center,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

function AddRoleModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  let navigate = useNavigate();
  let toast = useToast();
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  //   validation

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );
  const validate = (data, event) => {
    event.preventDefault();
    const input = {
      description: data?.description,
      permissionTitle: data?.title,
      roleTitle: data?.title,
    };
    sessionStorage.setItem('roletitle', JSON.stringify(data?.title));

    setLoading(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/rolesAndPermissions/roles/saveRoles',
      data: input,
    })
      .then(response => {
        setLoading(false);
        toast({
          title: 'A New Role Created!.',
          position: 'top',
          description: 'A New Role has been Created Successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
        navigate('/roles/add-permission');
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: 'It is not you, it i us, wait as we resolve the issue.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Box
        onClick={onOpen}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        w={['100%', '170px']}
        h="100%"
        p="1rem"
        borderRadius="8px"
        bg="whitesmoke"
        gap="2"
        cursor="pointer"
        border="1px solid #E4ECF7"
      >
        <Image src="assets/add-role.png" alt="" />
        <Text
          bg="whitesmoke"
          fontWeight="500"
          color="#191716"
          borderRadius="4px"
          fontSize="16px"
        >
          Add new role
        </Text>
      </Box>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Add new role
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <Box
              mt="1rem"
              p="1rem"
              display="flex"
              flexDirection="column"
              gap="3"
            >
              <form onSubmit={handleSubmit(validate)}>
                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Title
                  </Text>
                  <Input
                    placeholder="Account Admin"
                    border={
                      errors.email ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    color="#000"
                    fontSize="14px"
                    outline="none"
                    id="email"
                    type="text"
                    {...register('title')}
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.title?.message}
                  </Text>
                </VStack>

                <VStack alignItems="flex-start">
                  <Text fontSize="13px" fontWeight="300" color="#454D54">
                    Description
                  </Text>
                  <Textarea
                    placeholder=""
                    border={
                      errors.email ? '1px solid red' : ' 1px solid #E3E5E8'
                    }
                    borderRadius="4px"
                    {...register('description')}
                    color="#000"
                    fontSize="14px"
                    outline="none"
                    type="text"
                    _focus={{ outline: 'none' }}
                    _active={{ outline: 'none' }}
                  />
                  <Text color="red" mt=".5rem">
                    {errors.description?.message}
                  </Text>
                </VStack>

                <Center w="full">
                  {loading ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Add Role
                    </Button>
                  )}
                </Center>
              </form>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AddRoleModal;
