/** @jsxImportSource @emotion/react */
import { Box, HStack, Image, Skeleton, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import { swrfetcher } from '../../utils/swrFetcher';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import DataGridViewTemp from '../Common/DataGridViewTemp';
import WalletBalance from '../Common/WalletBalance';

const WalletDetails = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line
  const { walletuserId } = useParams();

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const url =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/getUserProfileByUsername?username=${walletuserId}`
      : `/apis/bizzdesk/organization/wallet/getUserProfileByUsername?username=${walletuserId}`;

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/wallet/getWalletActivitiesByUsername?limit=10&offset=0&username=${walletuserId}`
      : `/apis/bizzdesk/organization/wallet/getWalletActivitiesByUsername?limit=10&offset=0&username=${walletuserId}`;

  const { data } = useSWR([url, token, 'GET'], ([url, USER_TOKEN, method]) =>
    swrfetcher(url, USER_TOKEN, method)
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        return {
          id: index + 1,
          transactiontype: item?.transType,
          description: item?.transinfo,
          debitN: item?.debit,
          creditN: item?.credit,
          balanceN: item?.newamount,
          date: item?.date,
          action: {
            link: `/wallet/${item?.userName}`,
            print: true,
          },
        };
      });
    }
  }

  const TransWalletColumn = [
    {
      headerName: 'S/N',
    },
    {
      headerName: 'transaction type',
    },
    {
      headerName: 'description',
    },
    {
      headerName: 'debit',
    },
    {
      headerName: 'credit',
    },
    {
      headerName: 'balance after',
    },
    {
      headerName: 'date',
    },
    {
      headerName: 'Action',
    },
  ];

  return (
    <Box>
      <button
        onClick={() => navigate(-1)}
        style={{ fontSize: '14px', fontWeight: '300' }}
        tw="mb-4 hover:underline"
      >
        Back
      </button>
      <Box
        display="flex"
        gap={['3', '0']}
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        w="full"
        mt="1rem"
      >
        <HStack float="left" gap="3">
          <Image src="/assets/merchantframe.png" alt="" />
          <VStack alignItems="flex-start">
            <Skeleton isLoaded={data}>
              <Text fontWeight="600" fontSize="24px" tw="capitalize">
                {data?.fullName.toLowerCase()}
              </Text>
            </Skeleton>
            <Skeleton isLoaded={data}>
              <Text fontWeight="400" fontSize="14px" color="#A6B7D4">
                {data?.noOfTerminals} Terminals
              </Text>
            </Skeleton>
          </VStack>
        </HStack>
      </Box>

      <WalletBalance
        data={data}
        balance={data?.walletBalance}
        label={'Main Wallet Balance'}
      />

      <DataGridViewTemp
        link={`/wallet/merchant-wallet-llist?username=${walletuserId}`}
        limited
        title="Total Wallet History"
        rows={rows}
        columns={TransWalletColumn}
        isValidating={isValidating}
      />
    </Box>
  );
};

export default WalletDetails;
