/** @jsxImportSource @emotion/react */
import {
  Box,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Box as MUIBox } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import Sidebar from '../component/Common/Sidebar';
import TabPanel from '../component/Common/TabPanel';
import Topnav from '../component/Common/Topnav';
import Income from '../component/Transactions/Income';
import Overview from '../component/Transactions/Overview';
import POSTransactions from '../component/Transactions/POSTransactions';
import ReceivableTrans from '../component/Transactions/ReceivableTrans';
import WalletWithdrawal from '../component/Transactions/WalletWithdrawal';

const Transaction = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const [tabValue, setTabValue] = React.useState(userType === 'admin' ? 0 : 1);

  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTabValue(parseInt(tab));
    }
  }, []);

  const Tab = ({ label, index }) => {
    return (
      <button
        css={[
          tw`text-black text-sm 2xl:text-base py-1.5 md:py-2.5 2xl:py-3.5 px-2 xl:px-4 tracking-[-0.025em] font-medium`,
          index === tabValue &&
            tw`text-white bg-[#6500E0] rounded-lg xl:rounded-[12px]`,
        ]}
        onClick={() => {
          navigate({
            pathname: '/transactions',
            search: `?tab=${index}`,
          });

          setTabValue(index);
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <Text flat="left" fontWeight="700" fontSize="32px">
          Transactions
        </Text>

        <MUIBox sx={{ width: '100%', marginTop: '24px' }}>
          <MUIBox
            sx={{
              paddingBottom: {
                xs: '20px',
                lg: '40px',
              },
            }}
          >
            <div tw="space-x-2 md:space-x-3 xl:space-x-4">
              {userType === 'admin' && <Tab label="Overview" index={0} />}
              <Tab label="POS Transactions" index={1} />
              <Tab label="Wallet Withdrawal" index={2} />
              <Tab label="Receivable Transactions" index={3} />
              {userType === 'admin' && (
                <Tab
                  label={
                    userType === 'admin'
                      ? 'Paysure Income'
                      : 'Organization Income'
                  }
                  index={4}
                />
              )}
            </div>
          </MUIBox>
          {userType === 'admin' && (
            <TabPanel tabvalue={tabValue} index={0}>
              <Overview />
            </TabPanel>
          )}
          <TabPanel tabvalue={tabValue} index={1}>
            <POSTransactions />
          </TabPanel>
          <TabPanel tabvalue={tabValue} index={2}>
            <WalletWithdrawal />
          </TabPanel>
          <TabPanel tabvalue={tabValue} index={3}>
            <ReceivableTrans />
          </TabPanel>
          {userType === 'admin' && (
            <TabPanel tabvalue={tabValue} index={4}>
              <Income />
            </TabPanel>
          )}
        </MUIBox>
      </Box>
    </Box>
  );
};

export default Transaction;
