/** @jsxImportSource @emotion/react */
import { Button, Skeleton } from '@chakra-ui/react';
import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
import tw from 'twin.macro';
import { swrfetcher } from '../../utils/swrFetcher';

const Detail = ({ title, value }) => {
  return (
    <>
      <p tw="text-[#505780] text-xs leading-[14.32px] tracking-[-0.02em] uppercase">
        {title}
      </p>
      <p tw="mt-[6.9px] text-[#16192C] text-sm leading-[16.71px] tracking-[-0.025em]">
        {value}
      </p>
    </>
  );
};

const WalletSlip = () => {
  const navigate = useNavigate();

  const { transactionRef } = useParams();

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const url =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/merchant/analytics/getMerchantTransactionRecord?limit=10&offset=0&searchParameter=${transactionRef}`
      : `/apis/bizzdesk/organization/transaction/getMerchantTransactionRecord?limit=10&offset=0&searchParameter=${transactionRef}`;

  const { data } = useSWR([url, token, 'GET'], ([url, USER_TOKEN, method]) =>
    swrfetcher(url, USER_TOKEN, method)
  );

  const details = [
    { title: 'Date/Time', value: data && data[0]?.date },
    { title: 'Merchant Name', value: data && data[0]?.fullName },
    { title: 'Transaction Ref', value: data && data[0]?.transactionRef },
    { title: 'Walet Id', value: data && data[0]?.walletid },
    { title: 'Beneficiary Bank Name', value: data && data[0]?.beneficiaryBank },
    { title: 'Beneficiary Name', value: data && data[0]?.beneficiaryName },
    { title: 'Beneficiary Acct No', value: data && data[0]?.beneficiaryAcctNo },
    {
      title: 'Charge',
      value: data && (
        <CurrencyFormat
          value={data[0]?.totalCharge}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'₦'}
        />
      ),
    },
    { title: 'Tsq Res Code', value: data && data[0]?.tsqResCode },
    { title: 'Tsq Res Msg', value: data && data[0]?.tsqResMsg },
    { title: 'Description', value: data && data[0]?.narration, isSpan: true },
    {
      title: 'Status Description',
      value:
        data && data[0]?.status.toLowerCase() === 'completed' ? (
          <span tw="text-[#136F63] bg-[#E9FBF9] text-[10px] p-1 rounded-[4px]">
            {data && data[0]?.status}
          </span>
        ) : data && data[0]?.status.toLowerCase() === 'failed' ? (
          <span tw="text-[#EDA95A] bg-[#FCEAE8] text-[10px] p-1 rounded-[4px]">
            {data && data[0]?.status}
          </span>
        ) : (
          <span tw="text-[10px] p-1 rounded-[4px]">
            {data && data[0]?.status}
          </span>
        ),
      isSpan: true,
    },
  ];

  return (
    <div tw="mx-auto bg-white max-w-[375px] w-[375px] pt-[45px] pb-[140px] px-[17px]">
      <button tw="pb-[5px]" onClick={() => navigate(-1)}>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22.5 12C22.5 11.5858 22.1642 11.25 21.75 11.25H4.06066L8.78033 6.53033C9.07322 6.23744 9.07322 5.76256 8.78033 5.46967C8.48744 5.17678 8.01256 5.17678 7.71967 5.46967L1.71967 11.4697C1.42678 11.7626 1.42678 12.2374 1.71967 12.5303L7.71967 18.5303C8.01256 18.8232 8.48744 18.8232 8.78033 18.5303C9.07322 18.2374 9.07322 17.7626 8.78033 17.4697L4.06066 12.75H21.75C22.1642 12.75 22.5 12.4142 22.5 12Z"
            fill="black"
          />
        </svg>
      </button>

      <h4 tw="text-center font-medium text-2xl leading-[28.64px] tracking-[-0.05em]">
        Transaction Details
      </h4>

      <img src="/assets/logo.png" alt="logo" tw="mx-auto mt-[27px] mb-[38px]" />

      <h6 tw="text-center text-[#505780] text-xs tracking-[-0.02em] leading-[14.4px]">
        Amount
      </h6>
      <Skeleton isLoaded={data}>
        <h6 tw="text-center mt-[6.9px] text-[#16192C] text-[34.48px] font-medium leading-[41.15px] tracking-[-0.02em]">
          {data && (
            <CurrencyFormat
              value={data[0]?.amount}
              displayType={'text'}
              thousandSeparator={true}
              prefix={'₦'}
            />
          )}
        </h6>
      </Skeleton>

      <hr tw="mt-[38.4px]" />

      <div tw="mt-4 grid grid-cols-2 gap-x-2 gap-y-[38px]">
        {details.map((detail, index) => {
          return (
            <Skeleton
              key={index}
              isLoaded={data}
              css={[
                index % 2 === 1 &&
                  index !== details.length - 1 &&
                  tw`text-right `,
                detail.isSpan && tw`col-span-2`,
              ]}
            >
              <Detail title={detail.title} value={detail.value} />
            </Skeleton>
          );
        })}
      </div>

      <hr tw="mt-[38.4px]" />

      <Button tw="mt-[56px] w-full text-white bg-[#6500E0] hover:bg-[rgb(12 98 156)] text-xs leading-[14.4px] py-[17px] rounded-[10.3435px] ">
        Print
      </Button>
    </div>
  );
};

export default WalletSlip;
