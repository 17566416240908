/** @jsxImportSource @emotion/react */
import { Box, Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSWR from 'swr';
// eslint-disable-next-line no-unused-vars
import tw from 'twin.macro';
import DatRangePickerAndOthers from '../component/Common/DataGridFunctionalities/DatRangePickerAndOthers';
import FilterBox from '../component/Common/DataGridFunctionalities/FilterBox';
import SearchBar from '../component/Common/DataGridFunctionalities/SearchBar';
import DataGridViewTemp from '../component/Common/DataGridViewTemp';
import Sidebar from '../component/Common/Sidebar';
import Topnav from '../component/Common/Topnav';
import { swrfetcher } from '../utils/swrFetcher';

const Transaction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const offset = query.get('offset');
  const urlstatus = query.get('status');

  const { transMode } = useParams();

  const [searchValue, setSearchValue] = React.useState('');
  const [status, setStatus] = React.useState('');
  const [date, setDate] = React.useState(['', '']);

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  React.useEffect(() => {
    if (urlstatus) {
      setStatus(urlstatus);
    }
  }, [urlstatus]);

  const tableUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/merchant/analytics/getMerchantTransactionRecord?limit=30&offset=${
          offset || 0
        }&transMode=${
          transMode === 'pos'
            ? 'pos'
            : transMode === 'income'
            ? 'Bizzdesk%20income'
            : transMode === 'walletWithdrawal'
            ? ''
            : transMode === 'receivableTransaction' &&
              'Receivable%20transactions'
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`
      : `/apis/bizzdesk/organization/transaction/getMerchantTransactionRecord?limit=30&offset=${
          offset || 0
        }&transMode=${
          transMode === 'pos'
            ? 'pos'
            : transMode === 'income'
            ? 'Bizzdesk%20income'
            : transMode === 'walletWithdrawal'
            ? ''
            : transMode === 'receivableTransaction' &&
              'Receivable%20transactions'
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`;

  const countUrl =
    userType === 'admin'
      ? `/apis/v1/bizzdesk/admin/merchant/analytics/getMerchantTransactionRecord/count?limit=30&offset=${
          offset || 0
        }&transMode=${
          transMode === 'pos'
            ? 'pos'
            : transMode === 'income'
            ? 'Bizzdesk%20income'
            : transMode === 'walletWithdrawal'
            ? ''
            : transMode === 'receivableTransaction' &&
              'Receivable%20transactions'
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`
      : `/apis/bizzdesk/organization/transaction/getMerchantTransactionRecord/count?limit=30&offset=${
          offset || 0
        }&transMode=${
          transMode === 'pos'
            ? 'pos'
            : transMode === 'income'
            ? 'Bizzdesk%20income'
            : transMode === 'walletWithdrawal'
            ? ''
            : transMode === 'receivableTransaction' &&
              'Receivable%20transactions'
        }${!searchValue ? '' : `&searchParameter=${searchValue}`}${
          !date[0] ? '' : `&startDate=${date[0]}`
        }${!date[1] ? '' : `&endDate=${date[1]}`}${
          !status ? '' : `&status=${status}`
        }`;

  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const { data: tableData, isValidating } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { data: countValue } = useSWR(
    [countUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  const { isOpen, onOpen, onClose } = useDisclosure();

  // columns
  const TransPOSColumn =
    transMode === 'pos'
      ? [
          {
            headerName: 'S/N',
          },
          {
            headerName: 'Transaction Type',
          },
          {
            headerName: 'Terminal Id',
          },
          {
            headerName: 'Merchant Name',
          },
          {
            headerName: 'Organization Name',
          },
          {
            headerName: 'Role',
          },
          {
            headerName: 'RRN',
          },
          {
            headerName: 'Transaction Ref.',
          },
          {
            headerName: 'Bank',
          },
          {
            headerName: 'Card Pan',
          },
          {
            headerName: 'Card Type',
          },
          {
            headerName: 'Currency',
          },
          {
            headerName: 'Stan',
          },
          {
            headerName: 'Auth Code',
          },
          {
            headerName: 'Amount',
          },
          {
            headerName: 'Total Charge',
          },
          {
            headerName: 'Paysure Charge',
          },
          {
            headerName: 'Organization Charge',
          },
          {
            headerName: 'Recievable Amount',
          },
          {
            headerName: 'Date/Time',
          },
          {
            headerName: 'Status',
          },
          {
            headerName: 'Response Code',
          },
          {
            headerName: 'Action',
          },
        ]
      : transMode === 'income'
      ? [
          {
            headerName: 'S/N',
          },
          {
            headerName: 'Transaction Type',
          },
          {
            headerName: 'Merchant Name',
          },
          {
            headerName: 'Organization Name',
          },
          {
            headerName: 'Role',
          },
          {
            headerName: 'Wallet ID',
          },
          {
            headerName: 'Wallet Acct. No.',
          },
          {
            headerName: 'Wallet Bank Name',
          },
          {
            headerName: 'Transaction Ref.',
          },
          {
            headerName: 'Channel',
          },
          {
            headerName: 'Receivable Amount',
          },
          {
            headerName: 'Total Charge',
          },
          {
            headerName: 'Paysure Charge',
          },
          {
            headerName: 'Organization Charge',
          },
          {
            headerName: 'Status Code',
          },
          {
            headerName: 'Status',
          },
          {
            headerName: 'Date/Time',
          },
          {
            headerName: 'Action',
          },
        ]
      : transMode === 'walletWithdrawal'
      ? [
          {
            headerName: 'S/N',
          },
          {
            headerName: 'Transaction Type',
          },
          {
            headerName: 'Merchant Name',
          },
          {
            headerName: 'Organization Name',
          },
          {
            headerName: 'Role',
          },
          {
            headerName: 'Wallet ID',
          },
          {
            headerName: 'Wallet Acct. No.',
          },
          {
            headerName: 'Wallet Bank Name',
          },
          {
            headerName: 'Session ID',
          },
          {
            headerName: 'Transaction Ref.',
          },
          {
            headerName: 'Beneficiary Bank',
          },
          {
            headerName: 'Beneficiary Acct. No.',
          },
          {
            headerName: 'Channel',
          },
          {
            headerName: 'Amount',
          },
          {
            headerName: 'Total Charge',
          },
          {
            headerName: 'Paysure Charge',
          },
          {
            headerName: 'Organization Charge',
          },
          {
            headerName: 'TSQ. RES. Code',
          },
          {
            headerName: 'TSQ. RES. MSG.',
          },
          {
            headerName: 'Status',
          },
          {
            headerName: 'Date/Time',
          },
          {
            headerName: 'Action',
          },
        ]
      : transMode === 'receivableTransaction' && [
          {
            headerName: 'S/N',
          },
          {
            headerName: 'Transaction Type',
          },
          {
            headerName: 'Merchant Name',
          },
          {
            headerName: 'Organization Name',
          },
          {
            headerName: 'Role',
          },
          {
            headerName: 'Wallet ID',
          },
          {
            headerName: 'Wallet Acct. No.',
          },
          {
            headerName: 'Wallet Bank Name',
          },
          {
            headerName: 'Transaction Ref.',
          },
          {
            headerName: 'Channel',
          },
          {
            headerName: 'Receivable Amount',
          },
          {
            headerName: 'Total Charge',
          },
          {
            headerName: 'Paysure Charge',
          },
          {
            headerName: 'Organization Charge',
          },
          {
            headerName: 'Status Code',
          },
          {
            headerName: 'Status',
          },
          {
            headerName: 'Date/Time',
          },
          {
            headerName: 'Action',
          },
        ];

  // rows
  let rows = [];
  if (tableData) {
    if (tableData?.status >= 400) {
      rows = [];
    } else {
      rows = tableData?.map((item, index) => {
        if (transMode === 'pos') {
          return {
            id: Number(offset || 0) + index + 1,
            type: item?.transtype,
            terminalId: item?.tid,
            merchantName: item?.fullName,
            organizationName: item?.organizationName,
            role: item?.role,
            rrn: item?.rrn,
            transactionRef: item?.ref,
            bank: item?.bank,
            cardPan: item?.panAccount,
            cardType: item?.cardType,
            currency: item?.currency,
            stan: item?.stan,
            authCode: item?.authCode,
            amountN: item?.amount,
            totalChargeN: item?.totalCharges,
            bizzDeskChargeN: item?.bizzdeskCharges,
            organizationChargeN: item?.organizationCharges,
            recievableAmountN: item?.receivableAmount,
            dateTime: item?.date,
            status: item?.status,
            responseCode: item?.response,
            action: {
              link: `/transactions/${item?.ref}`,
            },
          };
        } else if (transMode === 'income') {
          return {
            id: Number(offset || 0) + index + 1,
            type: item?.transtype,
            merchantName: item?.fullName,
            organizationName: item?.organizationName,
            role: item?.role,
            walletId: item?.walletid,
            walletAccountNo: item?.walletAcctNo,
            walletBankName: item?.walletBankName,
            transactionRef: item?.transactionRef,
            channel: item?.channel,
            receivableAmountN: item?.receivableAmount,
            totalChargeN: item?.totalCharge,
            bizzDeskChargeN: item?.bizzdeskCharges,
            organizationChargeN: item?.organizationCharge,
            statusCode: item?.response,
            status: item?.status,
            dateTime: item?.date,
            action: {
              link: `/transactions/income/${item?.transactionRef}`,
            },
          };
        } else if (transMode === 'walletWithdrawal') {
          return {
            id: Number(offset || 0) + index + 1,
            type: item?.transtype,
            merchantName: item?.fullName,
            organizationName: item?.organizationName,
            role: item?.role,
            walletId: item?.walletid,
            walletAccountNo: item?.walletAcctNo,
            walletBankName: item?.walletBankName,
            sessionId: item?.sessionId,
            transactionRef: item?.transactionRef,
            beneficiaryBank: item?.beneficiaryBank,
            beneficiaryAccountNo: item?.beneficiaryAcctNo,
            channel: item?.channel,
            amountN: item?.amount,
            totalChargeN: item?.totalCharge,
            bizzDeskChargeN: item?.bizzdeskCharges,
            organizationChargeN: item?.organizationCharge,
            tsqResCode: item?.tsqResCode,
            tsqResMsg: item?.tsqResMsg,
            status: item?.status,
            dateTime: item?.date,
            action: {
              link: `/transactions/wallet/${item?.transactionRef}`,
            },
          };
        }
        return {
          id: Number(offset || 0) + index + 1,
          type: item?.transtype,
          merchantName: item?.fullName,
          organizationName: item?.organizationName,
          role: item?.role,
          walletId: item?.walletid,
          walletAccountNo: item?.walletAcctNo,
          walletBankName: item?.walletBankName,
          transactionRef: item?.transactionRef,
          channel: item?.channel,
          amountN: item?.receivableAmount,
          totalChargeN: item?.totalCharge,
          bizzDeskChargeN: item?.bizzdeskCharges,
          organizationChargeN: item?.organizationCharge,
          statusCode: item?.response,
          status: item?.status,
          dateTime: item?.date,
          action: {
            link: `/transactions/receivable/${item?.transactionRef}`,
          },
        };
      });
    }
  }

  return (
    <Box minH="100vh" w="full" bg="whitesmoke">
      <Sidebar
        onClose={() => onClose}
        display={{ base: 'none', md: 'block' }}
      />
      <Drawer
        autoFocus={false}
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
      >
        <DrawerContent>
          <Sidebar onClose={onClose} />
        </DrawerContent>
      </Drawer>

      {/* mobilenav */}
      <Topnav onOpen={onOpen} />
      <Box ml={{ base: 0, md: 60 }} p="4">
        <div tw="mb-4 hover:underline">
          <button
            onClick={() => navigate(-1)}
            style={{ fontSize: '14px', fontWeight: '300' }}
            tw="mb-4 hover:underline"
          >
            Back
          </button>
        </div>

        <DataGridViewTemp
          title={
            transMode === 'pos'
              ? 'POS Transactions'
              : transMode === 'income'
              ? userType === 'admin'
                ? 'Paysure Income'
                : 'Organization Income'
              : transMode === 'walletWithdrawal'
              ? 'Wallet Withdrawal Transactions'
              : transMode === 'receivableTransaction' &&
                'Receivable Transactions'
          }
          rows={rows}
          columns={TransPOSColumn}
          isValidating={isValidating}
          hasExportBtn={tableData}
          pagination
          recordCount={countValue || 0}
          page={offset / 30 + 1}
        >
          <div tw="grid grid-auto-columns[auto] gap-4 w-full xl:(flex items-center space-y-0 space-x-4)">
            <SearchBar value={searchValue} setSearchValue={setSearchValue} />
            <FilterBox
              dropdownData={['All', 'Completed', 'Pending', 'Failed']}
              value={status}
              setFilterValue={setStatus}
            />
            <DatRangePickerAndOthers date={date} setDate={setDate} />
          </div>
        </DataGridViewTemp>
      </Box>
    </Box>
  );
};

export default Transaction;
