import React from 'react';
import {
  Box,
  Input,
  InputRightElement,
  InputGroup,
  Select,
  Image,
} from '@chakra-ui/react';

const FilterMerchant = () => {
  return (
    <Box
      display="flex"
      gap="6"
      justifyContent="space-between"
      w="full"
      flexDirection={['column', 'row']}
      mt="2rem"
      mb={['1rem', '0']}
    >
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        gap="2"
        float="left"
      >
        <InputGroup w="full">
          <Input fontSize="13px" placeholder="Search: Support Staff" />
          <InputRightElement
            children={<Image src="assets/search-normal.png" alt="" />}
          />
        </InputGroup>
        <Select
          fontWeight="300"
          textAlign="center"
          borderRadius="4px"
          fontSize="12px"
          alignItems="flex-start"
          color="#425D8A"
          placeholder="Sort By: High"
        >
          <option style={{ margin: '.75rem' }} value="option1">
            Option 1
          </option>
          <option value="option2">Option 2</option>
          <option value="option3">Option 3</option>
        </Select>
      </Box>
    </Box>
  );
};

export default FilterMerchant;
