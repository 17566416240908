import {
  Box,
  Button,
  HStack,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import OtpInput from 'react-otp-input';
import * as Yup from 'yup';
// eslint-disable-next-line
import { usePaystackPayment } from 'react-paystack';

function FundWalletModal() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // const [loading, setLoading] = React.useState(false);
  // const [val, setVal] = React.useState(false);
  const [formStep, setFormStep] = useState(0);
  const [otp, setOtp] = useState();
  function handleChange(e) {
    setOtp(e.target.value);
  }
  const completeFormStep = () => {
    setFormStep(cur => cur + 1);
  };
  const clearPreviewData = () => {
    setFormStep(0);
    onClose();
  };
  const RenderButton = () => {
    if (formStep === 0) {
      return (
        <Button
          type="submit"
          bg="#6500E0"
          w="full"
          h="42px"
          borderRadius="8px"
          colorScheme="#6500E0"
          variant="solid"
          onClick={completeFormStep}
        >
          Continue
        </Button>
      );
    } else if (formStep === 1) {
      return (
        <Button
          type="submit"
          bg="#6500E0"
          w="full"
          h="42px"
          borderRadius="8px"
          colorScheme="#6500E0"
          variant="solid"
          onClick={completeFormStep}
          // disabled={isValid ? false : true}
        >
          Continue
        </Button>
      );
    } else if (formStep === 2) {
      return (
        <Button
          type="submit"
          bg="#6500E0"
          w="full"
          h="42px"
          borderRadius="8px"
          colorScheme="#6500E0"
          variant="solid"
          onClick={completeFormStep}
          // disabled={isValid ? false : true}
        >
          Completed
        </Button>
      );
    } else if (formStep === 3) {
      return (
        <Button
          type="submit"
          bg="#6500E0"
          w="full"
          h="42px"
          borderRadius="8px"
          colorScheme="#6500E0"
          variant="solid"
          onClick={() => {
            completeFormStep();
            clearPreviewData();
          }}

          // disabled={isValid ? false : true}
        >
          Proceed
        </Button>
      );
    } else {
      return null;
    }
  };
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  let toast = useToast();
  // eslint-disable-next-line
  const [loadings, setLoadings] = React.useState(false);
  //   validation

  const validationSchema = Yup.object().shape({
    number: Yup.string().required('Input is required'),
    amount: Yup.string().required('Amount of Admin is required'),
    expdate: Yup.string().required('Expiry date is required'),
    cvv: Yup.string().required('CVV is required'),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const validate = (data, event) => {
    event.preventDefault();
    const input = {
      email: data?.email,
      fullNameOfAdmin: data?.fullNameOfAdmin,
      password: data?.password,
      phoneNumber: data?.phoneNumber,
      role: data?.role,
    };

    setLoadings(true);

    axios({
      method: 'post',
      headers: {
        Authorization: `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*',
      },
      url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/admin/SubAdmin/add',
      data: input,
    })
      .then(response => {
        setLoadings(false);
        toast({
          title: 'A New Sub-admin Created!.',
          position: 'top',
          description: 'A New Role has been Created Successfully.',
          status: 'success',
          duration: 9000,
          isClosable: true,
        });
      })
      .catch(err => {
        toast({
          title: 'Failed.',
          position: 'top',
          description: 'It is not you, it i us, wait as we resolve the issue.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
        setLoadings(false);
      });
  };

  return (
    <>
      <Button
        onClick={onOpen}
        bg="#6500E0"
        w="185px"
        h="56px"
        borderRadius="12px"
        color="#fff"
        colorScheme="#6500E0"
        variant="solid"
      >
        Fund Wallet
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            {formStep === 0 && <>Fund Wallet</>}
            {formStep === 1 && <>New Card</>}
            {formStep === 2 && <>Enter OTP</>}
            {formStep === 3 && <>Select Card</>}
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <form onSubmit={handleSubmit(validate)}>
              <Box
                mt="1rem"
                p="1rem"
                display="flex"
                flexDirection="column"
                gap="3"
              >
                {formStep === 0 && (
                  <VStack alignItems="center">
                    <Input
                      placeholder="0.00"
                      {...register('amount')}
                      border={
                        errors.amount ? '1px solid red' : 'none !important'
                      }
                      borderRadius="4px"
                      color="#333"
                      fontSize="32px"
                      textAlign="center"
                      outline="none"
                      type="text"
                      _focus={{ outline: 'none' }}
                      _active={{ outline: 'none' }}
                    />
                    <Text
                      textAlign="center"
                      fontSize="12px"
                      fontWeight="400"
                      color="#454D54"
                    >
                      Enter amount
                    </Text>
                    <Text color="red" mt=".5rem">
                      {errors.title?.amount}
                    </Text>
                  </VStack>
                )}
                {formStep === 1 && (
                  <>
                    <VStack alignItems="flex-start">
                      <Text fontSize="13px" fontWeight="300" color="#454D54">
                        Enter card number
                      </Text>
                      <Input
                        placeholder=""
                        {...register('number')}
                        border={
                          errors.number ? '1px solid red' : ' 1px solid #E3E5E8'
                        }
                        borderRadius="4px"
                        color="#333"
                        fontSize="14px"
                        outline="none"
                        type="mail"
                        _focus={{ outline: 'none' }}
                        _active={{ outline: 'none' }}
                      />
                      <Text color="red" mt=".5rem">
                        {errors.title?.number}
                      </Text>
                    </VStack>
                    <HStack gap="6">
                      <VStack alignItems="flex-start">
                        <Text fontSize="13px" fontWeight="300" color="#454D54">
                          Exp date
                        </Text>
                        <Input
                          placeholder=""
                          {...register('expdate')}
                          border={
                            errors.expdate
                              ? '1px solid red'
                              : ' 1px solid #E3E5E8'
                          }
                          borderRadius="4px"
                          color="#333"
                          fontSize="14px"
                          outline="none"
                          type="text"
                          _focus={{ outline: 'none' }}
                          _active={{ outline: 'none' }}
                        />
                        <Text color="red" mt=".5rem">
                          {errors.title?.expdate}
                        </Text>
                      </VStack>
                      <VStack alignItems="flex-start">
                        <Text fontSize="13px" fontWeight="300" color="#454D54">
                          CVV
                        </Text>
                        <Input
                          placeholder=""
                          {...register('cvv')}
                          border={
                            errors.cvv ? '1px solid red' : ' 1px solid #E3E5E8'
                          }
                          borderRadius="4px"
                          color="#333"
                          fontSize="14px"
                          outline="none"
                          type="text"
                          _focus={{ outline: 'none' }}
                          _active={{ outline: 'none' }}
                        />
                        <Text color="red" mt=".5rem">
                          {errors.title?.cvv}
                        </Text>
                      </VStack>
                    </HStack>
                  </>
                )}
                {formStep === 2 && (
                  <VStack alignItems="center">
                    <Text fontSize="13px" fontWeight="300" color="#454D54">
                      A one time password was sent you
                    </Text>

                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      inputStyle={{
                        border: '1px solid #ccc',
                        margin: '3px',
                        borderRadius: '8px',
                        width: '54px',
                        height: '54px',
                        fontSize: '12px',
                        color: '#000',
                        fontWeight: '400',
                        caretColor: 'blue',
                      }}
                      focusStyle={{
                        border: '1px solid #CFD3DB',
                        outline: 'none',
                      }}
                      separator={<span> </span>}
                    />
                  </VStack>
                )}
                {formStep === 3 && (
                  <Box
                    w="full"
                    h="full"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    gap="3"
                    justifyContent="center"
                  >
                    <Image src="assets/successful.png" alt="" />
                    <Text color="#000" fontSize="24px" fontWeight="500">
                      Transaction Successful
                    </Text>
                    <Text color="#666" fontSize="14px" fontWeight="400">
                      ₦639,000.00 has been added to your wallet
                    </Text>
                  </Box>
                )}
                {/* <Center w="full">
                  {loadings ? (
                    <Button
                      isLoading
                      mt="2rem"
                      w="full"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      loadingText="Loading"
                      colorScheme="#6500E0"
                      variant="outline"
                    />
                  ) : (
                    <Button
                      bg="#6500E0"
                      mt="2rem"
                      w="full"
                      color="#fff"
                      align="center"
                      p="1rem"
                      borderRadius="4px"
                      role="group"
                      cursor="pointer"
                      _hover={{
                        bg: '#5403b8',
                        color: 'white',
                      }}
                      type="submit"
                    >
                      Add Admin User
                    </Button>
                  )}
                </Center> */}
                {RenderButton()}
              </Box>
            </form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default FundWalletModal;
