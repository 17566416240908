import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import React from 'react';

function AssignStaff() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        onClick={onOpen}
        bg="#6500E0"
        w={['85%', '320px']}
        h="42px"
        borderRadius="8px"
        colorScheme="#6500E0"
        variant="solid"
      >
        Assign Merchant to a Support Staff
      </Button>

      <Modal borderRadius="8px" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader textAlign="center" borderBottom="1px solid #E4ECF7">
            Assign to Support Staff
          </ModalHeader>
          {/* <Image src="/assets/close-square.png" alt="" /> */}
          <ModalCloseButton
            border="1.5px solid #425D8A"
            color="#425D8A"
            borderRadius="8px"
          />
          <ModalBody>
            <Box
              mt="1rem"
              p="1rem"
              display="flex"
              flexDirection="column"
              gap="3"
              w="full"
            >
              <VStack w="full" alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Merchant
                </Text>
                <Accordion
                  position="relative"
                  border="1px solid #E3E6E8"
                  borderRadius="8px"
                  allowToggle
                  w="full"
                >
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          Select Merchant
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      position="absolute"
                      zIndex="10"
                      bg={['#fff', '#fff']}
                      w="full"
                      overflowY="auto"
                      maxH="10rem"
                      pb={4}
                    >
                      <Box
                        display="flex"
                        cursor="pointer"
                        _hover={{ background: '#F3F7FC' }}
                        w="full"
                        py="1rem"
                        gap="3"
                      >
                        <Image
                          src="assets/merchantframe.png"
                          w="39px"
                          h="39px"
                          alt=""
                        />
                        <VStack alignItems="flex-start">
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#191716"
                          >
                            Mr. Olamide Aderibigbe
                          </Text>
                          <Text
                            fontSize="13px"
                            fontWeight="300"
                            color="#425D8A"
                          >
                            #021233
                          </Text>
                        </VStack>
                      </Box>
                      <Box
                        display="flex"
                        cursor="pointer"
                        _hover={{ background: '#F3F7FC' }}
                        w="full"
                        py="1rem"
                        gap="3"
                      >
                        <Image
                          src="assets/merchantframe.png"
                          w="39px"
                          h="39px"
                          alt=""
                        />
                        <VStack alignItems="flex-start">
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#191716"
                          >
                            Mr. Olamide Aderibigbe
                          </Text>
                          <Text
                            fontSize="13px"
                            fontWeight="300"
                            color="#425D8A"
                          >
                            #021233
                          </Text>
                        </VStack>
                      </Box>
                      <Box
                        display="flex"
                        cursor="pointer"
                        _hover={{ background: '#F3F7FC' }}
                        w="full"
                        py="1rem"
                        gap="3"
                      >
                        <Image
                          src="assets/merchantframe.png"
                          w="39px"
                          h="39px"
                          alt=""
                        />
                        <VStack alignItems="flex-start">
                          <Text
                            fontSize="14px"
                            fontWeight="400"
                            color="#191716"
                          >
                            Mr. Olamide Aderibigbe
                          </Text>
                          <Text
                            fontSize="13px"
                            fontWeight="300"
                            color="#425D8A"
                          >
                            #021233
                          </Text>
                        </VStack>
                      </Box>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </VStack>
              <VStack w="full" alignItems="flex-start">
                <Text fontSize="13px" fontWeight="300" color="#454D54">
                  Band
                </Text>
                <Accordion
                  position="relative"
                  w="full"
                  border="1px solid #E3E6E8"
                  borderRadius="8px"
                  allowToggle
                >
                  <AccordionItem>
                    <h2>
                      <AccordionButton>
                        <Box flex="1" textAlign="left">
                          Select Band
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel
                      position="absolute"
                      zIndex="10"
                      bg={['#fff', '#fff']}
                      w="full"
                      maxH="10rem"
                      overflowY="auto"
                      pb={4}
                    >
                      <Text
                        cursor="pointer"
                        _hover={{ background: '#F3F7FC' }}
                        borderBottom="1px solid #ccc"
                        p="1rem"
                      >
                        {' '}
                        Band A{' '}
                      </Text>
                      <Text
                        cursor="pointer"
                        _hover={{ background: '#F3F7FC' }}
                        borderBottom="1px solid #ccc"
                        p="1rem"
                      >
                        {' '}
                        Band B{' '}
                      </Text>
                      <Text
                        cursor="pointer"
                        _hover={{ background: '#F3F7FC' }}
                        borderBottom="1px solid #ccc"
                        p="1rem"
                      >
                        {' '}
                        Band C{' '}
                      </Text>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </VStack>
              <Button
                border="none"
                borderRadius="12px"
                w="full"
                h="55px"
                color="#fff"
                bg="#6500E0"
              >
                Done
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default AssignStaff;
