import { Button } from '@chakra-ui/button';
import { Image } from '@chakra-ui/image';
import { Input, InputGroup, InputLeftElement } from '@chakra-ui/input';
import { Box, HStack } from '@chakra-ui/layout';
import moment from 'moment/moment';
import React from 'react';
import tw from 'twin.macro';

const DatRangePickerAndOthers = ({ startDate, endDate, date, setDate }) => {
  const [value, setValue] = React.useState([]);

  React.useEffect(() => {
    if (date) {
      setValue([
        moment(date[0]).format('yyyy-MM-DD'),
        moment(date[1]).format('yyyy-MM-DD'),
      ]);
    } else {
      setValue([null, null]);
    }
  }, [date]);

  // UseState hook

  const handleDateFilter = () => {
    setDate(value);

    // router.push({
    //   pathname: router.pathname,
    //   query: {
    //     ...router.query,
    //     startDate: moment(value[0]).format('yyyy-MM-DD'),
    //     endDate: moment(value[1]).format('yyyy-MM-DD'),
    //   },
    // })
  };


  return (
    <HStack border="1px solid #ccc" width="fit-content">
      <InputGroup width="auto">
        <InputLeftElement
          pl={4}
          pointerEvents="none"
          children={<Span>From:</Span>}
        />
        <Input
          pl={12}
          outline="none !important"
          size="sm"
          type="date"
          border={0}
          value={value[0]}
          onChange={e => setValue([e.target.value, value[1]])}
        />
      </InputGroup>
      <Box sx={{ mx: '8px', color: '#979797' }}> | </Box>
      <InputGroup width="auto">
        <InputLeftElement
          pl={4}
          pointerEvents="none"
          children={<Span>To:</Span>}
        />
        <Input
          outline="none !important"
          size="sm"
          type="date"
          border={0}
          value={value[1]}
          onChange={e => setValue([value[0], e.target.value])}
        />
      </InputGroup>
      <Button onClick={handleDateFilter} ml="4px">
        <Image src="/assets/search-normal.png" alt="" />
      </Button>
    </HStack>
  );
};

// Tailwind Styles
const Span = tw.span`text-[13px] text-[#10101266]`;

export default DatRangePickerAndOthers;
