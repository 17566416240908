import { Box, Button, Image, Text } from '@chakra-ui/react';
import { Box as MUIBox } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddTerminalModal from './Modals/AddTerminalModal';
import Overview from './Overview';
/** @jsxImportSource @emotion/react */
import tw from 'twin.macro';
import TerminalBank from '../../component/Terminals/TerminalBank';
import TabPanel from '../Common/TabPanel';
import MerchantList from './MerchantList';

const Terminal = () => {
  const navigate = useNavigate();

  const [tabValue, setTabValue] = React.useState(0);

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  // get tab value from url param
  React.useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get('tab');
    if (tab) {
      setTabValue(parseInt(tab));
    }
  }, []);

  const Tab = ({ label, index }) => {
    return (
      <button
        css={[
          tw`text-black text-base py-3.5 px-2 tracking-[-0.025em] font-medium`,
          index === tabValue && tw`text-white bg-[#6500E0] rounded-[12px]`,
        ]}
        onClick={() => {
          navigate({
            pathname: '/terminals',
            search: `?tab=${index}`,
          });

          setTabValue(index);
        }}
      >
        {label}
      </button>
    );
  };

  return (
    <Box>
      <div tw="flex items-center justify-between">
        <Text flat="left" fontWeight="700" fontSize="32px">
          Terminals
        </Text>

        <Box display="flex" flexDirection={['column', 'row']} gap="6">
          <AddTerminalModal />
          <Button
            leftIcon={<Image src="/assets/add-blue-square.png" alt="" />}
            bg="#E9F3FA"
            // w="197px"
            // h="42px"
            color="#2085C9"
            borderRadius="8px"
            variant="solid"
          >
            Add multiple Terminals
          </Button>
        </Box>
      </div>

      <MUIBox sx={{ width: '100%', marginTop: '24px' }}>
        <MUIBox
          sx={{
            paddingBottom: {
              xs: '20px',
              lg: '40px',
            },
          }}
        >
          <div tw="space-x-4">
            <Tab label="Overview" index={0} />
            {userType === 'admin' && (
              <Tab label="Terminals per Bank" index={1} />
            )}
            <Tab label="Terminals per Merchants" index={2} />
          </div>
        </MUIBox>
        <TabPanel tabvalue={tabValue} index={0}>
          <Overview />
        </TabPanel>
        {userType === 'admin' && (
          <TabPanel tabvalue={tabValue} index={1}>
            <TerminalBank />
          </TabPanel>
        )}
        <TabPanel tabvalue={tabValue} index={2}>
          <MerchantList />
        </TabPanel>
      </MUIBox>
    </Box>
  );
};

export default Terminal;
