import {
  Avatar,
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { FiChevronDown, FiMenu } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

const Topnav = ({ onOpen, ...rest }) => {
  let navigate = useNavigate();

  const [navbar, setNavbar] = useState(false);
  // const [isOpened, setIsOpened] = useState(false);

  // const openMobileMenu = () => {
  //   setIsOpened(!isOpened);
  // };

  const userType = JSON?.parse(
    localStorage.getItem('userType') || sessionStorage.getItem('userType')
  ).toLowerCase();

  const AddBgOnScroll = () => {
    const scrollHeight = 100;
    const pos = window.scrollY;

    if (pos >= scrollHeight) {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', AddBgOnScroll);
  }, []);

  const dropDown = useRef(null);

  const handleSignOut = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userType');
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('userType');
    navigate('/auth/login');
  };

  return (
    <Flex
      //  ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 4 }}
      height="20"
      w="full !important"
      zIndex="200"
      pos="sticky"
      ref={dropDown}
      top="0"
      transition={navbar ? '0.5s ease' : ''}
      alignItems="center"
      bg={['#fff', '#F3F7FC']}
      borderBottomWidth={['1px', '0']}
      // borderBottomColor={useColorModeValue('gray.200', 'gray.700')}
      justifyContent={{ base: 'space-between', md: 'space-between' }}
      {...rest}
    >
      <IconButton
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        variant="outline"
        aria-label="open menu"
        icon={<FiMenu />}
      />

      <Image src="/assets/logo_purple.png" display={['none', 'block']} alt="" />

      <HStack spacing={{ base: '0', md: '6' }}>
        {/* <IconButton
          size="lg"
          variant="ghost"
          aria-label="open menu"
          icon={<FiBell />}
        /> */}
        <Text
          bg="#6500E0B3"
          color="#fff"
          display={['none', 'block']}
          borderRadius="4px"
          p="4px 9px"
        >
          {userType === 'admin' ? 'Super admin' : 'Admin'}
        </Text>
        <Flex alignItems={'center'}>
          <Menu>
            <MenuButton
              py={2}
              transition="all 0.3s"
              _focus={{ boxShadow: 'none' }}
            >
              <HStack>
                <Avatar
                  size={'sm'}
                  src={
                    'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRKaiKiPcLJj7ufrj6M2KaPwyCT4lDSFA5oog&usqp=CAU'
                  }
                />
                <Box display={{ base: 'none', md: 'flex' }}>
                  <FiChevronDown />
                </Box>
              </HStack>
            </MenuButton>
            <MenuList
              bg={useColorModeValue('white', 'gray.900')}
              borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
              <MenuItem>Profile</MenuItem>
              <MenuItem>Settings</MenuItem>
              <MenuDivider />
              <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </HStack>
    </Flex>
  );
};

export default Topnav;
