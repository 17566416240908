import {
  Box,
  Button,
  Input,
  // InputRightElement,
  // InputGroup,
  Select,
  // Image,
  Text,
} from '@chakra-ui/react';
import axios from 'axios';
import React from 'react';
import { CSVLink } from 'react-csv';
import useSWR from 'swr';
import { swrfetcher } from '../../utils/swrFetcher';

const FilterMainWallet = ({
  setSecondDate,
  setFirstDate,
  setSearch,
  filterTransactions,
}) => {
  // eslint-disable-next-line
  const [loading, setLoading] = React.useState(false);
  const token = JSON?.parse(
    localStorage.getItem('token') || sessionStorage.getItem('token')
  );

  const tableUrl = `/apis/v1/bizzdesk/admin/wallet/MainWallet/getWalletActivities?limit=2000&offset=0`;

  const { data: transactionData } = useSWR(
    [tableUrl, token, 'GET'],
    ([url, USER_TOKEN, method]) => swrfetcher(url, USER_TOKEN, method)
  );

  return (
    <Box
      display="flex"
      gap="6"
      justifyContent="space-between"
      alignItems="center"
      w="full"
      flexDirection={['column', 'row']}
      mt="2rem"
      mb={['1rem', '1rem']}
    >
      <Box
        display="flex"
        flexDirection={['column', 'row']}
        gap="2"
        float="left"
      >
        <Select
          fontWeight="300"
          textAlign="center"
          borderRadius="4px"
          fontSize="12px"
          alignItems="flex-start"
          color="#425D8A"
          onChange={e => setSearch(e.target.value)}
          placeholder="Showing: All"
        >
          <Text as="option" p="1rem" value="TRANSFER INCOME">
            Transfer Income
          </Text>
          <Text as="option" p="1rem" value="PURCHASE SETTLEMENT">
            Purchase Settlement
          </Text>
        </Select>
        <Box
          display="flex"
          border="1px solid #ccc"
          color="#425D8A"
          alignItems="center"
          borderRadius="4px"
        >
          <Input
            type="date"
            border="none"
            borderRight="1px solid #ccc !important"
            borderRadius="none"
            outline="none !important"
            className="filter"
            onChange={e => setFirstDate(e.target.value)}
          />
          <Input
            type="date"
            border="none"
            outline="none"
            onChange={e => setSecondDate(e.target.value)}
          />
        </Box>
        <Button w={['full', '50%']} onClick={() => filterTransactions()}>
          Search
        </Button>
      </Box>

      <Box float="right">
        <CSVLink
          data={transactionData}
          asyncOnClick={true}
          onClick={(event, done) => {
            axios({
              method: 'get',
              headers: {
                Authorization: `Bearer ${token}`,
              },
              url: 'https://bbm.bizzdesk.ng:8080/apis/v1/bizzdesk/terminals/getTerminals?limit=100&offset=0',
            }).then(() => {
              done(); // REQUIRED to invoke the logic of component
            });
          }}
        >
          <Button
            bg="#F3F7FC"
            w={['85%', '118px']}
            fontSize={['14px', '1rem']}
            h={['35px', '41px']}
            color="#6500E0"
            borderRadius="12px"
            variant="solid"
          >
            Export Data
          </Button>
        </CSVLink>
      </Box>
    </Box>
  );
};

export default FilterMainWallet;
